import { Divider, Icon, Row } from 'antd';
import 'antd/es/notification/style/css';
import { startCase } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import withApollo from '../../../config/with-apollo';
import { AppContext } from '../../../helpers/context';
import { AUTH, subscriptionImages, subscriptionPropertyMap } from '../../../utils/common';
import { post } from '../../../utils/api-handler';
import moment from 'moment';

const Card = (props) => {
  const { user_current_plan = {}, user_subscription_type } = useContext(AppContext)
  const { subscription } = props;
  const USER = AUTH();

  const isSubscription = useMemo(() => {
    if (!user_subscription_type && !subscription) return true;

    return user_current_plan.subscription_property === subscription.subscription_property
  }, [user_current_plan, subscription, user_subscription_type]);

  const checkRemaining = (property) => {
    const subscription_properties = subscription;
    if (!user_current_plan || !subscription_properties) return `0 / 0 left`;

    let data = null, current;
    switch (property) {
      case 'Meet ups':
        current = isSubscription ?
          `${user_current_plan.available_meet_ups || 0} / ${subscription_properties.meet_ups} left` :
          subscription_properties.meet_ups;
        data = subscription_properties.meet_ups === 99999 ? 'Unlimited' : current;
        break;
      case 'Be MPowered Conference':
        current = isSubscription ?
          `${user_current_plan.available_special_events || 0} / ${subscription_properties.special_events} left` :
          subscription_properties.special_events;
        data = subscription_properties.special_events === 99999 ? 'Unlimited' : current;
        break;
      case 'Expert Group Session':
        current = isSubscription ?
          `${user_current_plan.available_group_expert_sessions || 0} / ${subscription_properties.group_expert_sessions} left` :
          subscription_properties.group_expert_sessions;
        data = subscription_properties.group_expert_sessions === 99999 ? 'Unlimited' : current;
        break;
      case 'Expert 1:1 Session':
        current = isSubscription ?
          `${user_current_plan.available_single_expert_sessions || 0} / ${subscription_properties.single_expert_sessions} left` :
          subscription_properties.single_expert_sessions;
        data = subscription_properties.single_expert_sessions === 99999 ? 'Unlimited' : current;
        break;
      default:
    }
    return data;
  };

  const checkSubscription = (property) => {
    const subscription_properties = subscription

    if (!subscription_properties) {
      if (property === 'earn_tokens' || property === 'pay_as_you_go') return true;
      return false;
    }

    const value = subscription_properties[property]
    return !!value
  };

  const onViewSubscription = async () => {
    localStorage.removeItem('@return-url');
    if (['sign_up', 'sign up', 'free'].includes(user_subscription_type.toLocaleLowerCase())) return props.history.push('/upgrade-account')

    const response = await post(`/create-customer-session`, {
      redirect: `${window.location.origin}/user-subscription`,
      userId: USER.id,
    });

    if (response.data.sessionURL) {
      window.open(response.data.sessionURL, "_self")
    }
  }

  return (
    <div className='px-4 w-5/6'>
      {isSubscription ?
        <div className='mb-2 ml-3 bg-white p-1 md:p-5 md:flex justify-center text-center'>
          <span className='text-lg font-bold'>Current Subscription</span>
        </div> :
        <div className='mb-2 ml-3 w-full'>
          <span className='text-md font-bold'>{!props.index ? 'Other Subscriptions' : ' '}</span>
        </div>
      }
      <div className={`border border-gray-300 mb-2 ml-3 w-full mx-auto bg-white ${props.index && 'mt-7'}`}>
        <div className='md:flex justify-between px-8 py-2'>
          <Row className='h-2/6 w-full pl-2 pr-4 pt-7 '>
            <div className='pb-5 border-b-2 lg:pr-5 lg:border-r-2 md:pb-0 md:border-b-0 mb-4 container mx-auto px-3 place-self-center'>
              <p className='text-xl font-semibold text-center'>{(subscription || {}).subscription ? <span className='text-2xl font-grumpy'>{startCase(subscription.subscription.toLowerCase())} {subscription.billing !== '' && <small className='text-grey'><br></br>(${subscription.price}  {subscription.billing})</small>}</span> : 'Sign Up'}</p>
              {(user_current_plan || {}).subscription_type !== "SIGN_UP" && (
                <div className='text-center'>
                  {user_current_plan.canceled_at ?
                    <p className='text-red-500 text-md'>
                      Expires on<br></br>{moment((user_current_plan || {}).canceled_at).format('DD MMM YYYY')}
                    </p> :
                    <p className='text-primary text-md'>
                      Auto-renews on<br></br>{moment((user_current_plan || {}).expire_at).format('DD MMM YYYY')}
                    </p>
                  }
                  {user_current_plan.canceled_at ? <a className='text-primary' onClick={onViewSubscription} > Renew Subscription </a> : <a onClick={onViewSubscription} > Cancel Subscription </a> }             
                </div>
              )}
              <div className='place-self-center mt-3'>
                    {isSubscription &&
                      <div className='text-center p-2'>
                        <button
                          type={'submit'}
                          onClick={onViewSubscription}
                          className={'bg-white border-primary border font-medium text-primary px-6 py-2 text-base rounded hover:text-white hover:bg-primary focus:outline-none '}
                        >
                          Upgrade Subscription
                        </button>
                      </div>
                    }
                  </div>
            </div>
          </Row>

          <Row className='w-full pl-2 pt-7'>
            <div className='mb-4'>
              <div className='font-semibold text-lg text-center pb-1'>Network</div>
              <div className='mb-2 ml-3 flex items-center'>
                {checkSubscription(subscriptionPropertyMap['Member listing']) ?
                  <Icon type="check" className='text-primary font-bold' /> :
                  <Icon type="close" className='text-red-500 font-bold' />
                }
                <span className='ml-1'>
                  Member listing
                </span>
              </div>

              <div className='mb-2 ml-3 flex items-center'>
                {checkSubscription(subscriptionPropertyMap['Meet ups']) ?
                  <Icon type="check" className='text-primary font-bold' /> :
                  <Icon type="close" className='text-red-500 font-bold' />
                }
                <span className='ml-1'>
                  Member Meet Ups
                  <span className='ml-2 text-event-going'>
                    <br></br>({checkRemaining('Meet ups')})
                    {/* {!isSubscription && <small><em> {labelMap[subscription.billing]}</em></small>}) */}
                  </span>
                </span>

              </div>

              <div className='mb-2 ml-3 flex items-center'>
                {checkSubscription(subscriptionPropertyMap['Be MPowered Conference']) ?
                  <Icon type="check" className='text-primary font-bold' /> :
                  <Icon type="close" className='text-red-500 font-bold' />
                }
                <span className='ml-1'>
                  Signature special events
                  <span className='ml-2 text-event-going'>
                    <br></br>({checkRemaining('Be MPowered Conference')})
                  </span>
                </span>
              </div>
            </div>
          </Row>

          <Row className='w-full pl-3 pt-7'>
            <div className='mb-4'>
              <div className='font-semibold text-lg text-center pb-1'>Promote</div>
              <div className='mb-2 ml-3 flex items-center'>
                {checkSubscription(subscriptionPropertyMap['Advertising']) ?
                  <Icon type="check" className='text-primary font-bold' /> :
                  <Icon type="close" className='text-red-500 font-bold' />
                }
                <span className='ml-1'>
                  Advert in Mumbition Podcast
                </span>
              </div>
            </div>
          </Row>

          <Row className='w-full pl-3 pt-7'>
            <div className='mb-4'>
              <div className='font-semibold text-lg text-center pb-1'>Upskill</div>
              <div className='mb-2 ml-3 flex items-center'>
                {checkSubscription(subscriptionPropertyMap['Personalised Action Plan']) ?
                  <Icon type="check" className='text-primary font-bold' /> :
                  <Icon type="close" className='text-red-500 font-bold' />
                }
                <span className='ml-1'>
                  Personalised Action Plan
                </span>
              </div>

              <div className='mb-2 ml-3 flex items-center'>
                {checkSubscription(subscriptionPropertyMap['Digital Lessons & Resources']) ?
                  <Icon type="check" className='text-primary font-bold' /> :
                  <Icon type="close" className='text-red-500 font-bold' />
                }
                <span className='ml-1'>
                  Digital library resources
                </span>
              </div>

              <div className='mb-2 ml-3 flex items-center'>
                {checkSubscription(subscriptionPropertyMap['Expert Group Session']) ?
                  <Icon type="check" className='text-primary font-bold' /> :
                  <Icon type="close" className='text-red-500 font-bold' />
                }
                <span className='ml-1'>
                  Expert Group Sessions
                  <span className='ml-2 text-event-going'>
                    <br></br>({checkRemaining('Expert Group Session')})
                  </span>
                </span>
              </div>

              <div className='mb-2 ml-3 flex items-center'>
                {checkSubscription(subscriptionPropertyMap['Expert 1:1 Session']) ?
                  <Icon type="check" className='text-primary font-bold' /> :
                  <Icon type="close" className='text-red-500 font-bold' />
                }
                <span className='ml-1'>
                  1:1 Expert Sessions
                  <span className='ml-2 text-event-going'>
                    <br></br>({checkRemaining('Expert 1:1 Session')})
                  </span>
                </span>
              </div>

              <div className='mb-2 ml-3 flex items-center'>
                {checkSubscription(subscriptionPropertyMap['Pay as you go']) ?
                  <Icon type="check" className='text-primary font-bold' /> :
                  <Icon type="close" className='text-red-500 font-bold' />
                }
                <span className='ml-1'>
                  Pay As You Go
                </span>
              </div>
            </div>
          </Row>
        </div>
      </div>

    </div>
  );
};

export default compose(withApollo, withRouter)(Card);
