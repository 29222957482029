import { useSubscription } from "@apollo/react-hooks";
import { Form, Icon, Input, Spin } from "antd";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import withApollo from "../../config/with-apollo";
import { S_MESSAGE } from "../../graphql/messages";
import { PageContext } from "../../helpers/context";
import _ from "lodash";
import { transformImageUrl } from "../../utils/tools";

const MessagesThread = (props) => {
  const {
    state,
    handleSendMessage,
    handleUpdateMessageSeen,
    handleGetConnection,
    handleSetMessage,
    USER,
    handleGetUser,
  } = useContext(PageContext);

  const { loading: messagesLoading, data: messagesData } = useSubscription(S_MESSAGE, {
    client: props.client,
    fetchPolicy: "network-only",
    variables: {
      where: {
        connection_id: {
          _eq: state.currentMessage ? state.currentMessage.id : USER.id,
        },
      },
    },
  });

  // useEffect(() => {
  //   setTimeout(() => {
  //     let threadContainer = document.getElementById('thread-container');
  //     threadContainer.scrollTop = threadContainer.scrollHeight;
  //     handleUpdateMessageSeen();
  //   }, 0);
  // }, [document, messagesData]);

  const userAvatar = (user_from, className) => {
    return (
      state.currentMessage.type === "group" && (
        <div className={className}>
          {user_from.avatar ? (
            <div
              onClick={handleGetUser(user_from.id)}
              className={
                "zester-dashboard-flex cursor-pointer rounded-full w-8 h-8 bg-cover bg-no-repeat bg-center"
              }
              style={{ backgroundImage: `url(${transformImageUrl(user_from.avatar)})` }}
            />
          ) : (
            <div
              onClick={handleGetUser(user_from.id)}
              className={
                "w-8 h-8 bg-event-going cursor-pointer text-white flex items-center justify-center text-xl font-grumpy zester-dashboard-flex rounded-full overflow-hidden"
              }
            >
              {user_from.name && user_from.name[0]}
            </div>
          )}
        </div>
      )
    );
  };

  const renderUserInfo = (user) => {
    let label = null;
    const business = user.businesses.length ? user.businesses[0] : null;
    if (business && business.name) {
      label = `${user.name} is from ${(business || {}).name}`;
    } else if (user.subscription_type) {
      label = `${user.name} is a ${_.startCase(_.lowerCase(user.subscription_type))} member`;
    } else {
      label = `${user.name} has been a member since ${moment(user.created_at).format("LL")}`;
    }

    const desc = (business || {}).about || user.about_me || null;
    return (
      <>
        {label}
        <div>{desc}</div>
      </>
    );
  };

  const getFirstName = (name) => {
    const names = name.split(" ");
    names.pop();
    return names.join(" ");
  };
  const renderBusinessIntroMsg = (creatorId) => {
    const { connections_users } = state.currentMessage;
    const userList = connections_users.filter((data) => data.user.id !== creatorId);
    const names = [...userList].map((data) => getFirstName(data.user.name));
    const lastName = names.pop();
    return (
      <div className="p-4 text-black">
        <div>
          {/* <div className='font-bold'>Introducing you both before Be MPowered 13 October</div> */}
          <div>
            Hi <span className="font-semibold">{names.join(", ")}</span> and{" "}
            <span className="font-semibold">{lastName}</span>
          </div>
        </div>
        <div className="mt-4">
          I'm pleased to make this introduction in advance of Be MPowered on 13 October, where you
          have both registered to attend.
        </div>
        {userList.map((data) => {
          const { user } = data;
          return (
            <div className="mt-4">
              {renderUserInfo(user)}
              <div className="mt-1">
                You can find out more at {getFirstName(user.name)}'s member profile{" "}
                <span
                  onClick={handleGetUser(user.id)}
                  className="cursor-pointer underline text-primary"
                >
                  here
                </span>
                .
              </div>
            </div>
          );
        })}
        <div className="mt-4">
          In short: you're both inspiring and engaging and I think you should meet!
        </div>
        <div className="mt-4">
          Please let me know if there's anything else I can share for now and do look out for each
          other online or in person at Be MPowered.
        </div>
        <div className="mt-4">Over to you, and I hope this introduction is a fruitful one!</div>
        <div className="mt-4">Warmest,</div>
        <div>Lucy</div>
        <div>Mums & Co Community Manager</div>
      </div>
    );
  };

  const Thread = useCallback(() => {
    return (
      state.currentMessage &&
      !messagesLoading &&
      messagesData.messages.map((value) => {
        const { user_from } = value;
        return (
          <div
            key={value.id}
            className={`flex mb-2 ${value.from === USER.id ? "justify-end" : "justify-start"}`}
          >
            {value.from !== USER.id && userAvatar(user_from, "mr-2")}
            <div
              className={`flex flex-col max-w-xl ${
                value.from === USER.id ? "items-end" : "items-start"
              }`}
            >
              <div
                className={`p-2 bg-white text-sm rounded`}
                dangerouslySetInnerHTML={{
                  __html:
                    value.message === "%business_intro_message%"
                      ? renderBusinessIntroMsg(value.from)
                      : value.message,
                }}
              />

              <div
                className={`text-xs w-full mt-1 flex ${
                  value.from === USER.id ? "justify-start" : "justify-end"
                }`}
              >
                {moment(value.created_at).format("lll")}
              </div>
            </div>
            {value.from === USER.id && userAvatar(user_from, "ml-2")}
          </div>
        );
      })
    );
  }, [messagesLoading, messagesData, state.currentMessage]);

  const userList = useMemo(() => {
    if (!((state.currentMessage || {}).connections_users || []).length) return;
    const connectionList = state.currentMessage.connections_users;
    const members = connectionList
      .filter(({ user }) => user.id !== USER.id)
      .map(({ user }) => user.name);
    const lastMember = members.pop();
    return `You, ${members.join(", ")} and ${lastMember}`;
  }, [(state.currentMessage || {}).connections_users]);

  return (
    <div className={"h-full relative"}>
      <div className={"p-3 bg-white shadow flex"}>
        {state.currentMessage ? (
          state.currentMessage.type === "group" ? (
            <div className="mr-3 w-10 h-10 bg-primary text-white flex items-center justify-center text-xl font-grumpy rounded-full overflow-hidden">
              <Icon type="team" />
            </div>
          ) : handleGetConnection(state.currentMessage).avatar ? (
            <div
              onClick={handleGetUser(handleGetConnection(state.currentMessage || {}).id)}
              className={
                "rounded-full w-10 h-10 mr-3 cursor-pointer bg-cover bg-no-repeat bg-center"
              }
              style={{
                backgroundImage: `url(${transformImageUrl(
                  handleGetConnection(state.currentMessage).avatar
                )})`,
              }}
            />
          ) : (
            <div
              onClick={handleGetUser(handleGetConnection(state.currentMessage || {}).id)}
              className={
                "w-10 h-10 mr-3 bg-event-going text-white flex items-center justify-center cursor-pointer text-xl font-grumpy rounded-full overflow-hidden"
              }
            >
              {handleGetConnection(state.currentMessage || {}).name &&
                handleGetConnection(state.currentMessage || {}).name[0]}
            </div>
          )
        ) : null}
        <div className={"text-black font-medium"}>
          <div>
            {state.currentMessage
              ? state.currentMessage.type === "group"
                ? state.currentMessage.name
                : handleGetConnection(state.currentMessage).name
              : "Make new connections and message them here!"}
          </div>
          <div className={"flex flex-row"}>
            {state.currentMessage ? (
              state.currentMessage.type === "group" ? (
                <div
                  style={{ color: "rgba(0, 0, 0, 0.65)" }}
                  className="bg-white text-xs font-normal"
                >
                  {userList}
                </div>
              ) : (
                handleGetConnection(state.currentMessage).businesses.map(({ name, id }) => {
                  return (
                    name && (
                      <div key={id} className={"px-1 rounded bg-gray-300 text-black text-xs mr-3"}>
                        {name}
                      </div>
                    )
                  );
                })
              )
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div
        id={"thread-container"}
        className={`${
          state.currentMessage && state.currentMessage.type === "group" ? "px-4" : "px-6"
        } mt-4 h-95 overflow-scroll`}
      >
        {messagesLoading && (
          <div className={"w-full flex justify-center"}>
            <Spin />
          </div>
        )}
        <Thread />
      </div>
      <Form onSubmit={handleSendMessage}>
        <div className={"px-6 mb-3 flex flex-col absolute w-full bottom-0"}>
          <div className={"mb-2"}>
            <Input.TextArea
              onChange={handleSetMessage}
              onKeyDown={(event) => {
                if (event.key === "Enter" && !event.shiftKey) {
                  event.preventDefault();
                  handleSendMessage();
                }
              }}
              rows={1}
              maxLength={500}
              value={state.message}
              disabled={!state.currentMessage}
              className={
                "h-10.5 message-input border rounded border-light-gray-header w-full text-black font-normal font-sans p-3"
              }
              style={{ fontSize: "16px" }}
            />
          </div>
          <p className={"font-thin mb-3"} style={{ fontSize: "0.625rem" }}>
            {state.message ? 500 - state.message.length : "500"} characters remaining{" "}
          </p>
          <button
            disabled={!state.currentMessage}
            type={"submit"}
            className={`flex self-end  rounded py-2 px-3 text-white ${
              !state.currentMessage ? "bg-disabled" : "bg-primary"
            }`}
          >
            Send
          </button>
        </div>
      </Form>
    </div>
  );
};

export default withApollo(MessagesThread);
