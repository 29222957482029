import { useSubscription } from "@apollo/react-hooks";
import { Rate } from "antd";
import "antd/es/rate/style/css";
import moment from "moment";
import React, { useContext, useState } from "react";
import { compose } from "react-apollo";
import { withRouter } from "react-router-dom";
import withApollo from "../../config/with-apollo";
import { S_DIGITAL_LIBRARY_REVIEWS } from "../../graphql/digital-libraries";
import { PageContext } from "../../helpers/context";
import UserModal from "../modals/user-modal";
import { transformImageUrl } from "../../utils/tools";

const DigitalLibraryCommentDetails = (props) => {
  const { client, match } = props;
  const ID = match.params.id;
  const { data: digitalLibraryData } = useContext(PageContext);
  const { loading, data } = useSubscription(S_DIGITAL_LIBRARY_REVIEWS, {
    variables: { digitalLibraryId: ID },
    client,
  });
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const urlify = (text) => {
    let urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;

    return text.replace(urlRegex, function(url) {
      let parseUrl;

      if (url.indexOf("https") !== -1 || url.indexOf("http") !== -1) {
        parseUrl = url;
      } else {
        parseUrl = `//${url}`;
      }

      return `<a class="text-primary underline" target="_blank" href="${parseUrl}">${url}</a>`;
    });
  };

  const handleShowUserModal = (id) => () => {
    setSelectedId(id);
    setShowUserModal(true);
  };

  const comments = () => {
    if (!data.digital_library_reviews.length) {
      return <p className={"text-lg text-black font-bold"}>No Reviews</p>;
    }
    return (
      <>
        <p className={"text-lg text-black font-bold mb-5"}>
          Reviews{" "}
          <span className={"text-black font-medium"}>
            (
            {
              digitalLibraryData.digital_library_by_pk.digital_library_reviews_aggregate.aggregate
                .count
            }
            )
          </span>
        </p>
        <PageContext.Provider
          value={{ showUserModal, setShowUserModal, selectedId, setSelectedId }}
        >
          <UserModal source="CO" />
          {data.digital_library_reviews.map((value) => {
            return (
              <div className={"mb-8"}>
                <div
                  className={
                    "bg-light-gray-header p-4 text-lg rounded text-comment-color mb-4 pointer-bottom"
                  }
                >
                  <div className={"mb-2 flex flex-row"}>
                    <Rate
                      allowHalf
                      value={value.rating}
                      className={"text-primary text-base mr-2"}
                      disabled
                    />
                    <p className={"text-base text-black font-medium flex self-center"}>
                      {value.rating}
                    </p>
                  </div>
                  <div
                    className={"break-words"}
                    dangerouslySetInnerHTML={{ __html: urlify(value.review) }}
                  />
                </div>
                <div
                  onClick={handleShowUserModal(value.user.id)}
                  className={"flex flex-row items-center cursor-pointer"}
                >
                  <div className={"w-1/6 md:w-12"}>
                    {value.user.avatar ? (
                      <div
                        className={"rounded-full w-10 h-10 bg-cover bg-no-repeat bg-center"}
                        style={{ backgroundImage: `url(${transformImageUrl(value.user.avatar)})` }}
                      />
                    ) : (
                      <div
                        className={
                          "flex items-center justify-center font-grumpy text-white text-xl hidden md:inline-flex bg-event-going rounded-full h-10 w-10"
                        }
                      >
                        {value.user.name[0].toUpperCase()}
                      </div>
                    )}
                  </div>
                  <div className={"w-full"}>
                    <div className={"flex flex-row justify-between mb-2"}>
                      <span className={"text-sm text-black font-bold hover:text-primary"}>
                        {value.user.name}
                      </span>
                      <span className={"text-xs text-black"}>
                        {moment(value.created_at).format("lll")}
                      </span>
                    </div>
                    <div className={"flex flex-row"}>
                      {value.user.businesses.map(({ name, id }) => {
                        return (
                          name && (
                            <div
                              key={id}
                              className={"px-3 rounded bg-gray-300 text-black text-sm mr-3"}
                            >
                              {name}
                            </div>
                          )
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </PageContext.Provider>
      </>
    );
  };

  return !loading && comments();
};

export default compose(withRouter, withApollo)(DigitalLibraryCommentDetails);
