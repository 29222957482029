import React from "react";
import logo from "../../assets/icons/mums_and_co_logo.png";
import { transformImageUrl } from "../../utils/tools";

const Terms = () => {
  return (
    <div className={"min-h-screen bg-light-gray"}>
      <div className={"w-full flex justify-center mb-10 bg-primary py-5"}>
        <img src={transformImageUrl(logo)} alt="" />
      </div>
      <div className={"container mx-auto pb-10 px-16 text-black text-sm terms"}>
        <div className="w-richtext">
          <h3 className={"text-4xl font-grumpy font-bold"}>
            EVENT TICKET SALES TERMS AND CONDITIONS
          </h3>
          {/* <p className='font-bold'>Effective as at 21 July 2023.</p> */}
          <p className="font-bold">
            These Ticket Sales Terms and Conditions (Terms) apply to your purchase of event tickets
            from Insurance Australia Limited t/a Mums & Co (ABN 11 000 016 722) (we, us, our).{" "}
          </p>
          {/* <p>These Terms and Conditions govern the registration for operation and membership of Mums & Co, a business which is part of the IAG Group. Some member benefits and membership activities offered to you from time to time may be subject to separate or additional terms and conditions which will be notified to you in advance.</p> */}
          <p>1. Ticket Registration</p>
          <div className="pl-4">
            <p>
              (a) You may register for an event as set out on the website. You may register on your
              own behalf or on another attendee’s behalf if you have their consent to the
              registration and you have made them aware of these Terms.
            </p>
            <p>
              (b) It is your responsibility to check the registration details, including selected
              ticket and ticket type, quantity of tickets, venue, time and pricing, before you
              submit your registration through the website.
            </p>
            <p>
              (c) When you register and pay on the website and your payment has been validated, we
              will provide you with a copy of your ticket(s) in PDF form and/or a QR code by email
              to the email address provided at the time of registration.
            </p>
            <p>
              (d) If you register for a concession ticket, you might need to show valid proof of
              identification at the event. If you do not show the right identification, you'll need
              to pay the rest of the full ticket price or you won't be let in.
            </p>
            <p>
              (e) We are only responsible for the inclusions set out on the event page of the
              website.
            </p>
            <p>
              (f) You acknowledge and agree that your ticket does not include any transport to or
              from the event or any accommodation.
            </p>
          </div>
          <p>2. Price and Payment</p>
          <div className="pl-4">
            <p>
              (a) You must pay us the purchase price of each ticket you register for at the time you
              register for your ticket(s). All amounts are stated in Australian dollars and
              Australian GST will be set out separately (where applicable).
            </p>
            <p>
              (b) If we offer payment via a payment processor, the payment may be subject to the
              payment processor’s terms and conditions. You will be charged any processor fees in
              addition to the ticket price, which will be set out at point of sale.
            </p>
            <p>
              (c) As a member, you may qualify for an event ticket either through your membership
              with us or by utilizing your membership tokens. Your use of the membership and tokens
              is subject to the{" "}
              <a
                className="text-primary underline hover:text-primary hover:underline"
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.mumsandco.com.au/membership-terms-and-conditions"
              >
                Membership Terms and Conditions
              </a>
              .
            </p>
          </div>
          <p>3. Disclaimers</p>
          <div className="pl-4">
            <p>
              (a) Any comments or opinions shared by a speaker or an event sponsor are their own and
              do not represent our views or beliefs. Unless specifically mentioned, we neither
              endorse nor confirm their statements, including any recommendations or promotions they
              might offer.
            </p>
            <p>
              (b) We cannot vouch for the accuracy of the information given by speakers or sponsors
              during the event. If you choose to rely on their words, we won't be held responsible
              for any consequences arising from such reliance.
            </p>
          </div>
          <p>4. Changes, refunds and cancellations</p>
          <div className="pl-4">
            <p className="underline mx-1">Changes to programmes</p>
            <p>
              (a) We reserve the right to change event programmes or speakers at any time. Every
              effort will be made to ensure programmes or speakers are of an equivalent standard.
            </p>
            <p className="underline mx-1">Errors on website</p>
            <p>
              (b) We reserve the right to cancel a ticket registration, at any time before an event,
              if the ticket registration was subject to an error on the website (for example, in
              relation to an event description or price). If an error occurs, we will contact you
              using the details you provided when you registered and we will endeavor to give you
              the option of purchasing the ticket at the correct price (if any) or provide you with
              a refund.
            </p>
            <p className="underline mx-1">Ticketing mistake</p>
            <p>
              (c) If you request us to reprocess your ticket(s) due to an error on your behalf, we
              may charge you any additional fees incurred in reprocessing your registration
              (including chargeback fees or other fees incurred by our payment provider).
            </p>
            <p className="underline mx-1">Your rescheduling, cancellation or name change</p>
            <p>
              (d) We may offer a refund for a change of mind if requested within the timeframe set
              out on the event description on our website. If you are eligible, we will refund you
              the amount you paid for your ticket, less the fees charged by our payment processor.
            </p>
            <p className="underline mx-1">Our cancellation</p>
            <p>
              (e) If the event or experience you have purchased tickets for cannot go ahead or we
              are required to reduce the number of persons that can attend the event or experience
              due to public health restrictions, we will contact you and provide you with an option
              to transfer your ticket to the following year or for another event or experience of
              equal value, to receive a refund or to transfer your ticket to a digital offering.
            </p>
            <p>
              (f) You may request a refund or exchange, from us, where the event you registered for
              is cancelled, rescheduled or significantly relocated by us (and where you do not wish
              to attend the rescheduled or relocated event).
            </p>
            <p>
              (g) Unless required by law (including the Australian Consumer Law), we will not be
              liable for, or cover the costs, of any other losses incurred by you as a result of the
              cancellation, rescheduling or relocation of an event, including any travel and
              accommodation expenses. You must cover these yourself. We recommend you carefully
              consider the refund and cancellation policies of any travel, transport or
              accommodation you book in anticipation of attending an event you have registered for
              on the website.
            </p>
            <p className="underline mx-1">Australian Consumer Law</p>
            <p>
              (h) Certain legislation including the Australian Consumer Law (ACL) in the Competition
              and Consumer Act 2010 (Cth), and similar consumer protection laws and regulations may
              confer you with rights, warranties, guarantees and remedies relating to our provision
              of our services which cannot be excluded, restricted or modified.
            </p>
            <p>
              (i) Nothing in these Terms attempts to modify or exclude the conditions, warranties
              and undertakings, and other legal rights, under ACL.
            </p>
          </div>
          <p>5. Behaviour</p>
          <div className="pl-4">
            <p>(a) By registering for an event, you agree: </p>
            <div className="pl-4">
              <p>(1) To comply with these Terms and our reasonable requests and requirements;</p>
              <p>(2) To comply with the venue rules where the event is hosted;</p>
              <p>
                (3) If applicable, to inform us of your dietary requirements on the website when
                registering for the event. If you do not provide us with your dietary requirements,
                we will assume you have no dietary requirements;
              </p>
              <p>
                (4) You will be considerate and respectful to all event attendees and at related
                social events;
              </p>
              <p>
                (5) You will not consume excessive amounts of alcohol or be under the influence of
                non-prescription drugs;
              </p>
              <p>
                (6) We do not tolerate harassment, bullying, or any other unacceptable behavior;
              </p>
              <p>
                (7) To hold all necessary insurances for your attendance at the event, including
                travel insurance; and
              </p>
              <p>
                (8) To not do anything that may put us, our business, our brand, or the event into
                disrepute.
              </p>
            </div>
            <p>
              (b) You agree that we reserve the right to remove you from the event if we or the
              event hosts consider that your behaviour constitutes a breach of this clause or a
              breach of any other provision of these Terms. No refund will be due to you as a result
              of any unused portion of your ticket.
            </p>
          </div>
          <p>6. Resale of Tickets</p>
          <div className="pl-4">
            <p>
              (a) You agree not to offer tickets sold by us for resale or to use tickets for
              advertising, promotional or other commercial purposes, including trade promotions or
              competitions.
            </p>
            <p>
              (b) If you sell a ticket or it is used in breach of these Terms, we may cancel the
              validity of the ticket without refund to you, and the ticketholder may be refused
              admission to the relevant event.
            </p>
            <p>
              (c) The resale of tickets in certain circumstances may attract criminal penalties
              under the relevant legislation.
            </p>
          </div>
          <p>7. Intellectual Property </p>
          <div className="pl-4">
            <p>
              (a) You acknowledge and agree that all intellectual property in our materials
              including our logo, trademark, and any information or documents we make available
              vest, or remain vested, in us.
            </p>
            <p>
              (b) We authorize you to use our materials solely for your personal use. You must not
              exploit our materials for any other purpose, nor allow, aid, or facilitate such use by
              any third party. You may only access our materials on your personal device, and you
              may not use our materials for any commercial purpose.
            </p>
            <p>
              (c) You acknowledge and agree that any intellectual property or content used at an
              event by a speaker or vendor/sponsor is owned or licensed by the relevant speaker or
              vendor/sponsor (Exhibitor’s Materials).
            </p>
            <p>
              (d) You must not, without our, or the relevant speaker or vendor/sponsor’s, prior
              written consent:
            </p>
            <div className="pl-4">
              <p>
                (1) copy, in whole or in part, any of our materials or an Exhibitor’s Materials;
              </p>
              <p>
                (2) reproduce, retransmit, distribute, disseminate, sell, publish, broadcast or
                circulate any of our materials or an Exhibitor’s Materials to any third party;
              </p>
            </div>
            <p>(e) This clause will survive termination or expiry of these Terms.</p>
          </div>
          <p>8. Limitation of Liability </p>
          <div className="pl-4">
            <p>(a) Despite anything to the contrary, to the maximum extent permitted by law: </p>
            <div className="pl-4">
              <p>
                (1) our maximum aggregate liability arising from or in connection with the Terms
                (including the event and/or the subject matter of the Terms) will be limited to, and
                must not exceed, the portion of the price paid by you to us for the ticket the
                subject of the relevant claim, or where no price was paid, $100; and
              </p>
              <p>
                (2) we will not be liable to you for any loss of profit (including anticipated
                profit), loss of benefit (including anticipated benefit), loss of revenue, loss of
                business, loss of goodwill, loss of opportunity, loss of savings (including
                anticipated savings), loss of reputation, loss of use and/or loss or corruption of
                data, whether under statute, contract, equity, tort (including negligence),
                indemnity or otherwise.
              </p>
            </div>
            <p>
              (b) Despite anything to the contrary, to the maximum extent permitted by law, we will
              have no liability, and you release and discharge us from all liability, arising from
              or in connection with any:
            </p>
            <div className="pl-4">
              <p>
                (1) loss of, or damage to, any ticket, or any injury or loss to any person as a
                result of attending or not attending an event;
              </p>
              <p>
                (2) any third parties or any services or events provided by third parties, including
                hotels, partners, venues, or other subcontractors which the provision of the events
                may be contingent on, or impacted by;
              </p>
              <p>(3) the website or an event being unavailable;</p>
              <p>(4) breach of the Terms or any law, </p>
              <p>where caused or contributed to by any:</p>
              <p>(5) event or circumstance beyond our reasonable control; or</p>
              <p>(6) act or omission of you or your related parties,</p>
              <p>
                and, in any event, any defect, error, omission or lack of functionality or
                suitability (or the absence of, or reduction in, any anticipated result, outcome or
                benefit) with respect to the event you purchase a ticket for.{" "}
              </p>
            </div>
            <p>
              (c) Despite anything to the contrary, to the maximum extent permitted by law, you are
              liable for and agree to indemnify, hold harmless, release and discharge, us in respect
              of any liability which we may suffer, incur or are otherwise liable for as a result
              of, or in connection with any loss or damage to any person or property at an event
              that arises as a result of any negligent acts or omissions of you or people you have
              purchased a ticket for or where you (or a person you purchase a ticket for) refuse to
              follow our reasonable directions (including with regards to safety procedures).
            </p>
          </div>
          <p>9. Privacy </p>
          <div className="pl-4">
            <p>
              (a) We collect personal information about you in order to enable you to access and use
              the website, to process your registration and provide your ticket(s), to contact and
              communicate with you, to register your attendance at our events, to coordinate with
              third-party service providers, to respond to your inquiries, and for other purposes
              set out in our{" "}
              <a
                className="text-primary underline hover:text-primary hover:underline"
                rel="noopener noreferrer"
                target="_blank"
                href="https://app.mumsandco.com.au/privacy-and-security"
              >
                Privacy Policy
              </a>{" "}
              (available on our website).
            </p>
            <p>
              (b) We may disclose that information to our third-party service providers who help us
              deliver our services (including our information technology service providers, data
              storage, web-hosting and server providers, professional advisors, payment systems
              operators, event caterers, and our business partners), or as required by law. If you
              do not provide this information, we may not be able to provide our services to you. In
              certain circumstances, we may disclose your personal information to third parties
              located, or who store data, outside Australia.
            </p>
            <p>
              (c) We may also prepare a list of attendees. This list may be shared with other
              attendees, any third party hosting the event alongside us, and with vendors/sponsors.
              If you do not want to be on this list and for your name to be passed on, you must
              opt-out when registering or by using the email below.
            </p>
            <p>
              (d) Our Privacy Policy contains further information about: (i) how we store and use
              your personal information; (ii) how you can access and seek correction of your
              personal information; (iii) how you can make a privacy-related complaint; and (iv) our
              complaint handling process.
            </p>
            <p>
              (e) By providing personal information to us, you acknowledge we will collect, hold,
              use, and disclose your personal information in accordance with our Privacy Policy.
            </p>
          </div>
          <p>10. General</p>
          <div className="pl-4">
            <p>
              (a) <strong>Assignment:</strong> You must not assign any rights or obligations under
              these Terms, whether in whole or in part, without our prior written consent.
            </p>
            <p>
              (b) <strong>Filming and Photographing Events:</strong> When attending our events, we
              might take pictures or videos, and you might be in them. Unless you tell us otherwise,
              we can use these images or videos for promotional reasons, like on our social media.
              You won't be paid for this use. If you don’t want to be in the photos or videos, let
              us know in writing before the event. Also, you shouldn't take your own photos or
              videos without our written permission. If you want any of the official photos taken,
              you can request them from us.
            </p>
            <p>
              (c) <strong>Your Photographs:</strong> If you provide us with photographs of yourself
              at an event, including via email or by tagging us on social media, you consent to us
              publishing such photographs and details (including but not limited to your name,
              location, and date) for our marketing purposes including but not limited to on our
              website, in our social media, on our content sites, and to supply to publishers
              covering the event.
            </p>
            <p>
              (d) <strong>Disputes:</strong> Neither party may commence court proceedings relating
              to any dispute arising from, or in connection with, these Terms without first meeting
              with a senior representative of the other party to seek (in good faith) to resolve
              that dispute (unless that party is seeking urgent interlocutory relief or the dispute
              relates to compliance with this clause).
            </p>
            <p>
              (e) <strong>Governing Law:</strong> These Terms are governed by the laws of New South
              Wales. Each Party irrevocably and unconditionally submits to the exclusive
              jurisdiction of the courts operating in New South Wales and any courts entitled to
              hear appeals from those courts and waives any right to object to proceedings being
              brought in those courts.
            </p>
          </div>
          <p className={"font-bold"}>
            <strong>For any questions and notices, please contact us at:</strong>
          </p>
          <p>Insurance Australia Limited t/a Mums & Co (ABN 11 000 016 722)</p>
          <p>Email: hello@mumsandco.com.au </p>
          <p>
            ©{" "}
            <a
              className="text-primary underline hover:text-primary hover:underline"
              rel="noopener noreferrer"
              target="_blank"
              href="https://legalvision.com.au/"
            >
              LegalVision
            </a>{" "}
            ILP Pty Ltd
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
