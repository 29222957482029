import { Form } from "antd";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import coverLogin from "../../assets/icons/cover-login.png";
import inputEmailIcon from "../../assets/icons/input-icon-email.png";
import inputIconError from "../../assets/icons/input-icon-error.png";
import inputPasswordIcon from "../../assets/icons/input-icon-password.png";
import mumsAndCoLogo from "../../assets/icons/mums_and_co_logo_login.png";
import mumsAndCoLogoWhite from "../../assets/icons/mums_and_co_logo.png";
import CustomInput from "../../components/common/input";
import { post, setHeaders } from "../../utils/api-handler";
import { LOGIN_URL } from "../../utils/url-handler";
import { transformImageUrl } from "../../utils/tools";
const Login = (props) => {
  const {
    form: { getFieldDecorator, validateFields, getFieldError, setFieldsValue },
    location,
  } = props;
  const [error, setError] = useState(null);

  useEffect(() => {
    if (location && location.search) {
      let search = new URLSearchParams(location.search);

      let type = search.get("type"),
        username = search.get("email"),
        message = search.get("message");

      if (type === "error") {
        setError(message);
        setFieldsValue({ username });
      }
    }
  }, []);

  const rules = {
    username: [
      { required: true, message: "Email is required" },
      { type: "email", message: "Please enter a valid email address" },
    ],
    password: [
      { required: true, message: "Password is required" },
      { min: 8, message: "Please enter at least 8 characters" },
    ],
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    validateFields(async (errors, values) => {
      try {
        if (!errors) {
          const response = await post(LOGIN_URL, values);
          if (response.data.status === "DEACTIVE") {
            return setError(
              <>
                Your account has been deleted. Please{" "}
                <a
                  className={"text-primary focus:text-primary hover:text-primary cursor-pointer"}
                  target={"_blank"}
                  href={"https://www.mumsandco.com.au/contact-us"}
                >
                  contact support
                </a>{" "}
                for further assistance.
              </>
            );
          }

          setHeaders("Authentication", `Bearer ${response.data.token}`);
          localStorage.setItem(process.env.REACT_APP_TOKEN, JSON.stringify(response.data));

          let returningUrl = sessionStorage.getItem("mumsnco-returning-url");

          if (returningUrl) {
            window.location = returningUrl;
          } else {
            window.location.href = process.env.REACT_APP_DEFAULT_HOME;
          }

          sessionStorage.removeItem("mumsnco-returning-url");
        }
      } catch (e) {
        if (e.response.status === 404 || e.response.status === 403) {
          setError(`Your email address and password doesn't match`);
        }
      }
    });
  };

  return (
    <>
      <div className={"h-full bg-white lg:flex lg:flex-row-reverse"}>
        <div className={"lg:w-3/4 bg-primary justify-between flex flex-col pt-20 lg:pt-0"}>
          <div className={"w-full flex flex-row z-10 lg:inline-flex hidden "}>
            <div className={"md:w-full flex items-center justify-center"}>
              <div className={"text-white text-2xl px-10 xl:px-40 mt-44 flex flex-col font-thin"}>
                <div className={"text-center"}>
                  <img alt={""} src={mumsAndCoLogoWhite} className={"m-auto mb-10"} />
                  <div className={"font-grumpy text-3xl mb-6"}>
                    Mums & Co helps women in business grow
                  </div>
                  <div className={"text-xl"}>
                    We advocate for the ambition of Australia's business owning women by providing a
                    digital membership to help you build deep networks, access strategic guidance,
                    resources and support at every stage of business.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img alt={""} src={coverLogin} className={"w-full h-auto"} />
        </div>
        <div className={"flex flex-col mt-4 px-10 md:px-40 lg:mt-40 lg:px-28 lg:w-3/4 xl:w-1/3"}>
          <Form onSubmit={handleSubmit}>
            <img alt={""} src={mumsAndCoLogo} className={"m-auto mb-10"} />
            {!!error && (
              <div className={"flex flex-row mb-4"}>
                <img
                  src={transformImageUrl(inputIconError)}
                  alt={""}
                  className={"w-auto h-4 ml-2"}
                />
                <p className={"text-xs text-gray-600 font-light mb-1 ml-3"}>{error}</p>
              </div>
            )}
            {getFieldDecorator("username", { rules: rules.username })(
              <CustomInput
                error={getFieldError("username")}
                placeholder={"Email"}
                className={classNames({ shadow: true, "mb-5": !getFieldError("username") })}
                prefixIcon={inputEmailIcon}
                style={{ backgroundSize: "1.25rem" }}
              />
            )}
            {getFieldDecorator("password", { rules: rules.password })(
              <CustomInput
                error={getFieldError("password")}
                type={"password"}
                placeholder={"Password"}
                className={"shadow"}
                prefixIcon={inputPasswordIcon}
                style={{ backgroundSize: "1rem", backgroundPosition: "1.15rem" }}
              />
            )}
            <button
              type={"submit"}
              className={
                "bg-primary text-white h-10 w-full text-sm mt-10 mb-4 font-grumpy font-light rounded-full focus:outline-none"
              }
            >
              Login
            </button>
            <p className={"text-xs"}>
              By clicking on Login you agree to our{" "}
              <a href={"/terms-and-conditions"} target={"_blank"} className={"text-primary"}>
                Terms
              </a>{" "}
              and{" "}
              <a href={"/privacy-and-security"} target={"_blank"} className={"text-primary"}>
                Privacy
              </a>{" "}
              rules
            </p>
            <div className={"flex justify-between mt-5"}>
              <Link to={"/register"}>
                <a href={"#"} className={"text-sm font-light text-primary cursor-pointer"}>
                  Register
                </a>
              </Link>
              <Link to={"/forgot-password"}>
                <a href={"#"} className={"text-sm font-light text-primary cursor-pointer"}>
                  Forgot Password?
                </a>
              </Link>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default withRouter(Form.create({ name: "login" })(Login));
