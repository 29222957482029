import { Modal, Icon } from 'antd';
import React, { useState, useContext } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import withApollo from '../../config/with-apollo';
import { AppContext, PageContext } from '../../helpers/context';
import { M_USER_PLAN } from '../../graphql/user';
import { post } from '../../utils/api-handler';
import { AUTH } from '../../utils/common';




const WelcomeModal = (props) => {
  const { client } = props;
  const { subscription } = props;
  const { showWelcomeModal, setShowWelcomeModal } = useContext(PageContext);
  const { user_current_plan, user_subscription_type } = useContext(AppContext);
  const USER = AUTH();
  
  const handleOnSubmit = () => {
    client.mutate({
      mutation: M_USER_PLAN,
      variables: {
        id: user_current_plan.id,
        _set: {
          available_group_expert_sessions: 0,
          available_meet_ups: 1,
          available_single_expert_sessions: 0,
          available_special_events: 0,
          status: true,
          subscription_property: "sign_up",
          subscription_type: "SIGN_UP"
        },
      },
    });
  }

  const onViewSubscription = async () => {
    localStorage.removeItem('@return-url');
    if (['sign_up', 'sign up', 'free'].includes(user_subscription_type.toLocaleLowerCase())) return props.history.push('/upgrade-account')

    const response = await post(`/create-customer-session`, {
      redirect: `${window.location.origin}/user-subscription`,
      userId: USER.id,
    });

    if (response.data.sessionURL) {
      window.open(response.data.sessionURL, "_self")
    }
  }

  return <Modal
    visible={showWelcomeModal}
    okButtonProps={{ disabled: true }}
    cancelButtonProps={{ disabled: true }}
    footer={null}
    onCancel={()=>setShowWelcomeModal(false)}
    closable={false}
  >
    <div className={'flex flex-col'}>
      <p className={'self-center text-3xl text-black'}>Welcome Back!</p>
      <p className={'self-center text-lg mb-5 text-center'}>
        We made some changes recently to membership plans at Mums and Co. As a Free user you are now on a 'Sign-up' membership plan. 
        You can still make purchases with tokens, or you can upgrade to receive access to events, experts and more.
      </p>
      <div className={'flex flex-row w-full justify-center mt-4'}>
        <button
          onClick={() =>{
            handleOnSubmit();
            setShowWelcomeModal(false);
            onViewSubscription();
          }}
          className={
            'self-center bg-event-going text-white h-10 w-full text-md md:w-2/3 font-light mr-5  rounded active:bg-primary focus:outline-none'
          }
        >
          Show me Subscription options
        </button>
        <button
          onClick={() =>{
            handleOnSubmit();
            setShowWelcomeModal(false);
          }}
          className={`self-center text-white h-10 text-md md:w-2/3 w-full font-light  rounded active:bg-primary focus:outline-none bg-primary`}
        >
          Okay,  got it!
        </button>
      </div>
    </div>
  </Modal>;
};

export default compose(
  withApollo,
  withRouter)(WelcomeModal);
