import { Modal } from "antd";
import React, { useContext } from "react";
import bigCloseIcon from "../../assets/icons/big-close-icon.png";
import { PageContext } from "../../helpers/context";
import { transformImageUrl } from "../../utils/tools";
const ModalError = (props) => {
  const { showErrorModal, setShowErrorModal } = useContext(PageContext);

  return (
    <Modal
      visible={showErrorModal}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      footer={null}
      closable={false}
    >
      <div className={"flex flex-col"}>
        <img src={transformImageUrl(bigCloseIcon)} alt={""} className={"self-center w-24 mb-6"} />
        <p className={"self-center text-center text-3xl text-black"}>{props.title || ""}</p>
        <p className={"self-center text-center text-lg mb-5"}>{props.subtitle}</p>
        <button
          onClick={() => {
            setShowErrorModal(!showErrorModal);
          }}
          className={
            "self-center bg-primary text-white h-10 text-lg px-4 font-light  rounded active:bg-primary focus:outline-none "
          }
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default ModalError;
