import { useQuery, useSubscription } from "@apollo/react-hooks";
import { Icon, Progress, Spin, Tooltip } from "antd";
import "antd/es/progress/style/css";
import "antd/es/tooltip/style/css";
import moment from "moment";
import React, { useCallback, useContext, useMemo, useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { withRouter } from "react-router-dom";
import tooltipIcon from "../../assets/icons/tooltip-icon.png";
import { post } from "../../utils/api-handler";
import WelcomeModal from "../../components/modals/welcome-back-modal";

import copy from "clipboard-copy";
import withApollo from "../../config/with-apollo";
import {
  Q_DASHBOARD_EVENT,
  Q_DASHBOARD_PERKS,
  Q_GET_ADVICE_COUNT,
  Q_GET_VIEWS_COUNT,
  Q_TOP_HIGHLIGHTS,
  Q_WELCOME_VIDEO_ACTIVE,
} from "../../graphql/dashboard";
import { Q_UNREAD_MESSAGES } from "../../graphql/messages";
import { AppContext, PageContext } from "../../helpers/context";
import { AUTH } from "../../utils/common";
import BusinessBoost from "./business-boost";
// import FooterMobile from './footer-mobile';
import { startCase } from "lodash";
import HighlightModal from "../../components/modals/highlight-modal";
import PapAccessModal from "../../components/modals/pap-access-modal";
import { transformImageUrl } from "../../utils/tools";
const twoWeeksAgo = moment().subtract(2, "week");

const Dashboard = (props) => {
  const {
    id,
    user_name,
    user_avatar,
    user_completion,
    user_business_goals_tags,
    user_expired_at,
    setShowHighlightModal,
    user_current_plan,
    user_subscription_type,
  } = useContext(AppContext);
  const { client } = props;
  const USER = AUTH();
  const { loading: eventLoading, data: eventData } = useQuery(Q_DASHBOARD_EVENT, {
    client,
    variables: { where: { event_type: { display: { _eq: "y" } } } },
  });

  const { loading: perkLoading, data: perkData } = useQuery(Q_DASHBOARD_PERKS, { client });
  const { loading: topHighlightsLoading, data: topHighlightsData } = useQuery(Q_TOP_HIGHLIGHTS, {
    client,
  });
  const { data: getAdviceData } = useQuery(Q_GET_ADVICE_COUNT, { client });
  const { loading: getViewsLoading, data: getViewsData } = useQuery(Q_GET_VIEWS_COUNT, {
    client,
    variables: {
      where: {
        member_id: { _eq: USER.id }, // right now this metric is for all users
        //created_at: { _gt: twoWeeksAgo },
      },
    },
  });
  // const { loading: todayHighlightsLoading, data: todayHighlightsData } = useQuery(Q_TODAY_HIGHLIGHTS, { client });
  const { loading: welcomeVideoLoading, data: welcomeVideoData } = useQuery(
    Q_WELCOME_VIDEO_ACTIVE,
    { client }
  );

  const { loading: unreadMessagesLoading, data: unreadMessagesData } = useQuery(Q_UNREAD_MESSAGES, {
    client,
    variables: {
      messagesUnseenWhere: {
        seen: { _eq: false },
        to: { _eq: USER.id },
      },
    },
  });
  const [showModal, setShowModal] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  const profileURL = "https://app.mumsandco.com.au/member-directory/" + USER.id;
  const [textToCopy, setTextToCopy] = useState(profileURL);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    let isUndefined = undefined;
    let isNull = null;
    if (
      user_current_plan.subscription_type === isUndefined ||
      user_current_plan.subscription_type === isNull
    ) {
      return setShowWelcomeModal(true);
    }
  }, [user_current_plan, user_subscription_type]);

  const goToLink = (url, show_modal) => () => {
    if (!url) return;
    if (show_modal) {
      if (window.innerWidth < 760) {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
      } else {
        setShowHighlightModal({ show: true, url });
      }
    } else {
      window.location.href = url;
    }
  };

  const handleCopyClick = async (e) => {
    e.stopPropagation();
    try {
      await copy(textToCopy);
      setIsCopied(true);
    } catch (error) {
      console.error("Failed to copy: ", error);
    }
  };

  const checkBetweenDates = (start, end) => {
    return moment().isBetween(
      moment(moment(start).startOf("day")).format(),
      moment(moment(end).endOf("day")).format()
    );
  };

  const eventCount = () => {
    if (!eventLoading) {
      return eventData.events.filter(({ date_to, time_to }) => {
        let dateTo = moment(date_to).format("YYYY-MM-DD");
        let timeTo = new moment(`${time_to}`, "HH:mm:ss Z").format("HH:mm:ss");

        return !moment().isAfter(moment(`${dateTo} ${timeTo}`)) && !!date_to && !!time_to;
      }).length;
    }

    return 0;
  };

  const viewCount = useCallback(() => {
    if (!getViewsLoading) return getViewsData.member_directory_tracker_aggregate.aggregate.count;

    return 0;
  }, [getViewsLoading, getViewsData]);

  const unreadCount = useCallback(() => {
    if (!unreadMessagesLoading) return unreadMessagesData.messages_aggregate.aggregate.count;

    return 0;
  }, [unreadMessagesLoading, unreadMessagesData]);

  const perkCount = () => {
    if (!perkLoading) {
      return perkData.perks.filter(({ start_date, end_date }) =>
        checkBetweenDates(start_date, end_date)
      ).length;
    }

    return 0;
  };

  const handleGoToPage = (page) => () => {
    localStorage.removeItem("@return-url");
    props.history.push(page);
    window.scrollTo(0, 0);
  };

  const onViewSubscription = async (e) => {
    e.stopPropagation();
    localStorage.removeItem("@return-url");
    if (["sign_up", "sign up", "free"].includes(user_subscription_type.toLocaleLowerCase()))
      return props.history.push("/upgrade-account");

    const response = await post(`/create-customer-session`, {
      redirect: `${window.location.origin}/user-subscription`,
      userId: USER.id,
    });

    if (response.data.sessionURL) {
      window.open(response.data.sessionURL, "_self");
    }
  };

  const showExpiryCountdown = useMemo(() => {
    return moment(user_expired_at).diff(
      moment()
        .endOf("day")
        .format("YYYY-MM-DD"),
      "days"
    );
  }, [user_expired_at]);

  const MessageComponent = useCallback(() => {
    return (
      <div
        onClick={handleGoToPage("/messages")}
        className={
          "h-18 md:h-23.5 bg-white rounded-lg flex justify-between items-center md:p-10 p-4 mb-5 w-1/2 shadow-md cursor-pointer messages_tour_class"
        }
      >
        <div>
          <Icon className={"text-xl md:text-4xl text-primary w-6 md:w-8 h-auto"} type="message" />
          {/* <img src={transformImageUrl(dashboardMessage)} alt="" className={'w-5 md:w-10 h-auto'} /> */}
        </div>
        <div className={"flex flex-col text-right"}>
          <p className={"text-sm md:text-lg font-grumpy text-black"}>{unreadCount()}</p>
          <p className={"text-xs md:text-sm"}>Unread Messages</p>
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadMessagesLoading, unreadMessagesData]);

  const CountdownOrNotificationComponent = useCallback(() => {
    // let notification = (
    //   <div
    //     className={
    //       'h-18 md:h-23.5 bg-white rounded-lg flex justify-between items-center md:p-10 p-4 mb-5 w-1/2 shadow-md cursor-pointer'
    //     }
    //   >
    //     <div>
    //       <img src={transformImageUrl(notificationIcon)} alt="" className={'w-5 md:w-8 h-auto'} />
    //     </div>
    //     <div className={'flex flex-col text-right'}>
    //       <p className={'text-sm md:text-lg italic font-light text-black'}>Coming soon</p>
    //       <p className={'text-xs md:text-sm'}>Notifications</p>
    //     </div>
    //   </div>
    // );

    // let perkComponent = (
    //   <div
    //     onClick={handleGoToPage('/offers')}
    //     className={
    //       'h-18 md:h-23.5 bg-white rounded-lg flex justify-between items-center md:p-10 p-4 mb-5 w-1/2 shadow-md cursor-pointer'
    //     }
    //   >
    //     <div>
    //       <img src={transformImageUrl(discountIcon)} alt="" className={'w-6 md:w-8 h-auto'} />
    //     </div>
    //     <div className={'flex flex-col text-right'}>
    //       <p className={'text-sm md:text-lg font-grumpy text-black'}>{perkCount()}</p>
    //       <p className={'text-xs md:text-sm'}>Offers</p>
    //     </div>
    //   </div>
    // );

    let viewComponent = (
      <div
        onClick={handleGoToPage("/member-directory")}
        className={
          "h-18 md:h-23.5 bg-white rounded-lg flex justify-between items-center md:p-10 p-4 mb-5 w-1/2 shadow-md cursor-pointer relative"
        }
      >
        {!isMobile && (
          <Tooltip
            placement="topLeft"
            title={"The number of times member profiles have been viewed in the last few days.\n"}
          >
            <img
              src={tooltipIcon}
              className={"w-4 h-auto absolute"}
              style={{ top: 5, right: 5 }}
              alt={""}
            />
          </Tooltip>
        )}
        <div className="mr-4 flex flex-col">
          <Icon className={"text-xl md:text-4xl text-event-going w-6 md:w-8 h-auto"} type="eye" />
          {/* <img src={transformImageUrl(eyeIcon)} alt="" className={'w-6 md:w-12 h-auto'} /> */}
        </div>
        <div className={"flex flex-col text-right"}>
          <p className={"text-sm md:text-lg font-grumpy text-black"}>{viewCount()}</p>
          <p className={"text-xs md:text-sm"}>Profile Views</p>
          <p
            onClick={handleCopyClick}
            className="text-xs md:text-base text-primary place-self-end hover:underline"
          >
            {isCopied ? "Copied!" : "Copy Profile Link"}
          </p>
        </div>
      </div>
    );

    return viewComponent;
  }, [showExpiryCountdown, perkCount, viewCount]);

  // const DigitalLibraryCount = useCallback(() => {
  //   return (
  //     <div
  //       onClick={handleGoToPage('/digital-library')}
  //       className={
  //         'h-18 md:h-23.5 bg-white rounded-lg flex justify-between items-center md:p-10 p-4 mr-5 mb-5 w-1/2 shadow-md cursor-pointer videos_tour_class'
  //       }
  //     >
  //       <div>
  //         <img src={transformImageUrl(videoIcon)} alt="" className={'w-6 md:w-8 h-auto'} />
  //       </div>
  //       <div className={'flex flex-col text-right'}>
  //         <p className={'text-sm md:text-lg font-grumpy text-black'}>{digitalLibraryCount()}</p>
  //         <p className={'text-xs md:text-sm'}>Videos</p>
  //       </div>
  //     </div>
  //   );
  // }, [digitalLibraryCount]);

  const EventCount = useCallback(() => {
    return (
      <div
        onClick={handleGoToPage("/events")}
        className={
          "h-18 md:h-23.5 bg-white rounded-lg flex justify-between items-center md:p-10 p-4 mr-5 mb-5 w-1/2 shadow-md cursor-pointer events_tour_class"
        }
      >
        <div>
          <Icon
            className={"text-xl md:text-4xl text-event-going w-6 md:w-8 h-auto"}
            type="schedule"
          />
          {/* <img src={transformImageUrl(eventIcon)} alt="" className={'w-6 md:w-8 h-auto'} /> */}
        </div>
        <div className={"flex flex-col text-right"}>
          <p className={"text-sm md:text-lg text-black font-grumpy"}>{eventCount()}</p>
          <p className={"text-xs md:text-sm"}>Events</p>
        </div>
      </div>
    );
  }, [eventCount]);

  const TopHighlightsList = useCallback(() => {
    if (!topHighlightsLoading && topHighlightsData) {
      return (topHighlightsData.dashboard_highlights || []).map((value, key) => {
        return (
          <div
            key={value.id}
            onClick={goToLink(value.cta, value.show_modal)}
            className={`cursor-pointer flex flex-row border-b border-gray-300 pb-3 ${key !== 0 &&
              "pt-3"}`}
          >
            <div
              className={
                "zester-dashboard-flex w-12 h-12 rounded-lg bg-no-repeat bg-center bg-cover mr-5"
              }
              style={{ backgroundImage: `url(${transformImageUrl(value.image)})` }}
            />
            <div className={"flex flex-col"}>
              <div className={"text-base font-medium text-black"}>{value.title}</div>
              <div className={"text-sm"}>{value.description}</div>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className={"w-full flex justify-center items-center h-full flex-col"}>
          <Spin size={"large"} />
          <p>Getting data...</p>
        </div>
      );
    }
  }, [topHighlightsData]);

  const TodayHighlights = useCallback(() => {
    if (!welcomeVideoLoading && welcomeVideoData) {
      const data = welcomeVideoData.dashboard_video[0];

      return (
        <div>
          <iframe
            className={`w-full h-63 bg-black mb-2`}
            src={`${process.env.REACT_APP_VIMEO_PLAYER_URL}/video/${data.video_id}`}
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
          <div className={"flex flex-row justify-between items-center px-6 pb-6 "}>
            <div className={"flex flex-col"}>
              <div className={"text-sm font-medium text-black"}>{data.title}</div>
              <div className={"text-xs"}>{data.description}</div>
            </div>
            <div>
              {!!data.cta && (
                <a
                  onClick={goToLink(data.cta)}
                  className={
                    "text-xs text-primary hover:text-primary focus:text-primary focus:outline-none underline"
                  }
                >
                  More details
                </a>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={"w-full flex justify-center items-center h-full flex-col"}>
          <Spin size={"large"} />
          <p>Getting data...</p>
        </div>
      );
    }
  }, [welcomeVideoData]);

  const handlePersonalized = () => {
    console.log("user_subscription_type", user_subscription_type);
    if (!user_subscription_type) return setShowModal(true);

    props.history.push("/profile/me#pap");
  };

  const PersonalizedPlan = useCallback(() => {
    return (
      <div
        onClick={handlePersonalized}
        className={
          "h-18 md:h-23.5 bg-white rounded-lg flex justify-between items-center mr-5 md:p-10 p-4 mb-5 w-1/2 shadow-md cursor-pointer goals_tour_class"
        }
      >
        <div>
          <Icon className={"text-xl md:text-4xl text-primary w-6 md:w-8 h-auto"} type="compass" />
          {/* <img src={transformImageUrl(expertIcon)} alt="" className={'w-6 md:w-8 h-auto'} /> */}
        </div>
        <div className={"flex flex-col text-right"}>
          <p className={"text-sm md:text-lg font-grumpy text-black"}>
            {(user_business_goals_tags || []).length}
          </p>
          <p className={"text-xs md:text-sm"}>My Personalised Action Plan</p>
        </div>
      </div>
    );
  }, [user_business_goals_tags]);

  const SubscriptionPlan = useCallback(() => {
    return (
      <>
        <div
          className={
            "h-18 md:h-23.5 bg-white rounded-lg flex justify-between items-center md:p-10 p-1 pr-1 mb-5 w-1/2 shadow-md cursor-pointer membership_tour_class"
          }
          onClick={handleGoToPage("/user-subscription")}
        >
          <div>
            <Icon
              className={"text-xl md:text-4xl text-event-going w-6 md:w-8 h-auto"}
              type="profile"
            />
            {/* <img src={transformImageUrl(dashboardMessage)} alt="" className={'w-5 md:w-10 h-auto'} /> */}
          </div>
          <div className={"flex flex-col text-right"}>
            <div>
              <p className={"text-sm md:text-lg font-grumpy text-black"}>
                {user_current_plan.subscription_type
                  ? startCase(user_current_plan.subscription_type.toLowerCase())
                  : "Sign Up"}
              </p>
              <p className={"text-xs md:text-sm"}>Membership Inclusions</p>
            </div>
            <button
              type={"submit"}
              onClick={onViewSubscription}
              className={"text-xs md:text-base text-red-600 place-self-end hover:underline"}
            >
              {(() => {
                if (
                  user_current_plan.subscription_type === "SIGN_UP" ||
                  user_current_plan.subscription_type === "FREE"
                ) {
                  return "Upgrade to Community";
                } else if (user_current_plan.subscription_type === "COMMUNITY") {
                  return "Upgrade to Standard";
                } else if (user_current_plan.subscription_type === "STANDARD") {
                  return "Upgrade to Premium";
                } else {
                  return "View Inclusions";
                }
              })()}
            </button>
          </div>
        </div>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_current_plan]);

  const GetAdvice = useCallback(() => {
    return (
      <div
        onClick={handleGoToPage("/get-advice")}
        className={
          "h-18 md:h-23.5 bg-white rounded-lg flex justify-between items-center md:p-10 p-4 mr-5 mb-5 w-1/2 shadow-md cursor-pointer get_advice_tour_class"
        }
      >
        <div>
          <Icon className={"text-xl md:text-4xl text-primary w-6 md:w-8 h-auto"} type="team" />
          {/* <img src={transformImageUrl(expertIcon)} alt="" className={'w-6 md:w-8 h-auto'} /> */}
        </div>
        <div className={"flex flex-col text-right"}>
          <p className={"text-sm md:text-lg font-grumpy text-black"}>
            {!!getAdviceData && getAdviceData.expert_listings_aggregate
              ? getAdviceData.expert_listings_aggregate.aggregate.count
              : 0}
          </p>
          <p className={"text-xs md:text-sm"}>
            Experts available in{" "}
            {!!getAdviceData && getAdviceData.expert_listings_aggregate
              ? getAdviceData.expert_listing_categories_aggregate.aggregate.count
              : 0}{" "}
            categories
          </p>
        </div>
      </div>
    );
  }, [getAdviceData]);

  return (
    <PageContext.Provider
      value={{
        showModal,
        setShowModal,
        showWelcomeModal,
        setShowWelcomeModal,
      }}
    >
      <WelcomeModal />
      <PapAccessModal />
      <div className={"min-h-screen bg-light-gray pb-5"}>
        <HighlightModal />
        <div className={"w-full relative dashboard-header"}>
          <div className={"container mx-auto px-5 md:p-0 relative z-10"}>
            <div className={"flex flex-row md:items-center pt-6 pb-6 user_info_tour_class"}>
              {typeof user_avatar !== "function" ? (
                <div
                  className={
                    "zester-dashboard-flex rounded-full md:h-15 md:w-15 h-12 w-12 mr-5 bg-cover bg-no-repeat bg-center"
                  }
                  style={{ backgroundImage: `url(${transformImageUrl(user_avatar)})` }}
                />
              ) : (
                user_avatar(
                  "zester-dashboard-flex flex items-center justify-center font-grumpy text-event-going text-3xl bg-white rounded-full h-12 w-12 md:h-15 md:w-15 mr-5"
                )
              )}
              <div className={"flex flex-col justify-center"}>
                <div className={"flex flex-row justify-between md:justify-start"}>
                  <div className={"flex flex-col mr-5 md:w-3/4 w-full"}>
                    <div>
                      <p className={"text-sm md:text-lg font-bold md:font-medium"}>{user_name}</p>
                    </div>
                    <div className={"flex flex-row items-center"}>
                      <Progress
                        className={"text-xs w-3/5 mr-4"}
                        strokeColor={"#00AABB"}
                        percent={user_completion || 0}
                      />
                      <a
                        onClick={handleGoToPage("/profile/me")}
                        className={
                          "text-primary text-xs md:text-sm md:pt-1 hover:text-primary focus:text-primary update_profile_tour_class"
                        }
                      >
                        Update Profile
                      </a>
                    </div>
                  </div>
                </div>
                <div>
                  <p className={"text-xs md:text-sm"}>
                    Update your profile to increase networking opportunities and give you
                    recommended content based on your focus areas
                  </p>
                </div>
              </div>
            </div>
            <div className={"flex lg:flex-row flex-col"}>
              <div className={"lg:w-1/2 w-full md:pr-12"}>
                <div className={"flex flex-col"}>
                  {/* <GetAdvice /> */}
                  <div className={"flex flex-col"}>
                    <div className={"flex"}>
                      <PersonalizedPlan />
                      <SubscriptionPlan />
                    </div>
                    <div className={"flex"}>
                      <EventCount />
                      <MessageComponent />
                    </div>
                    <div className={"flex"}>
                      {/* <DigitalLibraryCount /> */}
                      <GetAdvice />
                      <CountdownOrNotificationComponent />
                    </div>
                  </div>
                </div>
              </div>
              <div className={"w-full mb-10 flex flex-1"}>
                <div className={"bg-white rounded-lg w-full overflow-hidden shadow-md"}>
                  <TodayHighlights />
                </div>
              </div>
            </div>
            <div className={"flex md:flex-row flex-col"}>
              <div className={"md:w-1/2 w-full md:mb-0 mb-5 md:pr-12"}>
                <div
                  className={
                    "px-6 pb-6 bg-white rounded-lg w-full shadow-md overflow-y-auto max-h-144 md:h-95 top_hightlights_tour_class"
                  }
                >
                  <div className={"py-4 sticky z-10 top-0 bg-white"}>
                    <p className={"text-lg font-grumpy text-black"}>
                      <span className="top_hightlights_header_tour_class">
                        Top Highlights{" "}
                        <Tooltip
                          placement="topLeft"
                          title={
                            "Curated events, videos, offers and experts just for you. Wouldn't want you to miss them!\n"
                          }
                        >
                          <img
                            src={transformImageUrl(tooltipIcon)}
                            className={"w-4 h-auto inline"}
                            alt={""}
                          />
                        </Tooltip>
                      </span>
                    </p>
                  </div>
                  <TopHighlightsList />
                </div>
              </div>
              <div className={"md:w-1/2 w-full pl-0 md:mb-0 mb-5"}>
                <BusinessBoost />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<FooterMobile/>*/}
    </PageContext.Provider>
  );
};

export default withRouter(withApollo(Dashboard));
