import { useQuery, useSubscription } from "@apollo/react-hooks";
import { Divider, Input, Select, Switch, Tooltip, Typography } from "antd";
import _, { debounce } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import tooltipIcon from "../../assets/icons/tooltip-icon.png";
import withApollo from "../../config/with-apollo";
import { Q_PILLARS, S_BUSINESS_GOALS_TAGS, S_CATEGORIES } from "../../graphql/business-goals";
import { PageContext } from "../../helpers/context";
import { transformImageUrl } from "../../utils/tools";
const Filters = (props) => {
  const { client } = props;
  const { data: pillarList } = useQuery(Q_PILLARS, { client });
  const { data: categoriesList } = useSubscription(S_CATEGORIES, { client });
  const { data: tagsList } = useSubscription(S_BUSINESS_GOALS_TAGS, { client });

  const { setFilter, filter } = useContext(PageContext);
  const [pillars, setPillars] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (pillarList && pillarList.pillars) {
      const pillars = pillarList.pillars;
      setPillars([{ id: "All", name: "All Pillars", color: "4ca6b7" }, ...pillars]);
    }
  }, [pillarList]);

  useEffect(() => {
    if (!(tagsList && tagsList.business_goals_tags && categoriesList && categoriesList.categories))
      return;
    if (filter.pillar === "All") return setCategories(categoriesList.categories);
    const goalsTags = tagsList.business_goals_tags;
    const categories = _.chain(goalsTags)
      .filter((tags) => tags.pillar_id === filter.pillar)
      .uniqBy("categories_id")
      .map((tags) => tags.category)
      .value();
    setCategories(categories);
  }, [filter.pillar, tagsList, categoriesList]);

  const onPillarChange = (id) => () => {
    setFilter((state) => ({ ...state, pillar: id, category: null }));
  };

  const handleRelatedChange = (checked) => {
    // add change filter flag for related tags
    setFilter((oldState) => ({ ...oldState, is_related: checked }));
  };

  const debouncedFilter = useCallback(
    debounce((value) => setFilter((state) => ({ ...state, keyword: value })), 300),
    []
  );

  const onKeywordChange = (event) => {
    debouncedFilter(event.target.value);
  };
  return (
    <>
      <div className="container mt-2 md:mt-6 mx-auto">
        <span className="text-black text-xl md:text-3xl font-grumpy">
          Filter by Pillar or your personalised focus areas
        </span>
      </div>
      <div
        className={
          "md:mb-7.5 my-6 container mx-auto flex flex-row flex-wrap items-start md:items-center"
        }
      >
        {pillars.map((pillar) => (
          <div key={pillar.id}>
            <button
              type={"button"}
              onClick={onPillarChange(pillar.id)}
              className={`h-7 md:h-10 mr-4 rounded px-4 flex items-center focus:outline-none cursor-pointer mb-4 md:mb-0`}
              style={{
                background: `#${filter.pillar === pillar.id ? pillar.color : "FFF"}`,
                color: `#${filter.pillar === pillar.id ? "FFF" : pillar.color}`,
                border: "1px solid #" + pillar.color,
              }}
            >
              {pillar.name}
            </button>
          </div>
        ))}
        <div className="flex flex-row h-7 md:h-10 items-center">
          <Typography className="text-black mx-2">My Focus Areas</Typography>
          <Switch onChange={handleRelatedChange} checked={filter.is_related} />
        </div>
      </div>
      <div className={"md:mb-7.5 my-4 container mx-auto flex flex-row flex-wrap items-center"}>
        <div className={"flex md:flex-row flex-col w-full"}>
          <div className={"flex flex-col mb-2 md:w-1/4 w-full mr-5"}>
            <p className={"font-medium text-black mr-3 mb-1"}>Looking for</p>
            <Select
              value={filter.category}
              size="large"
              onChange={(value) => setFilter((state) => ({ ...state, category: value }))}
              className={"w-full"}
              placeholder={"Select Category"}
            >
              {categories.map((category) => (
                <Select.Option
                  key={category.id}
                  value={category.id}
                  className={"custom-select-option"}
                >
                  {category.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className={"flex flex-col md:w-1/4 w-full mr-5"}>
            <div className={"font-medium text-black mr-3 mb-1"}>Keyword</div>
            <div className={"flex flex-row w-full items-center"}>
              <Input
                //value={filter.keyword}
                placeholder="Search"
                size="large"
                onChange={onKeywordChange}
                className={`w-full border rounded bg-white custom-focus-hover mr-3}`}
              />
              <Tooltip
                placement="topLeft"
                className="hidden md:block ml-4"
                title={
                  "You can enter member names, username, about, and member business goals. We would recommend not to include generic words e.g. for/the/business to avoid getting irrelant results."
                }
              >
                <img src={transformImageUrl(tooltipIcon)} className={"w-6 h-6 inline"} alt={""} />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <Divider />
      </div>
    </>
  );
};

export default withApollo(Filters);
