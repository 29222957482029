import { Icon, Tag } from "antd";
import "antd/es/tag/style/css";
import _ from "lodash";
import moment from "moment";
import React, { useContext } from "react";
import { PageContext } from "../../helpers/context";
import { transformImageUrl } from "../../utils/tools";
// import { isMobile } from 'react-device-detect';

const MessagesInbox = () => {
  const {
    connectionLoading,
    connectionData,
    handleSetState,
    USER,
    handleGetConnection,
    handleUpdateMessageSeen,
    // handleUpdateGrpMessageSeen,
    state,
    handleGetUser,
  } = useContext(PageContext);

  const handleOnClick = (message) => () => {
    handleSetState((oldState) => ({ ...oldState, currentMessage: message }));
    handleUpdateMessageSeen();
  };
  // const handleGrpOnClick = (message) => () => {
  //   handleSetState(oldState => ({ ...oldState, currentMessage: message }));
  //   handleUpdateGrpMessageSeen();
  // };

  return (
    <>
      {!connectionLoading &&
        _.orderBy(
          connectionData.connections,
          [(value) => (value.messages.length ? value.messages[0].created_at : value.created_at)],
          ["desc"]
        ).map((value) => {
          const name = value.type === "group" ? value.name : handleGetConnection(value).name;
          return (
            <div
              onClick={handleOnClick(value)}
              key={value.id}
              className={`flex flex-row w-full p-3 border-b cursor-pointer
              ${(state.currentMessage || {}).id === value.id && "bg-gray-200"}`}
            >
              <div className={"zester-dashboard-flex"}>
                {value.type === "group" ? (
                  <div className="w-10 h-10 bg-primary text-white flex items-center justify-center text-xl font-grumpy zester-dashboard-flex rounded-full overflow-hidden">
                    <Icon type="team" />
                  </div>
                ) : handleGetConnection(value).avatar ? (
                  <div
                    onClick={handleGetUser(handleGetConnection(value).id)}
                    className={
                      "zester-dashboard-flex rounded-full w-10 h-10 bg-cover bg-no-repeat bg-center"
                    }
                    style={{
                      backgroundImage: `url(${transformImageUrl(
                        handleGetConnection(value).avatar
                      )})`,
                    }}
                  />
                ) : (
                  <div
                    onClick={handleGetUser(handleGetConnection(value).id)}
                    className={
                      "w-10 h-10 bg-event-going text-white flex items-center justify-center text-xl font-grumpy zester-dashboard-flex rounded-full overflow-hidden"
                    }
                  >
                    {handleGetConnection(value).name && handleGetConnection(value).name[0]}
                  </div>
                )}
              </div>
              <div className={"w-full"}>
                <div className={"flex flex-row justify-between w-full"}>
                  <div className={"text-black font-medium"}>
                    {name}{" "}
                    <Tag
                      className={`${!value.messages_unseen.aggregate.count && "hidden"}`}
                      color="#e53e3e"
                    >
                      {value.messages_unseen.aggregate.count}
                    </Tag>
                  </div>
                  <div className={"text-xs font-light"}>
                    {moment(
                      value.messages.length ? value.messages[0].created_at : value.created_at
                    ).format("lll")}
                  </div>
                </div>
                <div
                  className={`text-sm truncate-1 font-light
                ${
                  (value.messages[0] || {}).seen || (value.messages[0] || {}).from === USER.id
                    ? "text-black"
                    : "text-primary"
                }`}
                  dangerouslySetInnerHTML={{
                    __html:
                      (value.messages[0] || {}).message === "%business_intro_message%"
                        ? "A business intro message"
                        : (value.messages[0] || {}).message ||
                          `You and ${handleGetConnection(value).name &&
                            handleGetConnection(value).name.split(" ")[0]} are now connected!`,
                  }}
                ></div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default MessagesInbox;
