import { Form } from "antd";
import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import coverLogin from "../../assets/icons/cover-login.png";
import inputEmailIcon from "../../assets/icons/input-icon-email.png";
import inputIconError from "../../assets/icons/input-icon-error.png";
import mumsAndCoLogo from "../../assets/icons/mums_and_co_logo_login.png";
import CustomInput from "../../components/common/input";
import { post } from "../../utils/api-handler";
import { FORGOT_PASSWORD_URL } from "../../utils/url-handler";
import { transformImageUrl } from "../../utils/tools";
const ForgotPassword = (props) => {
  const {
    form: { getFieldDecorator, validateFields, getFieldError },
    history,
  } = props;
  const [error, setError] = useState(null);

  const rules = {
    email: [
      { required: true, message: "Email is required" },
      { type: "email", message: "Please enter a valid email address" },
    ],
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    validateFields(async (errors, values) => {
      try {
        if (!errors) {
          await post(FORGOT_PASSWORD_URL, values);
          history.push({ pathname: "/forgot-password-message", params: values });
        }
      } catch (e) {
        setError(e.response.data.message);
        console.log(e.response);
      }
    });
  };

  return (
    <>
      <div className={"h-full bg-white lg:flex lg:flex-row-reverse"}>
        <div className={"lg:w-3/4 bg-primary justify-between flex flex-col pt-20 lg:pt-0"}>
          <div className={"w-full flex flex-row z-10 lg:inline-flex hidden "}>
            <div className={"md:w-full flex items-center justify-center"}>
              <div className={"text-white text-2xl px-10 xl:px-40 mt-44 flex flex-col font-thin"}>
                <span>
                  <span className={"font-grumpy text-3xl"}>"</span>
                  {"  "}When you have a dream you have to work hard to achieve that dream. Your
                  dreams when you are young can be the force that keeps you going.
                  <span className={"font-grumpy text-3xl"}>"</span>
                </span>
                <span className={"text-right italic"}>- Evonne Goolagong Cawley</span>
              </div>
            </div>
          </div>
          <img alt={""} src={coverLogin} className={"w-full h-auto"} />
        </div>
        <div className={"flex flex-col mt-4 px-10 md:px-40 lg:mt-40 lg:px-28 lg:w-3/4 xl:w-1/3"}>
          <Form onSubmit={handleSubmit}>
            <img alt={""} src={mumsAndCoLogo} className={"m-auto mb-10"} />
            {!!error && (
              <div className={"flex flex-row mb-4"}>
                <img
                  src={transformImageUrl(inputIconError)}
                  alt={""}
                  className={"w-auto h-4 ml-2"}
                />
                <p className={"text-xs text-gray-600 font-light mb-1 ml-3"}>{error}</p>
              </div>
            )}
            {getFieldDecorator("email", { rules: rules.email })(
              <CustomInput
                error={getFieldError("email")}
                placeholder={"Email"}
                className={"shadow"}
                prefixIcon={inputEmailIcon}
                style={{ backgroundSize: "1.25rem" }}
              />
            )}
            <button
              type={"submit"}
              className={
                "bg-primary text-white h-10 w-full text-sm mt-10 font-grumpy font-light rounded-full focus:outline-none"
              }
            >
              Reset Password
            </button>
            <div className={"flex justify-between mt-5"}>
              <p className={"text-sm font-light "}>
                Already a member?{" "}
                <Link to={"/"}>
                  <a className={"text-primary cursor-pointer"}>Login</a>
                </Link>
              </p>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default withRouter(Form.create({ name: "forgot-password" })(ForgotPassword));
