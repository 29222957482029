import React from "react";
import helpCover from "../../assets/images/help-cover.png";
import { transformImageUrl } from "../../utils/tools";

const HeaderCoverBanner1 = (props) => {
  return (
    <div
      className="hidden md:block bg-cover"
      style={{ backgroundImage: `url(${transformImageUrl(helpCover)})` }}
    >
      <div className={"container mx-auto md:py-15 w-full"}>
        <p className={"text-4xl text-white font-medium"}>{props.title || ""}</p>
        <p className={"text-white text-lg font-light"}>{props.subtitle || ""}</p>
      </div>
    </div>
  );
};

export default HeaderCoverBanner1;
