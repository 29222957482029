import { Icon, Modal, Checkbox, Input } from "antd";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { compose } from "react-apollo";
import { withRouter } from "react-router-dom";
import bookingConfirmation from "../../assets/icons/tickets.png";
import withApollo from "../../config/with-apollo";
import { M_EVENT_ATTENDEE_GOING } from "../../graphql/events";
import { M_USER_PLAN } from "../../graphql/user";
import { AppContext, PageContext } from "../../helpers/context";
import { post } from "../../utils/api-handler";
import { AUTH } from "../../utils/common";
import { startCase } from "lodash";
import { transformImageUrl } from "../../utils/tools";

const subscription = {
  COMMUNITY: 1,
  STANDARD: 2,
  PREMIUM: 3,
};

const eventMap = {
  "Meet Ups": "Meet Up",
  "Group Expert Sessions": "Expert Group Session",
  "1:1 Expert Sessions": "Expert 1:1 Session",
  "Be MPowered": "Be MPowered",
};

const propMaps = {
  "Meet Ups": {
    type: "available_meet_ups",
    contactType: "meet_ups",
  },
  "Be MPowered": {
    type: "available_special_events",
    contactType: "special_events",
  },
  "Group Expert Sessions": {
    type: "available_group_expert_sessions",
    contactType: "group_expert_sessions",
  },
  "1:1 Expert Sessions": {
    type: "available_single_expert_sessions",
    contactType: "single_expert_sessions",
  },
};

const EventBookingConfirmation = (props) => {
  const { client } = props;
  const { user_subscription_type, user_mailchimp_id, user_current_plan, user_tokens } = useContext(
    AppContext
  );
  const {
    event,
    showModal,
    setShowModal,
    setShowSuccessModal,
    setQueries,
    setBookNow,
  } = useContext(PageContext);
  let eventData = props.data || event;
  const USER = AUTH();
  const USER_ID = USER.hasura["https://hasura.io/jwt/claims"]["x-hasura-user-id"];
  const [submitting, setSubmitting] = useState(false);
  const [isDisabled, setDisabled] = useState(true);
  const [promoCode, setPromoCode] = useState("");
  const [promoError, setPromoError] = useState("");
  const [discountPercent, setDiscountPercent] = useState(0);
  const [modalType, setModalType] = useState("");
  const [payToken, setPayToken] = useState(false);

  useEffect(() => {
    if (!showModal || !eventData) return setModalType("");

    if (eventData.name === "Be MPowered 2022") {
      return setModalType("promo");
    }

    // Check if match
    if (
      (!eventData.subscription_type && !user_subscription_type) ||
      eventData.subscription_type === "ALWAYS_PAY_AS_YOU_GO" ||
      payToken ||
      eventData.cost === 0
    ) {
      return setModalType("token");
    }

    // TODO: Check access level matching hierarchy
    if (
      !eventData.subscription_type ||
      subscription[eventData.subscription_type] <=
        subscription[(user_subscription_type || "").toUpperCase()]
    ) {
      return setModalType("allowed");
    } else {
      return setModalType("free");
    }
  }, [eventData, showModal, user_subscription_type, payToken]);

  const handlePromo = async () => {
    try {
      setPromoError("");
      const result = await post("/checkCode", {
        elementType: process.env.REACT_APP_EVENT_KEY,
        elementId: eventData.id,
        discountCode: promoCode,
      });
      // setPromoError(`${result.data.percent}% discount code is applied`);
      setDiscountPercent(result.data.percent);
      handleGoing(eventData.id, true)();
    } catch (e) {
      setPromoError("Promo code is invalid");
    } finally {
    }
  };

  const handleApply = () => async () => {
    try {
      setPromoError("");
      const result = await post("/checkCode", {
        elementType: process.env.REACT_APP_EVENT_KEY,
        elementId: eventData.id,
        discountCode: promoCode,
      });
      setPromoError(`${result.data.percent}% discount code is applied`);
      setDiscountPercent(result.data.percent);
    } catch (e) {
      setPromoError(
        e.response && e.response.data && e.response.data.message
          ? e.response.data.message
          : "Error on discount code"
      );
    } finally {
    }
  };

  const updateInclusions = async (type, contactType) => {
    const remaining = user_current_plan[type] - 1;
    await client.mutate({
      mutation: M_USER_PLAN,
      variables: {
        id: user_current_plan.id,
        _set: {
          [type]: remaining,
        },
      },
    });

    // Meet Ups
    const {
      subscriptionPropertyBySubscriptionProperty: subscription_properties,
    } = user_current_plan;
    if (subscription_properties[contactType] !== 99999) {
      await post("/contact-field", {
        mailchimp_id: user_mailchimp_id,
        field: contactType,
        value: `${remaining}/${subscription_properties[contactType]}`,
      });
    }
  };
  const handleGoing = (id, isToken = false) => async () => {
    // return props.history.push('/top-up', { amount: eventData.cost, lackAmount: 50 });
    if (moment().isSameOrBefore(new moment(`${eventData.date_to} ${eventData.time_to}`)))
      try {
        setSubmitting(true);
        let date;

        let time = `${new moment(eventData.time_from, "hh:mm Z").format("LT")} - ${new moment(
          eventData.time_to,
          "hh:mm Z"
        ).format("LT")}`;

        if (moment(eventData.date_from).isSame(eventData.date_to)) {
          date = moment(eventData.date_to).format("ll");
        } else {
          date = `${moment(eventData.date_from).format("ll")} - ${moment(eventData.date_to).format(
            "ll"
          )}`;
        }

        let dateFrom = moment(eventData.date_from).format("YYYYMMDD");
        let timeFrom = new moment(`${eventData.time_from}`, "HHmmss Z").format("HHmmss");
        let dateTo = moment(eventData.date_to).format("YYYYMMDD");
        let timeTo = new moment(`${eventData.time_to}`, "HHmmss Z").format("HHmmss");

        // Check if via token or not
        await post("/transact", {
          type: process.env.REACT_APP_EVENT_KEY,
          transactionId: eventData.id,
          tokens: eventData.cost,
          name: eventData.name,
          userId: USER_ID,
          date,
          time,
          date_from: `${dateFrom}T${timeFrom}`,
          date_to: `${dateTo}T${timeTo}`,
          timezone: moment().format("Z"),
          discountCode: promoCode,
          is_token: isToken,
        });

        // Update plan available meet ups
        if (!isToken) {
          const eventType = (eventData.event_type || {}).type;
          const { type, contactType } = propMaps[eventType] || {};
          if (type && contactType) {
            await updateInclusions(type, contactType);
          }
        }

        await client.mutate({
          mutation: M_EVENT_ATTENDEE_GOING,
          variables: {
            objects: [
              {
                user_id: USER_ID,
                event_id: id,
                status: 1,
              },
            ],
          },
        });

        if (setQueries) {
          setQueries({ modal: "event-booking-confirmation" });
        }
        setShowModal(!showModal);
        setShowSuccessModal((state) => !state);
        setDisabled(true);
        setPromoCode("");
        setPromoError("");
      } catch (e) {
        console.log(e);

        if (e.response.data.code === 1000) {
          localStorage.setItem("@return-url", `/events/${eventData.id}?modal=event-registration`);
          return props.history.push("/upgrade-account", { amount: eventData.cost });
        }

        if (e.response.data.code === 1001) {
          localStorage.setItem("@return-url", `/events/${eventData.id}?modal=event-registration`);
          return props.history.push("/top-up", {
            amount: eventData.cost,
            lackAmount: e.response.data.amount || eventData.cost - user_tokens,
          });
        }

        // console.log(e.response);
      } finally {
        setSubmitting(false);
        setPromoError("");
      }
  };
  const handleSelect = (e) => {
    setDisabled(!e.target.checked);
  };

  const onUpgradeForMore = async () => {
    props.history.push("/user-subscription");
  };

  const onUpgradeClick = async () => {
    // add redirect url to storage
    if (user_current_plan.subscription_type) {
      const response = await post(`/create-customer-session`, {
        redirect: `${window.location.origin}/events/${eventData.id}?modal=event-registration`,
        userId: USER.id,
      });

      if (response.data.sessionURL) {
        return window.open(response.data.sessionURL, "_self");
      }
    }

    localStorage.setItem("@return-url", `/events/${eventData.id}?modal=event-registration`);
    props.history.push("/upgrade-account", {
      selected: eventData.subscription_type,
    });
  };

  const getEventProperties = (eventType) => {
    const {
      available_special_events,
      available_group_expert_sessions,
      available_single_expert_sessions,
      available_meet_ups,
      subscriptionPropertyBySubscriptionProperty: subscription_properties,
    } = user_current_plan;
    let hasAccess = false,
      spent = 0,
      noAvailable = false,
      propertyType = 0;
    switch (eventType) {
      case "Meet Ups":
        hasAccess = subscription_properties.meet_ups > 0;
        propertyType =
          subscription_properties.meet_ups === 99999 ? "unlimited" : available_meet_ups;
        noAvailable = available_meet_ups === 0;
        spent = subscription_properties.meet_ups - available_meet_ups;
        break;
      case "Group Expert Sessions":
        hasAccess = subscription_properties.available_group_expert_sessions > 0;
        propertyType =
          subscription_properties.group_expert_sessions === 99999
            ? "unlimited"
            : available_group_expert_sessions;
        noAvailable = available_group_expert_sessions === 0;
        spent = subscription_properties.group_expert_sessions - available_group_expert_sessions;
        break;
      case "1:1 Expert Sessions":
        hasAccess = subscription_properties.available_single_expert_sessions > 0;
        propertyType =
          subscription_properties.single_expert_sessions === 99999
            ? "unlimited"
            : available_single_expert_sessions;
        noAvailable = available_single_expert_sessions === 0;
        spent = subscription_properties.single_expert_sessions - available_single_expert_sessions;
        break;
      case "Be MPowered":
        hasAccess = subscription_properties.available_special_events > 0;
        propertyType = available_special_events;
        noAvailable = available_special_events === 0;
        spent = subscription_properties.special_events - available_special_events;
        break;
      case "Podcast Advertising":
        hasAccess = subscription_properties.podcast_member_feature;
        propertyType = subscription_properties.podcast_member_feature;
        noAvailable = !subscription_properties.podcast_member_feature;
        spent = 0;
        break;
      default:
    }

    return [hasAccess, propertyType, noAvailable, spent];
  };

  const ModalAllowed = useCallback(() => {
    const eventType = (eventData.event_type || {}).type;
    const [hasAccess, propertyType, noAvailable, spent] = getEventProperties(eventType);

    let message = `This event is included as part of your Mums & Co ${user_subscription_type} Membership. Please confirm you want to register.`;

    if (user_current_plan.subscription_type === "SIGN_UP" && spent === 0) {
      message = `As thanks for signing up with Mums & Co, we are offering your first Member Meet-up session for free. Please confirm you want to register`;
    }

    if (noAvailable) {
      message = hasAccess
        ? `You’re all out! Look at you go! You’ve used up all of your ${eventMap[eventType]} inclusions, simply upgrade your membership plan or redeem using pay as you go tokens.`
        : `You have no ${eventMap[eventType]} inclusions available. Simply upgrade your membership plan, or redeem using pay as you go tokens.`;
    }

    let title = `Great choice!`;
    if (noAvailable) {
      title = hasAccess ? `Great choice!` : `Upgrade or redeem with tokens to access this event`;
    }

    return (
      <div className={"flex flex-col "}>
        <img
          src={transformImageUrl(bookingConfirmation)}
          alt={""}
          className={"self-center w-24 mb-6"}
        />
        {hasAccess ? (
          <p className={"self-center text-3xl text-black"}>{title}</p>
        ) : (
          <p className={"self-center text-xl text-black"}>{title}</p>
        )}
        <p className={"self-center text-lg mb-5 text-center"}>{message}</p>
        <div className={"flex flex-row w-full justify-center"}>
          <button
            disabled={submitting}
            onClick={() => {
              if (setQueries) {
                setQueries({ modal: undefined });
              }
              setShowModal(!showModal);
              setBookNow(false);
              setDisabled(true);
              setPromoCode("");
              setPromoError("");
            }}
            className={
              "self-center bg-light-gray-header text-black h-10 text-lg w-1/3 font-light mr-5  rounded active:bg-primary focus:outline-none "
            }
          >
            Cancel
          </button>
          {noAvailable ? (
            <button
              disabled={submitting}
              onClick={() => setPayToken(true)}
              className={`self-center text-white h-10 text-md md:w-1/3 w-full font-light  rounded active:bg-primary focus:outline-none  ${
                submitting ? "bg-disabled" : "bg-primary"
              }`}
            >
              {submitting && <Icon type={"loading"} className={"mr-2"} />}
              Redeem with Tokens
            </button>
          ) : (
            <button
              type={"submit"}
              disabled={submitting}
              onClick={handleGoing(eventData.id)}
              className={`self-center text-white h-10 text-lg w-1/3 font-light  rounded active:bg-primary focus:outline-none  ${
                submitting ? "bg-disabled" : "bg-primary"
              }`}
            >
              {submitting && <Icon type={"loading"} className={"mr-2"} />}
              Confirm
            </button>
          )}
        </div>
        {eventType !== "Podcast Advertising" ? (
          <div className="text-center mt-4">
            <span className={"self-center text-xs"}>
              You have registered for <strong>{spent}</strong> {eventMap[eventType]}
              {propertyType !== "unlimited" && (
                <span>
                  {" "}
                  and have <strong>{propertyType} </strong>Remaining.
                </span>
              )}
            </span>
            <br />
            {user_subscription_type !== "Premium" && (
              <span
                onClick={onUpgradeForMore}
                className={"text-primary hover:text-primary self-center text-xs cursor-pointer"}
              >
                {" "}
                Upgrade to gain access to more.
              </span>
            )}
          </div>
        ) : (
          <div className="text-center mt-4">
            {!hasAccess && user_subscription_type !== "Premium" && (
              <span
                onClick={onUpgradeForMore}
                className={"text-primary hover:text-primary self-center text-xs cursor-pointer"}
              >
                {" "}
                Upgrade to gain access.
              </span>
            )}
          </div>
        )}
      </div>
    );
  }, [eventData, user_current_plan, user_subscription_type, showModal, submitting, setBookNow]);

  const ModalFree = useCallback(() => {
    const eventType = (eventData.event_type || {}).type;
    const [hasAccess, propertyType, noAvailable, spent] = getEventProperties(eventType);

    let message = "";
    if (user_current_plan.subscription_type === "SIGN_UP") {
      message = `As thanks for signing up with Mums & Co, we are offering your first Member Meet-up session for free. Please confirm you want to register`;
    }

    if (eventData.cost === 0) {
      message = `This event is free to redeem with your ${
        user_current_plan.subscription_type === "FREE" ? "Sign Up" : user_subscription_type
      } membership.`;
    } else if (noAvailable) {
      message = hasAccess
        ? `You’re all out! Look at you go! You’ve used up all of your ${eventMap[eventType]} inclusions so simply upgrade your membership plan or redeem using pay as you go tokens.`
        : `You have no ${eventMap[eventType]} inclusions available. Simply upgrade your membership plan, or redeem using pay as you go tokens.`;
    }

    let title = `Great choice!`;
    if (eventData.cost === 0) {
      title = `Great choice!`;
    } else if (noAvailable) {
      title = hasAccess ? `Great choice!` : `Upgrade or redeem with tokens to access this event`;
    }

    return (
      <div className={"flex flex-col "}>
        <img
          src={transformImageUrl(bookingConfirmation)}
          alt={""}
          className={"self-center w-24 mb-6"}
        />
        {hasAccess ? (
          <p className={"self-center text-3xl text-black"}>{title}</p>
        ) : (
          <p className={"self-center text-xl text-black"}>{title}</p>
        )}
        <p className={"self-center text-lg mb-5 text-center"}>{message}</p>
        <div className={"flex flex-row w-full justify-center"}>
          <button
            disabled={submitting}
            onClick={() => {
              if (setQueries) {
                setQueries({ modal: undefined });
              }
              setShowModal(!showModal);
              setBookNow(false);
              setDisabled(true);
              setPromoCode("");
              setPromoError("");
            }}
            className={
              "self-center bg-light-gray-header text-black h-10 text-lg w-1/3 font-light mr-5  rounded active:bg-primary focus:outline-none "
            }
          >
            Cancel
          </button>
          {noAvailable ? (
            <button
              disabled={submitting}
              onClick={
                eventData.cost === 0 ? handleGoing(eventData.id, true) : () => setPayToken(true)
              }
              className={`self-center text-white h-10 text-md md:w-1/3 w-full font-light ${
                eventData.cost === 0 ? "text-lg" : ""
              } rounded active:bg-primary focus:outline-none  ${
                submitting ? "bg-disabled" : "bg-primary"
              }`}
            >
              {submitting && <Icon type={"loading"} className={`mr-2`} />}
              {eventData.cost === 0 ? "Count me in" : "Redeem with Tokens"}
            </button>
          ) : (
            <button
              type={"submit"}
              disabled={submitting}
              onClick={handleGoing(eventData.id, false)}
              className={`self-center text-white h-10 text-lg w-1/3 font-light  rounded active:bg-primary focus:outline-none  ${
                submitting ? "bg-disabled" : "bg-primary"
              }`}
            >
              {submitting && <Icon type={"loading"} className={"mr-2"} />}
              Confirm
            </button>
          )}
        </div>
        {eventType !== "Podcast Advertising" ? (
          <div className="text-center mt-4">
            <span className={"self-center text-xs"}>
              You have registered for <strong>{spent}</strong> {eventMap[eventType]}
              {propertyType !== "unlimited" && (
                <span>
                  {" "}
                  and have <strong>{propertyType} </strong>Remaining.
                </span>
              )}
            </span>
            <br />
            {user_subscription_type !== "Premium" && (
              <span
                onClick={onUpgradeForMore}
                className={"text-primary hover:text-primary self-center text-xs cursor-pointer"}
              >
                {" "}
                Upgrade to gain access to more.
              </span>
            )}
          </div>
        ) : (
          <div className="text-center mt-4">
            {!hasAccess && user_subscription_type !== "Premium" && (
              <span
                onClick={onUpgradeForMore}
                className={"text-primary hover:text-primary self-center text-xs cursor-pointer"}
              >
                {" "}
                Upgrade to gain access.
              </span>
            )}
          </div>
        )}
      </div>
    );
  }, [eventData, user_current_plan, user_subscription_type, showModal, submitting, setBookNow]);

  const ModalUpgrade = useCallback(() => {
    const subscription = startCase(eventData.subscription_type.toLowerCase());
    let message = `You can’t access this yet! Simply upgrade your membership plan, or redeem using pay as you go tokens.`;
    const isSingleSessions = (eventData.event_type || {}).type === "1:1 Expert Sessions";
    if (isSingleSessions) {
      message =
        "Can’t access yet! 1:1 Expert Sessions are included with Premium Memberships. If you would like to access a 1:1 Session simply upgrade your membership plan or redeem using pay as you go tokens.";
    }

    return (
      <div className={"flex flex-col "}>
        <img
          src={transformImageUrl(bookingConfirmation)}
          alt={""}
          className={"self-center w-24 mb-6"}
        />
        <p className={"self-center text-3xl text-black"}> Can't Access!</p>
        <p className={"self-center text-lg mb-5 text-center"}>{message}</p>
        <div className={"flex flex-row w-full justify-center"}>
          <button
            disabled={submitting}
            onClick={() => {
              if (setQueries) {
                setQueries({ modal: undefined });
              }
              setShowModal(!showModal);
              setDisabled(true);
              setPromoCode("");
              setPromoError("");
              onUpgradeClick();
            }}
            className={
              "self-center bg-event-going text-white h-10 w-full text-md md:w-2/3 font-light mr-5  rounded active:bg-primary focus:outline-none "
            }
          >
            {isSingleSessions ? "Upgrade to Premium" : `Upgrade to ${subscription}`}
          </button>
          <button
            disabled={submitting}
            onClick={() => setPayToken(true)}
            className={`self-center text-white h-10 text-md md:w-2/3 w-full font-light  rounded active:bg-primary focus:outline-none  ${
              submitting ? "bg-disabled" : "bg-primary"
            }`}
          >
            {submitting && <Icon type={"loading"} className={"mr-2"} />}
            Redeem with Tokens
          </button>
        </div>
      </div>
    );
  }, [submitting, showModal, eventData]);

  const ModalToken = useCallback(() => {
    return (
      <div className={"flex flex-col "}>
        <img
          src={transformImageUrl(bookingConfirmation)}
          alt={""}
          className={"self-center w-24 mb-6"}
        />
        <p className={"self-center text-3xl text-black"}> Great choice!</p>
        {eventData.cost === 0 ? (
          <p className={"self-center text-lg mb-5 text-center"}>
            This event is free to redeem with your{" "}
            {user_current_plan.subscription_type === "FREE" ? "Sign Up" : user_subscription_type}{" "}
            membership.{" "}
            {Math.round((eventData.cost * (100 - (discountPercent ? discountPercent : 0))) / 100)}{" "}
            tokens will be deducted to reserve your spot.
          </p>
        ) : (
          <p className={"self-center text-lg mb-5 text-center"}>
            {Math.round((eventData.cost * (100 - (discountPercent ? discountPercent : 0))) / 100)}{" "}
            tokens will be deducted to reserve your spot. <br />
            Don’t worry if you don’t have enough right now. You’ll be redirected to the Top Up page
            after pressing the Confirm button.
          </p>
        )}
        <div className={"flex flex-row w-full justify-center mb-2"}>
          <Checkbox checked={!isDisabled} onChange={handleSelect}>
            Apply Discount code
          </Checkbox>
        </div>
        <div className={"flex flex-row w-full justify-center mb-2"}>
          <Input
            placeholder={"Discount code"}
            disabled={isDisabled}
            className={"md:w-1/3 w-full border rounded bg-white custom-focus-hover"}
            value={promoCode}
            autoFocus
            onChange={(e) => {
              setPromoCode(e.target.value);
            }}
          />
          <button
            type={"submit"}
            disabled={isDisabled}
            onClick={handleApply()}
            className={`self-center text-white h-8 text-lg w-1/5 font-light  rounded active:bg-primary focus:outline-none bg-primary ${
              isDisabled ? "bg-disabled" : "bg-primary"
            }`}
          >
            Apply
          </button>
        </div>
        <div className={"flex flex-row w-full justify-center text-red-500"}>{promoError}</div>
        <div className={"flex flex-row w-full justify-center"}>
          <button
            disabled={submitting}
            onClick={() => {
              if (setQueries) {
                setQueries({ modal: undefined });
              }
              setShowModal(!showModal);
              setBookNow(false);
              setDisabled(true);
              setPromoCode("");
              setPromoError("");
              setPayToken(false);
            }}
            className={
              "self-center bg-light-gray-header text-black h-10 text-lg w-1/3 font-light mr-5  rounded active:bg-primary focus:outline-none "
            }
          >
            Cancel
          </button>
          <button
            type={"submit"}
            disabled={submitting}
            onClick={handleGoing(eventData.id, true)}
            className={`self-center text-white h-10 text-lg w-1/3 font-light  rounded active:bg-primary focus:outline-none  ${
              submitting ? "bg-disabled" : "bg-primary"
            }`}
          >
            {submitting && <Icon type={"loading"} className={"mr-2"} />}
            Confirm
          </button>
        </div>
        <div className={"mt-2 flex self-center"}>
          <p>Note : Token refunds are at Mums & Co’s sole discretion.</p>
        </div>
      </div>
    );
  }, [
    discountPercent,
    eventData,
    submitting,
    showModal,
    isDisabled,
    promoCode,
    promoError,
    setBookNow,
  ]);

  const ModalPromo = useCallback(() => {
    return (
      <div className={"flex flex-col "}>
        <img
          src={transformImageUrl(bookingConfirmation)}
          alt={""}
          className={"self-center w-24 mb-6"}
        />
        <p className={"self-center text-3xl text-black"}> Great choice!</p>
        <p className={"self-center text-lg mb-5 text-center"}>
          This is where Be MPowered ticket holders access the live conference stream on the 13
          October 2022.
        </p>
        <p className={"self-center text-lg mb-2 text-center"}>
          {" "}
          You can buy a Be MPowered Ticket here (<strong>Standard & Premium members</strong> can
          claim yours free*){" "}
          <a
            className="text-primary hover:text-primary"
            rel="noopener noreferrer"
            href="https://www.mumsandco.com.au/be-mpowered-event-2022"
            target="_blank"
          >
            https://www.mumsandco.com.au/be-mpowered-event-2022
          </a>
        </p>
        <p className={"self-center text-sm mb-4 text-center"}>
          *Use the promo code emailed to you when you first signed up to your membership.
        </p>
        <div className={"flex flex-row w-full justify-center mb-2"}>
          <Input
            placeholder={"Enter code"}
            disabled={false}
            className={"md:w-1/2 w-full border rounded bg-white custom-focus-hover"}
            value={promoCode}
            size="large"
            autoFocus
            onChange={(e) => {
              setPromoCode(e.target.value);
            }}
          />
        </div>
        <div className={"flex flex-row w-full justify-center text-red-500"}>{promoError}</div>
        <div className={"flex flex-row w-full justify-center mt-4"}>
          <button
            disabled={submitting}
            onClick={() => {
              if (setQueries) {
                setQueries({ modal: undefined });
              }
              setShowModal(!showModal);
              setBookNow(false);
              setDisabled(true);
              setPromoCode("");
              setPromoError("");
              setPayToken(false);
            }}
            className={
              "self-center bg-light-gray-header text-black h-10 text-lg w-1/3 font-light mr-5  rounded active:bg-primary focus:outline-none "
            }
          >
            Cancel
          </button>
          <button
            type={"submit"}
            disabled={submitting || !promoCode}
            onClick={handlePromo}
            className={`self-center text-white h-10 text-lg w-1/3 font-light  rounded active:bg-primary focus:outline-none  ${
              submitting || !promoCode ? "bg-disabled" : "bg-primary"
            }`}
          >
            {submitting && <Icon type={"loading"} className={"mr-2"} />}
            Confirm
          </button>
        </div>
      </div>
    );
  }, [
    discountPercent,
    eventData,
    submitting,
    showModal,
    isDisabled,
    promoCode,
    promoError,
    setBookNow,
  ]);

  const modalProps = useMemo(() => {
    if (modalType !== "upgrade") return {};
    return {
      closable: true,
      maskClosable: true,
      onCancel: () => {
        if (setQueries) {
          setQueries({ modal: undefined });
        }
        setBookNow(false);
        setShowModal(!showModal);
      },
    };
  }, [modalType, setQueries, showModal, setBookNow]);

  return (
    <Modal
      visible={showModal}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      footer={null}
      closable={false}
      {...modalProps}
    >
      {modalType === "allowed" && <ModalAllowed />}
      {modalType === "upgrade" && <ModalUpgrade />}
      {modalType === "token" && <ModalToken />}
      {modalType === "promo" && <ModalPromo />}
      {modalType === "free" && <ModalFree />}
    </Modal>
  );
};

export default compose(withApollo, withRouter)(EventBookingConfirmation);
