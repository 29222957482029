import React, { useContext, useEffect, useState } from "react";
import { PageContext } from "../../helpers/context";
import orderBy from "lodash/orderBy";
import { SiHandshake } from "react-icons/si";
import { BsChatSquareTextFill } from "react-icons/bs";
import { Tooltip } from "antd";
import copyIcon from "../../assets/icons/copy.png";
import copy from "clipboard-copy";
import { transformImageUrl } from "../../utils/tools";

const MemberCard = (props) => {
  const { handleSelectUser, handleSelectUserMessage } = useContext(PageContext);
  const [showAllTags, setShowAllTags] = useState(false);
  const [userBusinessTags, setUserBusinessTags] = useState([]);

  const profileURL = "https://app.mumsandco.com.au/member-directory/" + props.id;
  const [textToCopy, setTextToCopy] = useState(profileURL);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    const tags = [];
    (props.user_business_goals_tags || []).forEach((element) => {
      tags.push({
        id: element.id,
        name: element.business_goals_tag.name,
        color: element.pillar.color,
      });
    });
    setUserBusinessTags(orderBy(tags, "name", "asc"));
  }, [props.user_business_goals_tags]);

  const handleCopyClick = async () => {
    try {
      await copy(textToCopy);
      setIsCopied(true);
    } catch (error) {
      console.error("Failed to copy: ", error);
    }
  };

  let ctaMessage = props.ctaMessage || "Send Message";
  let ctaAction =
    props.ctaAction ||
    function() {
      handleSelectUserMessage(props);
    };

  const sendMessage = (type) => () => {
    handleSelectUserMessage(props, type);
  };

  const greyscaleStyle = props.greyscale ? { filter: `grayscale(100%)` } : {};
  return (
    <div className={"relative"}>
      {props.featured && <div className="ribbon mt-1">Featured</div>}
      <div
        className={`border rounded p-2 w-full h-full shadow-md ${props.greyscale && "bg-gray-200"}`}
        style={{ minHeight: 100 }}
      >
        <div className="flex flex-row w-full">
          <div
            className={`flex flex-col items-start w-3/12 ${props.featured &&
              "mt-6"} justify-center`}
          >
            <div className={"mb-1 flex items-center justify-center w-full"} style={greyscaleStyle}>
              {props.avatar ? (
                <div
                  className={"rounded-full w-12 h-12 bg-cover bg-no-repeat bg-center"}
                  style={{ backgroundImage: `url(${transformImageUrl(props.avatar)})` }}
                />
              ) : (
                <div
                  className={
                    "flex items-center justify-center font-grumpy text-white text-xl bg-event-going rounded-full h-14 w-14"
                  }
                >
                  {props.name[0].toUpperCase()}
                </div>
              )}
            </div>
            {!props.greyscale && (
              <div className={"flex justify-center items-center w-full"}>
                <button
                  onClick={() => handleSelectUser(props)}
                  className={"text-primary text-sm underline focus:outline-none"}
                >
                  View Profile
                </button>
              </div>
            )}
          </div>
          <div
            className={`flex flex-row items-start w-9/12 ${props.featured &&
              "mt-6"} justify-center`}
          >
            <div className={"flex flex-col w-full"}>
              <div className={"w-full mb-2 flex flex-col justify-between "}>
                <div className={"flex flex-row justify-between items-start mb-2"}>
                  <div className={"w-full"}>
                    <span className={"text-base text-black font-bold"}>{props.name}</span>
                  </div>
                  <div className={"w-full flex flex-row justify-end"} style={{ filter: "none" }}>
                    <Tooltip title={isCopied ? "Copied!" : "Copy Profile Link"}>
                      <img
                        src={copyIcon}
                        onClick={handleCopyClick}
                        className={`cursor-pointer text-primary text-xl mr-4 w-5 h-auto rounded hover:scale-2`}
                        alt={""}
                      />
                    </Tooltip>
                    <Tooltip title="Introduce to">
                      <SiHandshake
                        onClick={sendMessage(true)}
                        className={`cursor-pointer text-primary text-xl mr-4 rounded hover:scale-2`}
                        style={{ color: "#DA70D6" }}
                      />
                    </Tooltip>
                    <Tooltip title="Send message">
                      <BsChatSquareTextFill
                        onClick={sendMessage(false)}
                        className={`cursor-pointer text-primary text-xl mr-4 rounded hover:scale-2`}
                      />
                    </Tooltip>
                  </div>
                </div>
                <div className={"flex md:flex-row flex-col items-start"}>
                  {props.businesses.map((value) => (
                    <div key={value.id}>
                      {value.name && (
                        <p
                          className={`px-2 rounded ${
                            props.greyscale ? "bg-gray-400" : "bg-gray-300"
                          } text-black text-sm mb-1 mr-2`}
                        >
                          {value.name}
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className={"text-black"}>
                {props.greyscale
                  ? (props.businesses[0] || {}).about
                  : (props.businesses[0] || {}).about
                  ? ((props.businesses[0] || {}).about || "").length >= 130
                    ? `${((props.businesses[0] || {}).about || "").substr(0, 130)}...`
                    : (props.businesses[0] || {}).about
                  : "Business description not provided"}
              </div>
            </div>
          </div>
        </div>
        {!!userBusinessTags.length && (
          <div className="flex flex-col mt-3 mb-1 mx-5">
            <span className={"text-base text-black font-bold mb-1"}>Focus Areas in common</span>
            <ul className="flex flex-row flex-wrap">
              {userBusinessTags.slice(0, showAllTags ? userBusinessTags.length : 3).map((item) => (
                <li
                  className="flex px-2 rounded flex-row mr-2 mt-1.5"
                  key={item.id}
                  style={{
                    outlineColor: "#" + item.color,
                    boxShadow: "#" + item.color + " 0 0px 8px",
                  }}
                >
                  {item.name}
                </li>
              ))}
              {userBusinessTags.length > 3 && (
                <span
                  className={"flex flex-row text-xs text-black font-bold mt-2 cursor-pointer"}
                  onClick={() => setShowAllTags((state) => !state)}
                >
                  {!showAllTags ? `+ ${userBusinessTags.length} More` : "Show Less"}
                </span>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default MemberCard;
