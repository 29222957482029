import { Modal } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { compose } from "react-apollo";
import { withRouter } from "react-router-dom";
import bigCheckIcon from "../../assets/icons/big-check-icon.png";
import { AppContext } from "../../helpers/context";
import { transformImageUrl } from "../../utils/tools";
const UpgradeAccountModal = (props) => {
  const { user_subscription_type, user_current_plan } = useContext(AppContext);

  const [showModal, setShowModal] = useState(false);

  const onProceed = () => {
    props.history.push({
      pathname: `/payment`,
      search: "?free=true&type=community&billed=yearly",
    });
  };

  useEffect(() => {
    const date = moment();
    const availUntilDate = date.isBefore(moment("2022-11-28"));

    if (
      availUntilDate &&
      (user_subscription_type.toLowerCase() === "free" ||
        user_subscription_type.toLowerCase() === "sign_up" ||
        user_subscription_type.toLowerCase() == "sign up") &&
      user_current_plan.plan_type === "ACCELERATE"
    ) {
      setShowModal(true);
    }
  }, [user_subscription_type, user_current_plan, props.location.pathname]);

  const onNavigate = () => {
    window.open("https://www.mumsandco.com.au/membership-terms-and-conditions", "_blank");
  };

  return (
    <Modal
      visible={showModal}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      footer={null}
      onCancel={() => setShowModal(false)}
      closable={true}
    >
      <div className={"flex flex-col"}>
        <img src={transformImageUrl(bigCheckIcon)} alt={""} className={"self-center w-24 mb-6"} />
        <p className={"self-center text-center text-3xl text-black mb-3"}>
          Membership Upgrade Offer!
        </p>
        <p className={"self-center text-left text-lg mb-5"}>
          The new Mums & Co Membership model is here. As a previous Accelerate Member, you have been
          transitioned to a Sign Up membership. To say thanks for being part of the Mums & Co
          journey so far, we’re offering you a free upgrade to a Community level subscription for
          the next 12 months. A Community annual subscription is worth $49. This offer is available
          until 27 November 2022.
        </p>
        <p className={"self-center text-left text-lg mb-5"}>
          To claim this offer hit the ‘Proceed with Upgrade’ button below. You’ll be asked to submit
          your payment details & billing address, but you will not be charged today. Your chosen
          credit card will be charged the applicable subscription fee when your subscription
          auto-renews at the end of the initial 12-month period, unless you cancel your subscription
          prior to the renewal date. To view the full Mums & Co{" "}
          <span onClick={onNavigate} className="cursor-pointer text-primary">
            Membership Terms and Conditions
          </span>
          , please click here.
        </p>
        <button
          onClick={onProceed}
          className={
            "self-center bg-primary text-white h-10 text-lg mt-4 px-4 font-light  rounded active:bg-primary focus:outline-none "
          }
        >
          Proceed with Upgrade
        </button>
      </div>
    </Modal>
  );
};

export default compose(withRouter)(UpgradeAccountModal);
