import { Modal } from "antd";
import React, { useContext } from "react";
import bigCheckIcon from "../../assets/icons/big-check-icon.png";
import { PageContext } from "../../helpers/context";
import { transformImageUrl } from "../../utils/tools";
const HelpRequestSubmittedModal = () => {
  const { showModal, setShowModal } = useContext(PageContext);

  return (
    <Modal
      visible={showModal}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      footer={null}
      closable={false}
    >
      <div className={"flex flex-col"}>
        <img src={transformImageUrl(bigCheckIcon)} alt={""} className={"self-center w-24 mb-6"} />
        <p className={"self-center text-3xl text-black"}>Request Submitted</p>
        <p className={"self-center text-lg mb-5"}>
          We'll respond to your query via email at the earliest
        </p>
        <button
          type={"submit"}
          onClick={() => {
            setShowModal(!showModal);
          }}
          className={
            "self-center bg-primary text-white h-10 text-lg px-4 font-light  rounded active:bg-primary focus:outline-none "
          }
        >
          Back to home
        </button>
      </div>
    </Modal>
  );
};

export default HelpRequestSubmittedModal;
