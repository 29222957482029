import { Form, Icon, Input, Switch } from "antd";
import "antd/es/icon/style/css";
import "antd/es/input/style/css";
import "antd/es/notification/style/css";
import "antd/es/select/style/css";
import { startCase } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { compose } from "react-apollo";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router-dom";
import profilePhoneIcon from "../../../assets/icons/profile-phone.png";
import profileMailIcon from "../../../assets/icons/profile-pmail.png";
import profileLocationIcon from "../../../assets/icons/profile-postal.png";
import withApollo from "../../../config/with-apollo";
import { AppContext, PageContext } from "../../../helpers/context";

import copyIcon from "../../../assets/icons/copy.png";
import copy from "clipboard-copy";
import { AUTH } from "../../../utils/common";
import { transformImageUrl } from "../../../utils/tools";

const ProfileCard = (props) => {
  const USER = AUTH();
  const {
    EDIT,
    onChange,
    profile,
    submitProfile,
    editProfile,
    tempImageUrl,
    submitting,
    setBookNow,
    setRequest,
  } = useContext(PageContext);
  let {
    user_avatar,
    user_name,
    user_email,
    user_joined,
    user_current_plan,
    user_business_intros,
    user_subscription_type,
  } = useContext(AppContext);
  const fileRef = useRef(null);

  const openFileWindow = () => {
    if (EDIT) fileRef.current.click();
  };

  const [subscription, setSubscription] = useState();

  const profileURL = "https://app.mumsandco.com.au/member-directory/" + USER.id;
  const [textToCopy, setTextToCopy] = useState(profileURL);
  const [isCopied, setIsCopied] = useState(false);
  useEffect(() => {
    setSubscription(user_current_plan.subscription_type);
  }, [user_current_plan]);

  const handleCopyClick = async () => {
    try {
      await copy(textToCopy);
      setIsCopied(true);
    } catch (error) {
      console.error("Failed to copy: ", error);
    }
  };

  return (
    <div className={"w-full break-words lg:p-6"}>
      <div className={"mb-5"}>
        <div className={"flex flex-row items-center"}>
          <div className={`mr-5 flex self-center  ${EDIT && "avatar"}`}>
            {typeof user_avatar !== "function" || tempImageUrl.blob ? (
              <div
                onClick={openFileWindow}
                className={`rounded-full bg-center bg-no-repeat bg-cover border-2 w-15 h-15 ${EDIT &&
                  "cursor-pointer"}`}
                style={{
                  backgroundImage: `url(${transformImageUrl(tempImageUrl.blob || user_avatar)})`,
                }}
              />
            ) : (
              user_avatar(
                `flex items-center justify-center font-grumpy text-event-going text-3xl bg-white rounded-full border-2 w-15 h-15 ${EDIT &&
                  "cursor-pointer"}`,
                { onClick: openFileWindow }
              )
            )}
            <input type="file" className={"hidden"} ref={fileRef} onChange={onChange("avatar")} />
          </div>
          <div>
            <p className={"text-base font-medium text-black pb-2"}>{user_name}</p>
            <div className={"mb-4 md:hidden block"}>
              {props.location.pathname === "/profile/me" && (
                <button
                  disabled={submitting}
                  onClick={EDIT ? submitProfile : editProfile}
                  className={`bg-primary py-1 px-6 rounded text-white focus:outline-none ${
                    submitting ? "bg-disabled" : "bg-primary"
                  }`}
                >
                  {submitting && EDIT && <Icon type={"loading"} className={"mr-2"} />}
                  {EDIT ? "Save" : "Edit"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="my-4">
        <span
          onClick={handleCopyClick}
          className="font-medium font-sans cursor-pointer border border-primary text-primary p-2"
        >
          {isCopied ? "Copied!" : "Copy Profile Link"}
        </span>
      </div>
      <div className={"mb-3"}>
        {EDIT && (
          <>
            <Input
              placeholder={"Postcode"}
              onChange={onChange("address")}
              value={profile.address}
              className={"profile-input mb-4"}
              addonBefore={<Icon style={{ color: "white" }} type={"environment"} />}
            />
            <div className={"flex flex-row items-center"}>
              <NumberFormat
                placeholder={"+61-xxxxxxxxxx"}
                onChange={onChange("number")}
                value={profile.number}
                format={"+61-##########"}
                className={"profile-input mr-2"}
                addonBefore={<Icon style={{ color: "white" }} type={"phone"} />}
                customInput={Input}
              />
              <Icon type={"lock"} />
            </div>
          </>
        )}
      </div>
      {!EDIT && (
        <div className={"flex flex-row items-center mb-3"}>
          <div className={"w-8"}>
            <img src={transformImageUrl(profileLocationIcon)} alt="" className={"h-4 w-auto"} />
          </div>
          <p>{profile.address || "No info"}</p>
        </div>
      )}
      <div className={`flex flex-row items-center ${!EDIT ? "mb-3" : "mb-4"}`}>
        <div className={`${!EDIT ? "w-8" : "mr-3"} flex self-center`}>
          <img src={transformImageUrl(profileMailIcon)} alt="" className={"h-3 w-auto"} />
        </div>
        <div className={"flex self-center mr-2"}>
          <p className={"text-sm"}>{user_email || "No info"}</p>
        </div>
        <Icon type={"lock"} />
      </div>
      {!EDIT && (
        <div className={"flex flex-row items-center mb-3"}>
          <div className={"w-8"}>
            <img src={transformImageUrl(profilePhoneIcon)} alt="" className={"w-2   h-auto"} />
          </div>
          <p className={"mr-2"}>{profile.number || "No info"}</p>
          <Icon type={"lock"} />
        </div>
      )}

      {!EDIT ? (
        <div className={"mb-3"}>
          <span className={"font-medium text-black"}>Business Intros: </span>
          <span>{profile.business_intros ? "Opted-in" : "Opted-out"}</span>
        </div>
      ) : (
        <div className="flex items-center">
          <span className={"font-medium text-black"}>Business Intros: </span>
          <Switch
            onChange={onChange("business_intros")}
            defaultChecked={profile.business_intros}
            value={true}
            className={"ml-2"}
          />
        </div>
      )}

      <div className={`${!EDIT ? "mb-3" : "mb-5"}`}>
        <p className={`${!EDIT ? "mb-3" : "mb-1"}`}>
          <span className={"font-medium text-black"}>Joined: </span>
          {moment(user_joined).format("MMM YYYY")}
        </p>
        <p className={"flex justify-between"}>
          <div>
            <span className={"font-medium text-black"}>Subscription: </span>
            {subscription
              ? startCase(user_current_plan.subscription_type.toLowerCase())
              : "Sign Up"}
          </div>
          {subscription === null ? (
            <a
              onClick={() => {
                localStorage.removeItem("@return-url");
                props.history.push("/upgrade-account");
              }}
              className={"text-primary hover:text-primary focus:text-primary"}
            >
              Upgrade
            </a>
          ) : (
            <a
              onClick={() => {
                props.history.push(
                  ["sign_up", "free", "sign up"].includes(user_subscription_type.toLowerCase())
                    ? "/upgrade-account"
                    : "/user-subscription"
                );
              }}
              className={"text-primary hover:text-primary focus:text-primary"}
            >
              {["sign_up", "free", "sign up"].includes(user_subscription_type.toLowerCase())
                ? "Upgrade Account"
                : "View Inclusions"}
            </a>
          )}
        </p>
        {(user_current_plan || {}).subscription_type !== "SIGN_UP" && (
          <div>
            {user_current_plan.canceled_at ? (
              <p className="text-red-500 text-md">
                Expires on {moment((user_current_plan || {}).canceled_at).format("DD MMM YYYY")}
              </p>
            ) : (
              <p className="text-primary text-md">
                Auto-renews on {moment((user_current_plan || {}).expire_at).format("DD MMM YYYY")}
              </p>
            )}
          </div>
        )}
      </div>
      {/*{EDIT ? (*/}
      {/*  <div className={`flex flex-row mb-5 hidden`}>*/}
      {/*    <p className={'font-medium text-black mr-7 md:mr-0'} style={{ width: '12.666667%' }}>Tags</p>*/}
      {/*    <Select key={userTags} onChange={handleOnChangeUserTags}*/}
      {/*      value={userTags}*/}
      {/*      mode="tags"*/}
      {/*      className={'w-full'}/>*/}
      {/*  </div>*/}
      {/*) : (*/}
      {/*  <div className={'flex flex-row mb-5 hidden'}>*/}
      {/*    {userTags.map(value => {*/}
      {/*      return <div key={value} className={'py-1 px-3 bg-gray-300 text-black mr-2 rounded text-sx'}>*/}
      {/*        {value}*/}
      {/*      </div>;*/}
      {/*    })}*/}
      {/*  </div>*/}
      {/*)}*/}
      {EDIT ? (
        <>
          <Input.TextArea
            placeholder={
              "Tell members a bit about you - Who’s the woman behind the business? What are the strengths that make you good at what you do? What do you love outside of your business?"
            }
            onChange={onChange("about_me")}
            value={profile.about_me}
            rows={5}
            maxLength={500}
            className={`border h-35 p-3 w-full rounded bg-white custom-focus-hover`}
          />
          <p className={"font-light"} style={{ fontSize: "0.625rem" }}>
            {profile.about_me ? 500 - profile.about_me.length : "500"} characters remaining{" "}
          </p>
        </>
      ) : (
        <p className={"text-sm"}>{profile.about_me || "No info"}</p>
      )}
    </div>
  );
};

export default compose(Form.create({ name: "profile-form" }), withApollo, withRouter)(ProfileCard);
