import { Checkbox, Icon, Input, Modal } from "antd";
import { startCase } from "lodash";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { compose } from "react-apollo";
import { withRouter } from "react-router-dom";
import bookingConfirmation from "../../assets/icons/tickets.png";
import withApollo from "../../config/with-apollo";
import { AppContext, PageContext } from "../../helpers/context";
import { post } from "../../utils/api-handler";
import { AUTH } from "../../utils/common";
import { transformImageUrl } from "../../utils/tools";

const subscription = {
  FREE: 1,
  SIGN_UP: 1,
  COMMUNITY: 2,
  STANDARD: 3,
  PREMIUM: 4,
};
const DigitalLibraryConfirmation = (props) => {
  const { user_subscription_type, user_current_plan, user_tokens } = useContext(AppContext);
  const {
    digitalLibrary,
    showModal,
    setShowModal,
    setShowSuccessModal,
    showSuccessModal,
  } = useContext(PageContext);
  let resourceData = props.data || digitalLibrary;
  const USER = AUTH();
  const USER_ID = USER.hasura["https://hasura.io/jwt/claims"]["x-hasura-user-id"];
  const [submitting, setSubmitting] = useState(false);
  const [isDisabled, setDisabled] = useState(true);
  const [promoCode, setPromoCode] = useState("");
  const [promoError, setPromoError] = useState("");
  const [discountPercent, setDiscountPercent] = useState(0);
  const [modalType, setModalType] = useState("");
  const [payToken, setPayToken] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    if (!showModal || !resourceData) return setModalType("");
    // Check if match
    if (!hasAccess && resourceData.cost !== 0) return setModalType("no-access");

    if (
      (!resourceData.subscription_type && !user_subscription_type) ||
      resourceData.subscription_type === "ALWAYS_PAY_AS_YOU_GO" ||
      payToken
    ) {
      return setModalType("token");
    }

    // TODO: Check access level matching hierarchy
    if (
      !resourceData.subscription_type ||
      subscription[resourceData.subscription_type] <=
        subscription[(user_subscription_type || "").toUpperCase()] ||
      resourceData.cost === 0
    ) {
      return setModalType("allowed");
    } else {
      return setModalType("upgrade");
    }
  }, [hasAccess, resourceData, showModal, user_subscription_type, payToken]);

  useEffect(() => {
    if (!resourceData || showModal) return;

    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("modal")) {
      const url = new URL(window.location);
      url.searchParams.delete("modal");
      window.history.pushState(null, "", url.toString());
      setShowModal(true);
    }
  }, [resourceData, showModal]);

  useEffect(() => {
    if (user_current_plan) {
      const {
        subscriptionPropertyBySubscriptionProperty: subscription_properties,
        subscription_type,
      } = user_current_plan;
      if (subscription_type) {
        setHasAccess(subscription_properties.digital_library);
      }
    }

    if (user_current_plan.subscription_type === resourceData.subscription_type) {
      setHasAccess(true);
    }
  }, [user_current_plan, resourceData]);

  const handleApply = () => async () => {
    try {
      setPromoError("");
      const result = await post("/checkCode", {
        elementType: process.env.REACT_APP_DIGITAL_LIBRARY_KEY,
        elementId: resourceData.id,
        discountCode: promoCode,
      });
      setPromoError(`${result.data.percent}% discount code is applied`);
      setDiscountPercent(result.data.percent);
    } catch (e) {
      setPromoError(
        e.response && e.response.data && e.response.data.message
          ? e.response.data.message
          : "Error on discount code"
      );
    } finally {
    }
  };
  const handleGoing = (isToken = false) => async () => {
    if (moment().isSameOrBefore(resourceData.resource_end_date))
      try {
        setSubmitting(true);
        await post("/transact", {
          type: process.env.REACT_APP_DIGITAL_LIBRARY_KEY,
          transactionId: resourceData.id,
          tokens: isToken ? resourceData.cost : 0,
          name: resourceData.title,
          userId: USER_ID,
          filter_type: "DIGITAL_LIBRARY",
          discountCode: promoCode,
          is_token: true,
        });

        setShowModal(!showModal);
        setShowSuccessModal(!showSuccessModal);
        setDisabled(true);
        setPromoCode("");
        setPromoError("");
      } catch (e) {
        console.log(e, e.message);
        if (e.response.data.code === 1000) {
          localStorage.setItem(
            "@return-url",
            `/digital-library/${resourceData.id}?modal=digital-library-registration`
          );
          return props.history.push("/upgrade-account", { amount: resourceData.cost });
        }

        if (e.response.data.code === 1001) {
          localStorage.setItem(
            "@return-url",
            `/digital-library/${resourceData.id}?modal=digital-library-registration`
          );
          return props.history.push("/top-up", {
            amount: resourceData.cost,
            lackAmount: e.response.data.amount || resourceData.cost - user_tokens,
          });
        }
      } finally {
        setSubmitting(false);
        setPromoError("");
      }
  };
  const handleSelect = (e) => {
    setDisabled(!e.target.checked);
  };

  const onUpgradeClick = async () => {
    if (user_current_plan.subscription_type) {
      const response = await post(`/create-customer-session`, {
        redirect: `${window.location.origin}/digital-library/${resourceData.id}?modal=digital-library-registration`,
        userId: USER.id,
      });

      if (response.data.sessionURL) {
        window.open(response.data.sessionURL, "_self");
      }
    }

    localStorage.setItem(
      "@return-url",
      `/digital-library/${resourceData.id}?modal=digital-library-registration`
    );
    props.history.push("/upgrade-account", {
      selected: resourceData.subscription_type,
    });
  };

  const ModalAllowed = useCallback(() => {
    return (
      <div className={"flex flex-col "}>
        <img
          src={transformImageUrl(bookingConfirmation)}
          alt={""}
          className={"self-center w-24 mb-6"}
        />
        <p className={"self-center text-3xl text-black"}>Great choice!</p>
        <p className={"self-center text-lg mb-5 text-center"}>
          {user_current_plan.subscription_type === "PREMIUM" ||
          user_current_plan.subscription_type === "STANDARD"
            ? `This library is included as part of your Mums & Co ${user_subscription_type} membership.`
            : `This resource is free to redeem with your ${
                user_current_plan.subscription_type === "FREE" ? "Sign Up" : user_subscription_type
              } membership.`}
        </p>
        <div className={"flex flex-row w-full justify-center"}>
          <button
            disabled={submitting}
            onClick={() => {
              setShowModal(!showModal);
              setDisabled(true);
              setPromoCode("");
              setPromoError("");
            }}
            className={
              "self-center bg-light-gray-header text-black h-10 text-lg w-1/3 font-light mr-5  rounded active:bg-primary focus:outline-none "
            }
          >
            Cancel
          </button>
          <button
            type={"submit"}
            disabled={submitting}
            onClick={handleGoing(false)}
            className={`self-center text-white h-10 text-lg w-1/3 font-light  rounded active:bg-primary focus:outline-none  ${
              submitting ? "bg-disabled" : "bg-primary"
            }`}
          >
            {submitting && <Icon type={"loading"} className={"mr-2"} />}
            {user_current_plan.subscription_type === "PREMIUM" ||
            user_current_plan.subscription_type === "STANDARD"
              ? "Confirm"
              : "Redeem"}
          </button>
        </div>
      </div>
    );
  }, [showModal, submitting, user_subscription_type]);

  const ModalUpgrade = useCallback(() => {
    const subscription = startCase(resourceData.subscription_type.toLowerCase());
    return (
      <div className={"flex flex-col "}>
        <img
          src={transformImageUrl(bookingConfirmation)}
          alt={""}
          className={"self-center w-24 mb-6"}
        />
        <p className={"self-center text-3xl text-black"}>Can't Access!</p>
        <p className={"self-center text-lg mb-5 text-center"}>
          Can’t access yet! Your current membership subscription has view only access to the digital
          library. To access this content simply upgrade your membership plan or redeem using pay as
          you go tokens.
        </p>
        <div className={"flex flex-row w-full justify-center"}>
          <button
            disabled={submitting}
            onClick={() => {
              setShowModal(!showModal);
              onUpgradeClick();
            }}
            className={
              "self-center bg-event-going text-white h-10 w-full text-md md:w-2/3 font-light mr-5  rounded active:bg-primary focus:outline-none "
            }
          >
            Upgrade to {subscription}
          </button>
          <button
            disabled={submitting}
            onClick={() => setPayToken(true)}
            className={`self-center text-white h-10 text-md md:w-2/3 w-full font-light  rounded active:bg-primary focus:outline-none  ${
              submitting ? "bg-disabled" : "bg-primary"
            }`}
          >
            {submitting && <Icon type={"loading"} className={"mr-2"} />}
            Redeem with Tokens
          </button>
        </div>
      </div>
    );
  }, [showModal, submitting, resourceData]);

  const ModalAccess = useCallback(() => {
    return (
      <div className={"flex flex-col "}>
        <img
          src={transformImageUrl(bookingConfirmation)}
          alt={""}
          className={"self-center w-24 mb-6"}
        />
        <p className={"self-center text-2xl text-black mb-2"}>
          Upgrade or redeem with tokens to access
        </p>
        <p className={"self-center text-lg mb-5 text-center"}>
          Your current subscription has no access to this digital resource. Please upgrade to
          Standard to access all the resources available here or redeem using pay as you go tokens.
        </p>
        <div className={"flex flex-row w-full justify-center"}>
          <button
            disabled={submitting}
            onClick={() => {
              setShowModal(!showModal);
              onUpgradeClick();
            }}
            className={
              "self-center bg-event-going text-white h-10 w-full text-md md:w-2/3 font-light mr-5  rounded active:bg-primary focus:outline-none "
            }
          >
            Upgrade to Standard
          </button>
          <button
            disabled={submitting}
            onClick={() => {
              setModalType("token");
            }}
            className={`self-center text-white h-10 text-md md:w-2/3 w-full font-light  rounded active:bg-primary focus:outline-none  ${
              submitting ? "bg-disabled" : "bg-primary"
            }`}
          >
            {submitting && <Icon type={"loading"} className={"mr-2"} />}
            Redeem with Tokens
          </button>
        </div>
      </div>
    );
  }, [showModal, submitting]);

  const ModalToken = useCallback(() => {
    return (
      <div className={"flex flex-col "}>
        <img
          src={transformImageUrl(bookingConfirmation)}
          alt={""}
          className={"self-center w-24 mb-6"}
        />
        <p className={"self-center text-3xl text-black"}>Great choice!</p>
        <p className={"self-center text-lg mb-5 text-center"}>
          {Math.round((resourceData.cost * (100 - (discountPercent ? discountPercent : 0))) / 100)}{" "}
          tokens will be deducted to purchase the item. <br />
          Don’t worry if you don’t have enough right now. You’ll be redirected to the Top Up page
          after pressing the Confirm button.
        </p>
        <div className={"flex flex-row w-full justify-center mb-2"}>
          <Checkbox checked={!isDisabled} onChange={handleSelect}>
            Apply Discount code
          </Checkbox>
        </div>
        <div className={"flex flex-row w-full justify-center mb-2"}>
          <Input
            placeholder={"Discount code"}
            disabled={isDisabled}
            className={"md:w-1/3 w-full border rounded bg-white custom-focus-hover"}
            value={promoCode}
            autoFocus
            onChange={(e) => {
              setPromoCode(e.target.value);
            }}
          />
          <button
            disabled={isDisabled}
            onClick={handleApply()}
            className={`self-center text-white h-8 text-lg w-1/5 font-light  rounded active:bg-primary focus:outline-none bg-primary ${
              isDisabled ? "bg-disabled" : "bg-primary"
            }`}
          >
            Apply
          </button>
        </div>
        <div className={"flex flex-row w-full justify-center text-red-500"}>{promoError}</div>
        <div className={"flex flex-row w-full justify-center"}>
          <button
            disabled={submitting}
            onClick={() => {
              setShowModal(!showModal);
              setDisabled(true);
              setPromoCode("");
              setPromoError("");
              setPayToken(false);
            }}
            className={
              "self-center bg-light-gray-header text-black h-10 text-lg w-1/3 font-light mr-5  rounded active:bg-primary focus:outline-none "
            }
          >
            Cancel
          </button>
          <button
            type={"submit"}
            disabled={submitting}
            onClick={handleGoing(true)}
            className={`self-center text-white h-10 text-lg w-1/3 font-light  rounded active:bg-primary focus:outline-none  ${
              submitting ? "bg-disabled" : "bg-primary"
            }`}
          >
            {submitting && <Icon type={"loading"} className={"mr-2"} />}
            Confirm
          </button>
        </div>
        <div className={"mt-2 flex self-center"}>
          <p>Note : Token refunds are at Mums & Co’s sole discretion.</p>
        </div>
      </div>
    );
  }, [resourceData, isDisabled, promoError, showModal, submitting, discountPercent, promoCode]);

  const modalProps = useMemo(() => {
    if (modalType === "allowed" || modalType === "token") return {};
    return {
      closable: true,
      maskClosable: true,
      onCancel: () => {
        setShowModal(!showModal);
      },
    };
  }, [modalType, showModal]);

  return (
    <Modal
      visible={showModal}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      footer={null}
      closable={false}
      {...modalProps}
    >
      {modalType === "allowed" && <ModalAllowed />}
      {modalType === "upgrade" && <ModalUpgrade />}
      {modalType === "token" && <ModalToken />}
      {modalType === "no-access" && <ModalAccess />}
    </Modal>
  );
};

export default compose(withApollo, withRouter)(DigitalLibraryConfirmation);
