import React from "react";
import helpCover from "../../assets/images/help-cover-mobile.png";
import { transformImageUrl } from "../../utils/tools";

const HeaderCoverBanner1Mobile = (props) => {
  return (
    <div
      className="md:hidden bg-cover bg-black-opacity h-40"
      style={{ backgroundImage: `url(${transformImageUrl(helpCover)})` }}
    >
      <div className={"container pt-4 px-4 w-full relative"}>
        <p className={"text-lg text-white font-bold mb-2"}>{props.title || ""}</p>
        <p className={"text-white text-sm text-medium"}>
          {props.subtitle || ""}
          {props.extraSubtitle}
        </p>
      </div>
    </div>
  );
};

export default HeaderCoverBanner1Mobile;
