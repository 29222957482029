import { useSubscription } from "@apollo/react-hooks";
import { Spin, Tooltip } from "antd";
import "antd/es/tooltip/style/css";
import React from "react";
import { compose } from "react-apollo";
import tooltipIcon from "../../assets/icons/tooltip-icon.png";
import withApollo from "../../config/with-apollo";
import { S_DASHBOARD_WHATS_NEW } from "../../graphql/dashboard";
import { transformImageUrl } from "../../utils/tools";
const BusinessBoost = (props) => {
  const { loading, data } = useSubscription(S_DASHBOARD_WHATS_NEW, { client: props.client });

  return (
    <div
      className={
        "bg-white rounded-lg px-6 pb-6 shadow-md overflow-y-auto h-95 business_boost_tour_class"
      }
    >
      <div className={"py-4 sticky z-10 top-0 bg-white"}>
        <p className={"text-lg font-grumpy text-black"}>
          <span className="whats_new_tour_class">What's New </span>
          <Tooltip
            placement="topLeft"
            title={
              "Latest news and features to help take your business to the next level and get the most out of your membership."
            }
          >
            <img src={transformImageUrl(tooltipIcon)} className={"w-4 h-auto inline"} alt={""} />
          </Tooltip>
        </p>
      </div>
      <div>
        {!loading ? (
          data.whats_new.map((value, key) => {
            return (
              <React.Fragment key={`business-boost-${key}`}>
                <div className={"flex flex-row"} key={value.id}>
                  {/*<div className={` payments`}*/}
                  {/*  style={{ flex: ' 0 0 3rem' }}>*/}
                  {/*  <img src={transformImageUrl(value.icon)} alt={value.reward} className={'w-auto h-7'}/>*/}
                  {/*</div>*/}

                  <div
                    className={`bg-primary p-1 flex justify-center items-center w-12 h-12 rounded-full mr-8 payments`}
                    style={{ flex: " 0 0 3rem" }}
                  >
                    <img
                      src={transformImageUrl(value.icon)}
                      alt={value.icon}
                      className={"w-7 h-auto"}
                    />
                  </div>
                  <div
                    className={"w-full flex flex-row items-center self-center w-full break-words"}
                  >
                    <div className={"w-full flex flex-col justify-between"}>
                      <span className={"text-base text-black font-medium"}>{value.title}</span>
                      <p className={"text-sm"}>{value.sub_title}</p>
                    </div>
                    {value.url ? (
                      <a
                        href={value.url}
                        className={
                          "pl-5 font-normal text-sm underline text-primary hover:text-primary focus:text-primary"
                        }
                      >
                        More details
                      </a>
                    ) : (
                      <span className={"pl-5"} style={{ width: "82px" }} />
                    )}
                  </div>
                </div>
                <div className={"border-b border-gray-300 my-3"} />
              </React.Fragment>
            );
          })
        ) : (
          <div className={"w-full flex justify-center items-center h-full flex-col"}>
            <Spin size={"large"} />
            <p>Getting data...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default compose(withApollo)(BusinessBoost);
