import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { Icon, Modal, Spin } from "antd";
import "antd/es/icon/style/css";
import { startCase } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import profileLocationIcon from "../../assets/icons/profile-postal.png";
import urlLink from "../../assets/icons/website-link-profile.png";
import withApollo from "../../config/with-apollo";
import { MEMBER_TRACKER } from "../../graphql/member-directories";
import { PageContext } from "../../helpers/context";
import { AUTH, USER_DEACTIVE } from "../../utils/common";
import { QUERY_USER } from "../../graphql/user";
import { BsChatSquareTextFill } from "react-icons/bs";
import { Tooltip } from "antd";
import copyIcon from "../../assets/icons/copy.png";
import copy from "clipboard-copy";
import { transformImageUrl } from "../../utils/tools";

const UserModal = (props) => {
  const USER = AUTH();
  const { showUserModal, setShowUserModal, selectedId, urlId } = useContext(PageContext);
  const [user, setUser] = useState({});
  const [businesses, setBusinesses] = useState([]);
  const [currentBusiness, setCurrentBusiness] = useState(0);
  const [userBusinessTags, setUserBusinessTags] = useState([]);
  const [trackMember] = useMutation(MEMBER_TRACKER, { client: props.client });
  const [loadUser, { loading, data }] = useLazyQuery(QUERY_USER, {
    client: props.client,
    variables: { id: null },
  });

  useEffect(() => {
    if (selectedId && showUserModal) {
      loadUser({ variables: { id: selectedId } });
      trackMember({
        variables: {
          objects: [{ viewer_id: USER.id, member_id: selectedId, source: props.source }],
        },
      });
    }
  }, [selectedId, showUserModal]);

  useEffect(() => {
    if (data && data.users_by_pk) {
      const { user_business_goals_tags, businesses } = data.users_by_pk;
      const tagsData = [];
      (user_business_goals_tags || []).forEach((item) => {
        const tag = item.business_goals_tag;
        const findTags = tagsData.find((tag) => tag.category.id === item.category.id);
        if (findTags) {
          findTags.tags.push({ ...tag, color: item.pillar.color });
        } else {
          const categoryItem = {
            category: item.category,
            tags: [{ ...tag, color: item.pillar.color }],
          };
          tagsData.push(categoryItem);
        }
      });
      setUserBusinessTags(tagsData);
      setBusinesses(businesses);
      setUser(data.users_by_pk);
    }
  }, [data]);

  const changeBusiness = (index) => () => {
    setCurrentBusiness(index);
  };

  const urlify = (text) => {
    let urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;

    return text.replace(urlRegex, function(url) {
      let parseUrl;

      if (url.indexOf("https") !== -1 || url.indexOf("http") !== -1) {
        parseUrl = url;
      } else {
        parseUrl = `//${url}`;
      }

      return `<a
        target="_blank" href="${parseUrl}"
        class="inline-block text-primary hover:text-primary focus:text-primary">${url}</a>`;
    });
  };

  const handleOnCancel = () => {
    setShowUserModal(false);
    if (urlId.length === 36) {
      return props.history.push("/member-directory");
    }
  };

  const IsExpiredOrDisabled = () => {
    if (!loading && user && user.status === USER_DEACTIVE) {
      return true;
    }

    if (!loading && user && user.user_plans) {
      if (!user.user_plans.length) {
        return true;
      }
    }
    return false;
  };

  const handleConnectUser = (id) => async () => {
    props.history.push("/messages", { user_id: id });
  };

  const UserDetails = () => {
    return (
      <>
        <div className={"flex md:flex-row flex-col mb-6 mt-6"}>
          <div className={"md:mr-5 mb-5"}>
            {user && user.avatar ? (
              <div
                style={{ backgroundImage: `url(${transformImageUrl(user.avatar)})` }}
                className={"w-32 h-32 rounded-lg overflow-hidden bg-center bg-cover bg-no-repeat"}
              />
            ) : (
              <div
                className={
                  "w-32 h-32 bg-event-going text-white flex items-center justify-center text-6xl font-grumpy rounded-lg overflow-hidden"
                }
              >
                {user.name && user.name[0]}
              </div>
            )}
          </div>
          <div>
            <div className={"mb-1 font-medium text-black text-base flex items-center"}>
              <p className="w-1/2 mr-4">{user.name}</p>
              <div className="flex space-x-2">
                <Tooltip title="Send message">
                  <BsChatSquareTextFill
                    onClick={handleConnectUser(user.id)}
                    className={`cursor-pointer text-primary text-xl mr-4 rounded hover:scale-2`}
                  />
                </Tooltip>
              </div>
            </div>

            <div>
              <div className={"flex flex-row items-center mb-1"}>
                <div className={"w-8"}>
                  <img
                    src={transformImageUrl(profileLocationIcon)}
                    alt=""
                    className={"h-4 w-auto"}
                  />
                </div>
                <p>{user.address || "No info"}</p>
              </div>
            </div>
            <div className={"flex md:flex-row flex-col mb-4"}>
              <p className={`mb-1 mr-6`}>
                <span className={"font-medium text-black"}>Joined: </span>
                {moment(user.created_at).format("MMM YYYY")}
              </p>
              <div>
                <span className={"font-medium text-black"}>Current Subscription: </span>
                {user.user_plans && user.user_plans[0].subscription_type
                  ? startCase(user.user_plans[0].subscription_type.toLowerCase())
                  : "Sign Up"}
              </div>
            </div>
            <p>{user.about_me}</p>
          </div>
        </div>
        <div className={"flex flex-row flex-wrap pb-6"}>
          <button
            onClick={changeBusiness(0)}
            className={`${
              currentBusiness === 0
                ? "bg-black text-white border border-black"
                : "bg-white border-black border text-black"
            } mr-6 rounded px-4 py-1 flex items-center focus:outline-none cursor-pointer`}
          >
            Primary Biz
          </button>
          <button
            onClick={changeBusiness(1)}
            className={`${
              currentBusiness !== 0
                ? "bg-black text-white border border-black"
                : "bg-white border-black border text-black"
            } rounded px-4 py-1 flex focus:outline-none  items-center cursor-pointer`}
          >
            Secondary Biz
          </button>
        </div>
        <div>
          <div className={"break-words"}>
            <div className={"mb-5"}>
              <div className={"profile-business-label"}>Stage</div>
              <div className={"inline-block mr-6"}>
                {businesses.length &&
                businesses[currentBusiness] &&
                businesses[currentBusiness].stage
                  ? businesses[currentBusiness].stage
                  : "No info"}
              </div>
              <div className={"inline-block"}>
                <div className={"profile-business-label"}>Industry</div>
                <div className={"inline-block"}>
                  {businesses.length &&
                  businesses[currentBusiness] &&
                  businesses[currentBusiness].industry
                    ? businesses[currentBusiness].industry
                    : "No info"}
                </div>
              </div>
            </div>
            <div className={"mb-5"}>
              <div className={"profile-business-label"}>Name</div>
              <div className={"inline-block mr-6"}>
                {businesses.length &&
                businesses[currentBusiness] &&
                businesses[currentBusiness].name
                  ? businesses[currentBusiness].name
                  : "No info"}
              </div>
              <div className={"items-center inline-block"}>
                <img
                  src={transformImageUrl(urlLink)}
                  className={"h-auto w-6 inline-block mr-3"}
                  alt=""
                />
                <div
                  className={"inline-block"}
                  dangerouslySetInnerHTML={{
                    __html:
                      businesses.length &&
                      businesses[currentBusiness] &&
                      businesses[currentBusiness].url
                        ? urlify(businesses[currentBusiness].url)
                        : "N/A",
                  }}
                />
              </div>
            </div>
            <div className={"mb-5"}>
              <div className={"profile-business-label"}>Type</div>
              <div className={"inline-block mr-6"}>
                {businesses.length &&
                businesses[currentBusiness] &&
                businesses[currentBusiness].type
                  ? businesses[currentBusiness].type
                  : "No info"}
              </div>
              <div className={"inline-block"}>
                <div className={"profile-business-label"}>Offering</div>
                <div className={"inline-block"}>
                  {businesses.length &&
                  businesses[currentBusiness] &&
                  businesses[currentBusiness].offering
                    ? businesses[currentBusiness].offering
                    : "No info"}
                </div>
              </div>
            </div>
            <p className={"text-sm mb-5"}>
              {businesses.length && businesses[currentBusiness] && businesses[currentBusiness].about
                ? businesses[currentBusiness].about
                : ""}
            </p>
            <div style={{ maxHeight: 250, overflow: "auto" }}>
              <ul className={"list-disc list-inside category-list category-list-user-modal"}>
                {userBusinessTags.map((item) => {
                  return (
                    <li key={item.category.id}>
                      <span>{item.category.name}</span>
                      <ul>
                        {item.tags.map((tag) => (
                          <li
                            className="small_tag"
                            key={tag.id}
                            style={{
                              outlineColor: "#" + tag.color,
                              boxShadow: "#" + tag.color + " 0 0px 8px",
                            }}
                          >
                            {tag.name}
                          </li>
                        ))}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <Modal
      className={"user-modal"}
      visible={showUserModal}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      footer={null}
      onCancel={handleOnCancel}
      destroyOnClose={true}
      style={{ top: 20 }}
    >
      {loading ? (
        <div className={"flex justify-center"}>
          <Spin />
        </div>
      ) : !IsExpiredOrDisabled() ? (
        <UserDetails />
      ) : (
        <div className={"text-black text-base"}>Sorry the member is no longer active</div>
      )}
    </Modal>
  );
};

export default withApollo(withRouter(UserModal));
