import { Card, Icon, Tooltip } from "antd";
import "antd/es/tooltip/style/css";
import { startCase } from "lodash";
import moment from "moment-timezone";
import React, { useContext, useEffect, useRef } from "react";
import { compose } from "react-apollo";
import { withRouter } from "react-router-dom";
import calendarIcon from "../../assets/icons/calendar-icon.png";
import locationIcon from "../../assets/icons/location-icon.png";
import ticketIcon from "../../assets/icons/tickets.png";
import tooltipIcon from "../../assets/icons/tooltip-icon.png";
import withApollo from "../../config/with-apollo";
import { PageContext } from "../../helpers/context";
import { AUTH } from "../../utils/common";
import EventBookingCancel from "../modals/event-booking-cancel";
import EventBookingConfirmation from "../modals/event-booking-confirmation";
import { transformImageUrl } from "../../utils/tools";

const EventDetailsCard = (props) => {
  const { card, match } = props;
  const {
    event,
    setShowModal,
    setCurrentEvent,
    setHeightCards,
    queries,
    setQueries,
    setShowSuccessModal,
  } = useContext(PageContext);
  const USER = AUTH();
  const USER_ID = USER.hasura["https://hasura.io/jwt/claims"]["x-hasura-user-id"];
  const ID = match.params.id;

  const cardRef = useRef(null);

  useEffect(() => {
    if (queries) {
      if (queries.modal === "event-registration") {
        setShowModal(true);
      } else if (queries.modal === "event-booking-confirmation") {
        setShowSuccessModal(true);
      }
    }
  }, []);

  useEffect(() => {
    getCardSize();

    window.addEventListener("resize", getCardSize);
  }, [cardRef]);

  useEffect(() => {
    return () => {
      setHeightCards({ details: 0 });
    };
  }, []);

  const getCardSize = () => {
    if (ID) {
      setHeightCards((oldState) => ({ ...oldState, details: 833 }));
    }
  };
  const hasAttendedTheEvent = event.attendees_count.find((value) => value.user_id === USER_ID);

  const numberOfSpotsLeft = event.number_of_spots - event.attendees_count.length;

  const CardCover = () => {
    if (!ID) {
      if (!event.video_id) {
        return (
          <div
            className={`w-full ${!ID &&
              "xl:h-52 lg:h-63.5 md:h-84 h-46"} bg-contain bg-no-repeat bg-center`}
            style={{ backgroundImage: `url(${transformImageUrl(event.image)})` }}
          />
        );
      }

      return (
        <iframe
          className={`w-full xl:h-52 lg:h-63.5 md:h-84 h-46 bg-black`}
          src={`${process.env.REACT_APP_VIMEO_PLAYER_URL}/video/${event.video_id}`}
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      );
    } else {
      if (!event.video_id) {
        return (
          <div
            className={`w-full xl:h-108 lg:h-84 md:h-56 h-48 bg-contain bg-no-repeat bg-center`}
            style={{ backgroundImage: `url(${transformImageUrl(event.image)})` }}
          />
        );
      }

      return (
        <iframe
          className={`w-full xl:h-108 lg:h-84 md:h-56 h-48 bg-black`}
          src={`${process.env.REACT_APP_VIMEO_PLAYER_URL}/video/${event.video_id}`}
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      );
    }
  };

  const EventFooter = () => {
    if (moment().isAfter(new moment(`${event.date_to} ${event.time_to}`))) {
      return (
        <div
          className={
            "h-10 w-full rounded-b-lg bg-light-gray-header text-black text-lg flex justify-center items-center mt-6"
          }
        >
          Expired
        </div>
      );
    }

    if (numberOfSpotsLeft <= 0) {
      if (hasAttendedTheEvent) {
        return (
          <div
            className={
              "h-10 w-full rounded-b-lg bg-event-going text-white text-lg flex justify-center items-center mt-6"
            }
          >
            Going
          </div>
        );
      }

      return (
        <div
          className={
            "h-10 w-full rounded-b-lg bg-light-gray-header text-black text-lg flex justify-center items-center mt-6"
          }
        >
          Sold Out
        </div>
      );
    }

    if (hasAttendedTheEvent) {
      return (
        <div
          className={
            "h-10 w-full rounded-b-lg bg-event-going text-white text-lg flex justify-center items-center mt-6"
          }
        >
          Going
        </div>
      );
    }
    if (
      event.event_type_id === "523336ef-723a-4a02-95ea-273fcd19cb4b" ||
      event.event_type_id === "fa6b2383-eca5-497c-91b3-402834c8e1b2"
    ) {
      return (
        <a
          href={event.location_notes}
          className={
            "h-10 w-full rounded-b-lg bg-primary text-white text-lg flex justify-center items-center mt-6 hover:text-white"
          }
        >
          I am in
        </a>
      );
    } else {
      return (
        <a
          onClick={handleConfirmModal(event)}
          className={
            "h-10 w-full rounded-b-lg bg-primary text-white text-lg flex justify-center items-center mt-6 hover:text-white"
          }
        >
          I am in
        </a>
      );
    }
  };

  const handleConfirmModal = (event) => () => {
    setShowModal((state) => {
      const newState = !state;
      setQueries({ modal: newState ? "event-registration" : undefined });
      return newState;
    });
    setCurrentEvent(event);
  };

  const formatTimeWithTimezone = (time_from, time_to) => {
    // Helper function to get the timezone abbreviation based on the offset
    const getTimezoneAbbreviation = (offset) => {
      if (offset === "+10") {
        return "AEST";
      } else if (offset === "+11") {
        return "AEDT";
      } else {
        return " ";
      }
    };
    const timezoneOffset = time_from.slice(-3);

    const timezoneAbbreviation = getTimezoneAbbreviation(timezoneOffset);

    let formattedTimeFrom = moment();
    let formattedTimeTo = moment();
    if (timezoneAbbreviation === "AEDT") {
      formattedTimeFrom = moment(time_from, "HH:mm:ssZ")
        .tz("Asia/Magadan")
        .format("h:mm A");
      formattedTimeTo = moment(time_to, "HH:mm:ssZ")
        .tz("Asia/Magadan")
        .format("h:mm A");
    } else {
      formattedTimeFrom = moment(time_from, "HH:mm:ssZ")
        .tz("Australia/Sydney")
        .format("h:mm A");
      formattedTimeTo = moment(time_to, "HH:mm:ssZ")
        .tz("Australia/Sydney")
        .format("h:mm A");
    }

    return `${formattedTimeFrom} - ${formattedTimeTo} ${timezoneAbbreviation}`;
  };

  return (
    <>
      <EventBookingConfirmation />
      <EventBookingCancel />
      <div ref={cardRef}>
        <Card
          className={`bg-white rounded-lg overflow-hidden shadow-md h-140 ${card &&
            card.className}`}
        >
          <CardCover />
          <div className={"px-3"}>
            <Card.Meta
              className={"pt-4 font-bold font-grumpy text-2xl text-black h-20"}
              title={event.name}
            />
            <div className={"flex justify-between h-28"}>
              {event.event_type_id === "523336ef-723a-4a02-95ea-273fcd19cb4b" ||
              event.event_type_id === "fa6b2383-eca5-497c-91b3-402834c8e1b2" ? null : (
                <div className={"mt-4 text-base text-black flex w-full"}>
                  <div className={"w-1/8"}>
                    <Icon className={"w-4"} type="team" />
                  </div>
                  <div className={"w-full"}>
                    Requires
                    <p className={"text-gray-800 font-light text-sm"}>
                      {event.subscription_type
                        ? startCase(event.subscription_type.toLowerCase())
                        : "N/A"}
                    </p>
                    {event.subscription_type === "COMMUNITY" ? (
                      <>
                        <p className={"text-gray-800 font-light text-sm"}>Standard</p>
                        <p className={"text-gray-800 font-light text-sm"}>Premium</p>
                      </>
                    ) : (
                      ""
                    )}
                    {event.subscription_type === "STANDARD" ? (
                      <>
                        <p className={"text-gray-800 font-light text-sm"}>Premium</p>
                      </>
                    ) : (
                      ""
                    )}
                    {event.subscription_type === "ALWAYS_PAY_AS_YOU_GO" ? (
                      <></>
                    ) : (
                      <>
                        <p className={"text-gray-800 font-light text-sm"}>Pay As You Go</p>
                      </>
                    )}
                  </div>
                </div>
              )}
              <div className={"mt-4 text-base text-black flex w-full"}>
                <div className={"w-1/8 pt-1.5"}>
                  <img src={transformImageUrl(calendarIcon)} className={"w-4 h-auto"} alt={""} />
                </div>
                <div className={"w-full"}>
                  Date and Time
                  <p className={"text-gray-800 font-light text-sm"}>
                    {moment(event.date_from).isSame(event.date_to)
                      ? moment(event.date_to).format("ll")
                      : `${moment(event.date_from).format("ll")} - ${moment(event.date_to).format(
                          "ll"
                        )}`}
                  </p>
                  <p className={"text-gray-800 font-light text-sm"}>
                    {formatTimeWithTimezone(event.time_from, event.time_to)}
                  </p>
                </div>
              </div>
              <div className={"mt-4 text-base text-black flex w-full"}>
                <div className={"w-1/8 pt-1.5"}>
                  <img src={transformImageUrl(locationIcon)} className={"w-4 h-auto"} alt={""} />
                </div>
                <div className={"w-full"}>
                  <p>
                    Location{" "}
                    {!!event.location_notes && (
                      <Tooltip placement="topLeft" title={event.location_notes}>
                        <img
                          src={transformImageUrl(tooltipIcon)}
                          className={"w-4 h-auto inline"}
                          alt={""}
                        />
                      </Tooltip>
                    )}
                  </p>
                  {event.online && (
                    <a
                      href={!!hasAttendedTheEvent && event.online ? event.location : "#"}
                      target={"__blank"}
                      className={`font-light text-sm ${
                        !!hasAttendedTheEvent ? "text-primary" : "text-gray-800"
                      }`}
                    >
                      {!!hasAttendedTheEvent && event.online ? "Go to Online Event" : "Online"}
                    </a>
                  )}
                  {!event.online && (
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${event.location}`}
                      target="_blank"
                      className={"text-primary text-base hover:text-primary"}
                    >
                      View on map
                    </a>
                  )}
                </div>
              </div>
            </div>
            <div className={"flex flex-between items-center"}>
              <div className={"mt-6 flex w-full"}>
                {event.event_type_id === "523336ef-723a-4a02-95ea-273fcd19cb4b" ||
                event.event_type_id === "fa6b2383-eca5-497c-91b3-402834c8e1b2" ? null : (
                  <>
                    <div className={"w-1/8 pt-1.5"} />
                    <div
                      className={
                        "w-full flex justify-between justify-center items-center text-black text-base"
                      }
                    >
                      {event.cost ? (
                        <div className={"flex flex-row items-center"}>
                          <img
                            src={transformImageUrl(ticketIcon)}
                            className={"h-10.5 mr-4"}
                            alt={""}
                          />
                          {event.cost}
                        </div>
                      ) : (
                        <div className={"border rounded p-2"}>FREE</div>
                      )}
                    </div>
                    <div className={"mt-4 flex w-full"}>
                      <div className={"w-1/8 pt-1.5"}></div>
                      <div className={"w-full flex justify-between text-base"}>
                        {!!hasAttendedTheEvent &&
                        !moment().isAfter(new moment(`${event.date_to} ${event.time_to}`)) ? (
                          <p onClick={() => {}} className={"text-red-500 cursor-pointer hidden"}>
                            Cancel
                          </p>
                        ) : (
                          <p className={"text-black"}>Only {numberOfSpotsLeft || 0} seats left !</p>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <EventFooter />
        </Card>
      </div>
    </>
  );
};

export default compose(withRouter, withApollo)(EventDetailsCard);
