import React from "react";
import { transformImageUrl } from "../../utils/tools";

const styles = {
  tooltip: {
    backgroundColor: "rgb(255, 255, 255)",
    borderRadius: 5,
    boxSizing: "border-box",
    color: "rgb(51, 51, 51)",
    fontSize: 16,
    maxWidth: "100%",
    padding: "10px 12px",
    position: "relative",
    width: 380,
  },
  header: {
    fontWeight: 700,
    fontSize: 14.5,
    color: "#4CA7B8",
    borderBottom: "1px solid #4CA7B8",
    paddingBottom: 7,
  },
  close: {
    float: "right",
    color: "#AAAAAA",
    outline: "none",
    lineHeight: "20px",
    fontSize: 18,
  },
  content: {
    color: "#555555",
    fontSize: 12,
    fontWeight: 500,
    marginBottom: 14,
    paddingTop: 8,
    lineHeight: "14.5px",
  },
  footer: {
    height: 24,
  },
  footer_button: {
    float: "right",
    fontSize: 12,
    fontWeight: 700,
    outline: "none",
  },
  back_button: {
    marginRight: 10,
    color: "#4CA7B8",
    lineHeight: "26px",
  },
  next_button: {
    backgroundColor: "#4CA7B8",
    color: "white",
    borderRadius: 8,
    padding: 4,
  },
};

const TourTooltip = ({
  continuous = false,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
}) => {
  return (
    <div className="__floater__body" style={{ width: 344 }} {...tooltipProps}>
      <div className="react-joyride__tooltip" style={styles.tooltip}>
        <div className="joyride_header" style={styles.header}>
          {step.title && <span>{step.title}</span>}
          <button style={styles.close} {...closeProps}>
            x
          </button>
        </div>

        <div style={styles.content}>
          {step.video_id ? (
            <iframe
              className={`tour-player w-full h-63 bg-white mb-2`}
              src={`${process.env.REACT_APP_VIMEO_PLAYER_URL}/video/${step.video_id}&autoplay=1&autopause=0&texttrack=en-x-autogenerated`}
              allow="autoplay"
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
            />
          ) : step.image ? (
            <img src={transformImageUrl(step.image)} />
          ) : (
            step.content
          )}
        </div>
        <div style={styles.footer}>
          {continuous && (
            <button style={{ ...styles.footer_button, ...styles.next_button }} {...primaryProps}>
              {index === 6 ? "Finish" : "Next"} {index + 1}/7
            </button>
          )}
          {index > 0 && (
            <button style={{ ...styles.footer_button, ...styles.back_button }} {...backProps}>
              Back
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TourTooltip;
