import { useMutation } from "@apollo/react-hooks";
import React, { useContext, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import discountIcon from "../../assets/icons/discount-icon.png";
import downloadIcon from "../../assets/icons/download-icon.png";
import "../../assets/styles/ribbon.css";
import withApollo from "../../config/with-apollo";
import { M_PERKS_LOG } from "../../graphql/perks";
import { PageContext } from "../../helpers/context";
import { AUTH } from "../../utils/common";
import { transformImageUrl } from "../../utils/tools";

const PerkDetailCard = (props) => {
  const { perks, setHeightCards } = useContext(PageContext);
  const ID = props.match.params.id;
  const { client } = props;

  const cardRef = useRef(null);

  const USER = AUTH();

  const [perkLog, {}] = useMutation(M_PERKS_LOG, { client });

  useEffect(() => {
    return () => {
      setHeightCards({ details: 0 });
    };
  }, []);

  useEffect(() => {
    getCardSize();

    window.addEventListener("resize", getCardSize);
  }, [cardRef]);

  const getCardSize = () => {
    if (ID) {
      setHeightCards((oldState) => ({
        ...oldState,
        details: !perks.discount_description ? 633 : 649,
      }));
    }
  };

  const handleOnClick = async () => {
    await perkLog({
      variables: {
        objects: [
          {
            user_id: USER.id,
            perk_id: perks.id,
          },
        ],
      },
    });
  };

  return (
    <div
      className={`bg-white rounded-lg h-full shadow-md ${props.card && props.card.className}`}
      ref={cardRef}
    >
      <div className={"relative"}>
        {perks.is_featured && <div className="ribbon">Featured</div>}
        <div
          className={"md:h-104 h-52 w-full bg-contain bg-no-repeat bg-center"}
          style={{
            backgroundImage: `url(${transformImageUrl(
              perks.image || "https://i.ytimg.com/vi/6y-OAsRZbas/maxresdefault.jpg"
            )})`,
          }}
        ></div>
      </div>
      <div className={"px-4"}>
        <p className={"pt-4 font-bold font-grumpy h-20 text-lg text-black md:text-2xl mb-4"}>
          {perks.name}
        </p>
        <div className={"md:flex md:flex-row md:justify-between items-start"}>
          <div className={"text-base text-black md:w-1/3 mb-4 md:mb-0"}>
            <button
              className={`font-grumpy bg-primary px-4 py-1 rounded text-sm text-white md:text-2xl md:px-6 md:py-2 focus:outline-none`}
            >
              {perks.discount}
            </button>
            <p className={"text-xs mt-1  md:text-sm font-light"}>{perks.discount_description}</p>
          </div>
          <div
            className={`text-base text-black flex md:w-1/3 items-start ${!perks.redemption_code &&
              "hidden"} mb-4 md:mb-0`}
          >
            <div className={"w-1/8 mr-2"}>
              <img src={transformImageUrl(discountIcon)} className={"w-5 h-auto mt-1"} alt={""} />
            </div>
            <div className={"w-full"}>
              <p className={"font-medium text-base mb-2"}>Redemption Code</p>
              <button
                className={
                  "font-light text-xs border rounded px-2 py-1 border-black focus:outline-none cursor-default"
                }
              >
                {perks.redemption_code}
              </button>
            </div>
          </div>
          <div className={"md:w-1/3 flex flex-col self-center md:items-end justify-center"}>
            {!!perks.attachment && (
              <div className={"flex items-center"}>
                <div className={"w-1/8 md:w-1/8 pt-1.5 mr-2 md:mr-4 md:mt-1"}>
                  <img src={transformImageUrl(downloadIcon)} className={"w-5 h-auto"} alt={""} />
                </div>
                <div className={"w-full md:w-3/4"}>
                  <a
                    target={"_blank"}
                    href={perks.attachment}
                    className={"text-primary text-base hover:text-primary"}
                  >
                    {perks.attachment_name || "attachment"}
                    <span className={"text-gray-500 font-semibold text-sm"}>
                      ({perks.attachment_size || "0kb"})
                    </span>
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <a
        onClick={handleOnClick}
        href={perks.perk_url}
        target="_blank"
        className={
          "h-10 w-full rounded-b-lg bg-primary text-white text-lg flex justify-center items-center mt-5 focus:outline-none hover:text-white"
        }
      >
        Get discount
      </a>
    </div>
  );
};

export default withRouter(withApollo(PerkDetailCard));
