import React from "react";
import logo from "../../assets/icons/mums_and_co_logo.png";
import { transformImageUrl } from "../../utils/tools";

const Privacy = () => {
  return (
    <div className={"min-h-screen bg-light-gray"}>
      <div className={"w-full flex justify-center mb-10 bg-primary py-5"}>
        <img src={transformImageUrl(logo)} alt="" />
      </div>
      <div className={"container mx-auto pb-10 px-16 text-black text-sm privacy"}>
        <h3 className={"text-4xl font-grumpy font-bold"}>Privacy & Security</h3>
        <p>We value the privacy of personal information we collect about you.</p>
        <p>
          We collect your personal information directly from you or through others including
          entities listed in our Privacy Policy. They include our related entities, agents and
          distributors.
        </p>

        <h4 className={"text-lg font-bold"}>
          <strong>How we use your personal information</strong>
        </h4>
        <p>
          We and the parties listed in our Privacy Policy will use your personal information for the
          purposes it was collected for. That usually includes to provide you with assistance, or to
          provide a product or service that you requested.
        </p>

        <p>
          Your personal information may also be used for other purposes that are set out in our
          Privacy Policy. You may choose to not give us your personal information. However, not
          giving us your personal information may affect our ability to provide you with a product
          or service.
        </p>

        <h4 className={"text-lg font-bold"}>
          <strong>Further information</strong>
        </h4>
        <p>We may disclose your personal information to:</p>
        <ul className={"list-disc list-inside pl-5"}>
          <li>our related entities;</li>
          <li>
            our service providers — which includes some service providers that maybe based overseas;
            and
          </li>
          <li>other parties as set out in our Privacy Policy.</li>
        </ul>
        <p>
          Our Privacy Policy provides more information about how we collect, from whom we collect
          and how we hold, use and disclose your personal information. Our Privacy Policy also
          provides information about how you can:
        </p>
        <ul className={"list-disc list-inside pl-5"}>
          <li>access your personal information;</li>
          <li>ask us to correct your personal information; and</li>
          <li>other parties as set out in our Privacy Policy.</li>
        </ul>

        <h4 className={"text-lg font-bold"}>
          <strong>Your consent</strong>
        </h4>
        <p>
          ‍You agree to us collecting, holding, using and disclosing your personal information as
          set out in our Privacy Policy when you:
        </p>
        <ul className={"list-disc list-inside pl-5"}>
          <li>provide us with your personal information, or</li>
          <li>apply for, use or renew your Mums & Co membership.</li>
        </ul>
        <p>
          <a
            className={"text-primary focus:text-primary hover:text-primary"}
            rel="noopener noreferrer"
            href="https://productionmumsnco.s3.ap-southeast-2.amazonaws.com/Mums__%26_Co_Privacy_Policy_Updated_17.10.23.pdf"
            target="_blank"
          >
            Download our Privacy Policy (PDF)
          </a>
        </p>
        <p>&zwj;</p>
      </div>
    </div>
  );
};

export default Privacy;
