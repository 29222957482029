import { Modal } from "antd";
import React, { useContext, useState } from "react";
import { Subscription } from "react-apollo";
import withApollo from "../../config/with-apollo";
import { S_EVENT_ATTENDEES_LIST } from "../../graphql/events";
import { PageContext } from "../../helpers/context";
import UserModal from "./user-modal";
import { transformImageUrl } from "../../utils/tools";

const EventAttendeesModal = () => {
  const { showModalAttendees, setShowModalAttendees, event } = useContext(PageContext);
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleShowUserModal = (id) => () => {
    setSelectedId(id);
    setShowUserModal(true);
  };

  return (
    <PageContext.Provider value={{ showUserModal, setShowUserModal, selectedId, setSelectedId }}>
      <Modal
        visible={showModalAttendees}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        footer={null}
        closable={true}
        onCancel={() => {
          setShowModalAttendees(!showModalAttendees);
        }}
      >
        <UserModal source="EV" />
        <div className={"w-full flex flex-wrap"}>
          <Subscription subscription={S_EVENT_ATTENDEES_LIST} variables={{ eventId: event.id }}>
            {({ loading, error, data }) => {
              if (loading) return <></>;
              return data.attendees.map((value, key) => {
                return (
                  <div className={"w-1/2 inline-flex"}>
                    <div
                      key={value.id}
                      onClick={handleShowUserModal(value.user.id)}
                      className={`flex items-center mb-5 cursor-pointer`}
                    >
                      {value.user.avatar ? (
                        <div
                          className={
                            "flex items-center justify-center rounded-full w-10 h-10 bg-cover bg-no-repeat bg-center"
                          }
                          style={{
                            backgroundImage: `url(${transformImageUrl(value.user.avatar)})`,
                            flex: "0 0 2.5rem",
                          }}
                        />
                      ) : (
                        <div
                          className={
                            "flex items-center justify-center font-grumpy text-white text-xl bg-event-going rounded-full h-10 w-10"
                          }
                        >
                          {value.user.name[0].toUpperCase()}
                        </div>
                      )}
                      <p className={"ml-3 text-sm"}>{value.user.name}</p>
                    </div>
                  </div>
                );
              });
            }}
          </Subscription>
        </div>
      </Modal>
    </PageContext.Provider>
  );
};

export default withApollo(EventAttendeesModal);
