import { useMutation } from "@apollo/react-hooks";
import { Checkbox, Divider, Form, Icon, Input, Modal } from "antd";
import "antd/es/divider/style/css";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { compose } from "react-apollo";
import { withRouter } from "react-router-dom";
import bookingConfirmation from "../../assets/icons/tickets.png";
import withApollo from "../../config/with-apollo";
import { M_EXPERT_LISTING_SESSION } from "../../graphql/experts";
import { M_USER_PLAN } from "../../graphql/user";
import { AppContext, PageContext } from "../../helpers/context";
import { baseAxios, post } from "../../utils/api-handler";
import { AUTH } from "../../utils/common";
import { transformImageUrl } from "../../utils/tools";

const subscription = {
  COMMUNITY: 1,
  STANDARD: 2,
  PREMIUM: 3,
};
const ExpertConfirmation = (props) => {
  const { client } = props;
  const { user_subscription_type, user_mailchimp_id, user_tokens, user_current_plan } = useContext(
    AppContext
  );
  const {
    getFieldDecorator,
    getFieldValue,
    validateFields,
    resetFields,
    getFieldError,
  } = props.form;

  const {
    digitalLibrary,
    showModal,
    setShowModal,
    setShowSuccessModal,
    setCurrentResource,
    setRequest,
  } = useContext(PageContext);

  let resourceData = props.data || digitalLibrary;

  const USER = AUTH();
  const USER_ID = USER.hasura["https://hasura.io/jwt/claims"]["x-hasura-user-id"];
  const [submitting, setSubmitting] = useState(false);
  const [modalType, setModalType] = useState("");
  const [payToken, setPayToken] = useState(false);

  const [insertExpertSession] = useMutation(M_EXPERT_LISTING_SESSION, { client });
  const [hasFreeSessions, setHasFreeSessions] = useState(false);

  useEffect(() => {
    if (!showModal || !resourceData) return setModalType("");

    // Check if match
    if (!resourceData.subscription_type) {
      setPayToken(true);
    }

    if (
      (!resourceData.subscription_type && !user_subscription_type) ||
      resourceData.subscription_type === "ALWAYS_PAY_AS_YOU_GO" ||
      payToken
    ) {
      return setModalType("token");
    }

    if (!hasFreeSessions) {
      return setModalType("upgrade");
    }

    if (
      !resourceData.subscription_type ||
      subscription[resourceData.subscription_type] <=
        subscription[(user_subscription_type || "").toUpperCase()]
    ) {
      return setModalType("allowed");
    } else {
      return setModalType("upgrade");
    }
  }, [resourceData, showModal, user_subscription_type, payToken, hasFreeSessions]);

  useEffect(() => {
    if (!resourceData || showModal) return;

    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("modal")) {
      const url = new URL(window.location);
      url.searchParams.delete("modal");
      window.history.pushState(null, "", url.toString());
      setShowModal(true);
    }
  }, [resourceData, showModal]);

  useEffect(() => {
    if (user_current_plan) {
      const {
        subscriptionPropertyBySubscriptionProperty: subscription_properties,
        subscription_type,
      } = user_current_plan;
      if (subscription_type) {
        setHasFreeSessions(!!subscription_properties.single_expert_sessions);
      }
    }
  }, [user_current_plan]);

  const handleOnSubmit = (isToken = false) => (e) => {
    e.preventDefault();
    validateFields(async (error, values) => {
      if (
        !error &&
        values.description &&
        values.checkbox &&
        moment().isSameOrBefore(resourceData.resource_end_date)
      ) {
        try {
          const { available_single_expert_sessions } = user_current_plan;
          if (!isToken && !available_single_expert_sessions) return;

          setSubmitting(true);

          await post("/transact", {
            type: process.env.REACT_APP_EXPERT_LISTING_KEY,
            transactionId: resourceData.id,
            tokens: resourceData.cost,
            name: `Session with ${resourceData.name}`,
            userId: USER_ID,
            filter_type: "EXPERT",
            is_token: isToken,
          });

          // Update plan available meet ups
          if (!isToken) {
            const remaining = available_single_expert_sessions - 1;
            await client.mutate({
              mutation: M_USER_PLAN,
              variables: {
                id: user_current_plan.id,
                _set: {
                  available_single_expert_sessions: remaining,
                },
              },
            });

            const {
              subscriptionPropertyBySubscriptionProperty: subscription_properties,
            } = user_current_plan;
            if (subscription_properties.single_expert_sessions !== 99999) {
              await post("/contact-field", {
                mailchimp_id: user_mailchimp_id,
                field: "single_expert_sessions",
                value: `${remaining}/${subscription_properties.single_expert_sessions}`,
              });
            }
          }

          setShowModal(false);
          setShowSuccessModal(true);

          await baseAxios(
            "POST",
            process.env.REACT_APP_FRESHDESK_DOMAIN,
            {
              name: USER.name,
              email: USER.email,
              subject: `Expert session request for ${resourceData.name}`,
              description: values.description,
              status: 2,
              priority: 1,
            },
            {},
            {
              headers: {
                Authorization:
                  "Basic " +
                  new Buffer(`${process.env.REACT_APP_FRESHDESK_API_KEY}:X`).toString("base64"),
              },
            }
          );

          insertExpertSession({
            variables: {
              objects: [{ expert_listing_id: resourceData.id, user_id: USER.id }],
            },
          });

          resetFields(["description"]);
          setCurrentResource({});
        } catch (e) {
          console.log(e);
          if (e.response.data.code === 1000) {
            localStorage.setItem(
              "@return-url",
              `/get-advice/${resourceData.id}?modal=expert-registration`
            );
            return props.history.push("/upgrade-account", { amount: resourceData.cost });
          }

          if (e.response.data.code === 1001) {
            localStorage.setItem(
              "@return-url",
              `/get-advice/${resourceData.id}?modal=expert-registration`
            );
            return props.history.push("/top-up", {
              amount: resourceData.cost,
              lackAmount: e.response.data.amount || resourceData.cost - user_tokens,
            });
          }
        } finally {
          setSubmitting(false);
        }
      }
    });
  };

  const onUpgradeClick = async () => {
    if (user_current_plan.subscription_type) {
      const response = await post(`/create-customer-session`, {
        redirect: `${window.location.origin}/get-advice/${resourceData.id}?modal=expert-registration`,
        userId: USER.id,
      });

      if (response.data.sessionURL) {
        window.open(response.data.sessionURL, "_self");
      }
    }

    localStorage.setItem("@return-url", `/get-advice/${resourceData.id}?modal=expert-registration`);
    props.history.push("/upgrade-account", {
      selected: "PREMIUM",
    });
  };

  const ModalAllowed = useCallback(() => {
    const {
      available_single_expert_sessions,
      subscriptionPropertyBySubscriptionProperty: subscription_properties,
    } = user_current_plan;
    const spentSessions =
      subscription_properties.single_expert_sessions - available_single_expert_sessions;

    const hasNoMore = !available_single_expert_sessions || available_single_expert_sessions === 0;

    return (
      <Form onSubmit={handleOnSubmit(false)}>
        <div className={"flex flex-col "}>
          <img
            src={transformImageUrl(bookingConfirmation)}
            alt={""}
            className={"self-center w-24 mb-6"}
          />
          <>
            <p className={"self-center text-3xl text-black"}>Great choice!</p>
            <p className={"self-center text-lg text-center"}>
              {hasNoMore
                ? `You’re all out! Look at you go! You’ve used up all of your Expert1:1 Session inclusions so simply upgrade your membership plan or redeem using pay as you go tokens.`
                : `If you're interested in a 1:1 session, hit the button below and we will arrange a time and date for your call at a time that suits you and our Expert.`}
            </p>
          </>
          {!hasNoMore && (
            <>
              <Divider />
              <div className={"text-lg font-grumpy text-black"}>
                What are your high level queries?
              </div>
              <p className={"text-xs"}>
                Providing this information in advance will maximise the session and the advice that
                your expert can provide. Once the request is received, we'll set up the session at a
                mutually convenient time.
              </p>
              {getFieldDecorator("description")(
                <Input.TextArea
                  placeholder={"Description"}
                  style={{ textDecoration: "none" }}
                  rows={6}
                  maxLength={500}
                  className={"border mt-5 p-3 w-full rounded bg-white custom-focus-hover h-full"}
                />
              )}
              <p className={"font-thin mb-1"} style={{ fontSize: "0.625rem" }}>
                {getFieldValue("description") ? 500 - getFieldValue("description").length : "500"}{" "}
                characters remaining{" "}
              </p>
              {getFieldDecorator("checkbox", {
                rules: [{ required: true, message: "* This is required" }],
                valuePropName: "checked",
              })(
                <Checkbox>
                  <span className={"text-black font-grumpy"}>
                    I agree{" "}
                    <span className={"text-red-500 text-xs"}>{getFieldError("checkbox")}</span>
                  </span>
                  <p className={"text-xs"}>
                    Any business advice provided is general in nature and not tailored to an
                    individual or business. Mums & Co and the listed experts will not be held
                    accountable for any business advice provided. By booking the session, you
                    consent to Mums & Co recording and storing audio, digital and written materials
                    and content from that session ('Content'). The Content will be stored, held and
                    may be used by Mums & Co in accordance with its Privacy Policy.
                  </p>
                </Checkbox>
              )}
            </>
          )}

          <div className={"mt-4 flex flex-row w-full justify-center"}>
            <button
              disabled={submitting}
              onClick={() => {
                setShowModal(!showModal);
                setRequest(false);
              }}
              className={
                "self-center bg-light-gray-header text-black h-10 text-lg w-1/3 font-light mr-5  rounded active:bg-primary focus:outline-none "
              }
            >
              Cancel
            </button>
            {hasNoMore ? (
              <button
                disabled={submitting}
                onClick={() => {
                  if (user_tokens < resourceData.cost) {
                    localStorage.setItem(
                      "@return-url",
                      `/get-advice/${resourceData.id}?modal=expert-registration`
                    );
                    return props.history.push("/top-up", {
                      lackAmount: resourceData.cost - user_tokens,
                      amount: resourceData.cost,
                    });
                  }
                  setPayToken(true);
                }}
                className={`self-center text-white h-10 text-md md:w-1/3 w-full font-light  rounded active:bg-primary focus:outline-none  ${
                  submitting ? "bg-disabled" : "bg-primary"
                }`}
              >
                {submitting && <Icon type={"loading"} className={"mr-2"} />}
                Redeem with Tokens
              </button>
            ) : (
              <button
                type={"submit"}
                disabled={submitting}
                className={`self-center text-white h-10 text-lg w-1/3 font-light  rounded active:bg-primary focus:outline-none  ${
                  submitting ? "bg-disabled" : "bg-primary"
                }`}
              >
                {submitting && <Icon type={"loading"} className={"mr-2"} />}
                Confirm
              </button>
            )}
          </div>
          <div className="text-center mt-4">
            <span className={"self-center text-xs"}>
              You have registered for <strong>{spentSessions}</strong> expert sessions and have{" "}
              <strong>{available_single_expert_sessions} </strong>Remaining.{" "}
            </span>
            <br />
          </div>
        </div>
      </Form>
    );
  }, [
    hasFreeSessions,
    user_tokens,
    showModal,
    submitting,
    user_current_plan,
    user_subscription_type,
  ]);

  const ModalUpgrade = useCallback(() => {
    return (
      <Form>
        <div className={"flex flex-col "}>
          <img
            src={transformImageUrl(bookingConfirmation)}
            alt={""}
            className={"self-center w-24 mb-6"}
          />
          <p className={"self-center text-3xl text-black"}>Can't Access!</p>
          <p className={"self-center text-lg mb-5 text-center"}>
            Can’t access yet! 1:1 Expert Sessions are included with Premium Memberships. If you
            would like to access a 1:1 Session simply upgrade your membership plan or redeem using
            pay as you go tokens.
          </p>
          <div className={"flex flex-row w-full justify-center"}>
            <button
              disabled={submitting}
              onClick={() => {
                setShowModal(!showModal);
                onUpgradeClick();
              }}
              className={
                "self-center bg-event-going text-white h-10 w-full text-md md:w-2/3 font-light mr-5  rounded active:bg-primary focus:outline-none "
              }
            >
              Upgrade to Premium
            </button>
            <button
              disabled={submitting}
              onClick={() => {
                if (user_tokens < resourceData.cost) {
                  localStorage.setItem(
                    "@return-url",
                    `/get-advice/${resourceData.id}?modal=expert-registration`
                  );

                  return props.history.push("/top-up", {
                    lackAmount: resourceData.cost - user_tokens,
                    amount: resourceData.cost,
                  });
                }
                setPayToken(true);
              }}
              className={`self-center text-white h-10 text-md md:w-2/3 w-full font-light  rounded active:bg-primary focus:outline-none  ${
                submitting ? "bg-disabled" : "bg-primary"
              }`}
            >
              {submitting && <Icon type={"loading"} className={"mr-2"} />}
              Redeem with Tokens
            </button>
          </div>
        </div>
      </Form>
    );
  }, [showModal, resourceData, submitting, user_tokens]);

  const ModalToken = useCallback(() => {
    return (
      <Form>
        <div className={"flex flex-col "}>
          <img
            src={transformImageUrl(bookingConfirmation)}
            alt={""}
            className={"self-center w-24 mb-6"}
          />
          <p className={"self-center text-3xl text-black"}>Great choice!</p>
          <p className={"self-center text-lg text-center"}>
            {resourceData.cost} tokens will be deducted to book this session. The session will be
            set up at a mutually convenient time.
          </p>
          <Divider />
          <div className={"text-lg font-grumpy text-black"}>What are your high level queries?</div>
          <p className={"text-xs"}>
            Providing this information in advance will maximise the session and the advice that your
            expert can provide. Once the request is received, we'll set up the session at a mutually
            convenient time.
          </p>
          {getFieldDecorator("description")(
            <Input.TextArea
              placeholder={"Description"}
              style={{ textDecoration: "none" }}
              rows={6}
              maxLength={500}
              className={"border mt-5 p-3 w-full rounded bg-white custom-focus-hover h-full"}
            />
          )}
          <p className={"font-thin mb-1"} style={{ fontSize: "0.625rem" }}>
            {getFieldValue("description") ? 500 - getFieldValue("description").length : "500"}{" "}
            characters remaining{" "}
          </p>
          {getFieldDecorator("checkbox", {
            rules: [{ required: true, message: "* This is required" }],
            valuePropName: "checked",
          })(
            <Checkbox>
              <span className={"text-black font-grumpy"}>
                I agree <span className={"text-red-500 text-xs"}>{getFieldError("checkbox")}</span>
              </span>
              <p className={"text-xs"}>
                Any business advice provided is general in nature and not tailored to an individual
                or business. Mums & Co and the listed experts will not be held accountable for any
                business advice provided. By booking the session, you consent to Mums & Co recording
                and storing audio, digital and written materials and content from that session
                ('Content'). The Content will be stored, held and may be used by Mums & Co in
                accordance with its Privacy Policy.
              </p>
            </Checkbox>
          )}
          <div className={"flex flex-row w-full justify-center"}>
            <button
              disabled={submitting}
              onClick={() => {
                setShowModal(!showModal);
                setPayToken(false);
                setRequest(false);
              }}
              className={
                "self-center bg-light-gray-header text-black h-10 text-lg w-1/3 font-light mr-5  rounded active:bg-primary focus:outline-none "
              }
            >
              Cancel
            </button>
            <button
              onClick={handleOnSubmit(true)}
              disabled={submitting}
              className={`self-center text-white h-10 text-lg w-1/3 font-light  rounded active:bg-primary focus:outline-none  ${
                submitting ? "bg-disabled" : "bg-primary"
              }`}
            >
              {submitting && <Icon type={"loading"} className={"mr-2"} />}
              Confirm
            </button>
          </div>
          <div className={"mt-2 flex self-center"}>
            <p>Note : Token refunds are at Mums & Co’s sole discretion.</p>
          </div>
        </div>
      </Form>
    );
  }, [resourceData, submitting, showModal]);

  const modalProps = useMemo(() => {
    if (modalType !== "upgrade") return {};
    return {
      closable: true,
      maskClosable: true,
      onCancel: () => {
        setShowModal(!showModal);
        setRequest(false);
      },
    };
  }, [modalType, showModal]);

  return (
    <Modal
      visible={showModal}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      footer={null}
      closable={false}
      destroyOnClose={true}
      {...modalProps}
    >
      {modalType === "allowed" && <ModalAllowed />}
      {modalType === "upgrade" && <ModalUpgrade />}
      {modalType === "token" && <ModalToken />}
    </Modal>
  );
};

export default compose(withApollo, withRouter, Form.create({ name: "form" }))(ExpertConfirmation);
