import { Dropdown, Layout, Menu } from "antd";
import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import helpMobileActive from "../../assets/icons/help-mobile-active.png";
import mailIcon from "../../assets/icons/menu-chat-icon.png";
import logoutIcon from "../../assets/icons/menu-logout-icon.png";
import setting from "../../assets/icons/menu-setting-icon.png";
import tokenHistory from "../../assets/icons/menu-token-icon.png";
import logoMobile from "../../assets/icons/mums-and-co-logo-mobile.png";
import userMobile from "../../assets/icons/user-mobile.png";
import { AppContext } from "../../helpers/context";
import { transformImageUrl } from "../../utils/tools";

const { Header } = Layout;

const PrimaryHeader = (props) => {
  const { user_avatar, user_status } = useContext(AppContext);
  const [menuClicked, setMenuClicked] = useState(false);

  const logout = () => {
    sessionStorage.removeItem("mumsnco-init");
    sessionStorage.removeItem("mumsnco-returning-url");
    localStorage.removeItem("mums-and-co-has-login");
    localStorage.removeItem(process.env.REACT_APP_TOKEN);
    sessionStorage.removeItem("mumsnco-returning-url");
    window.location.href = "/";
  };

  const handleGoToPage = (route) => () => {
    props.history.push(route);
    window.scrollTo(0, 0);
  };

  const setBorderAvatar = () => {
    setTimeout(() => {
      setMenuClicked(!menuClicked);
    }, 50);
  };

  const pendingMenu = (
    <Menu className={"pt-8"} style={{ bottom: "30px" }} onClick={setBorderAvatar}>
      <Menu.Item className={"flex flex-row items-center"} onClick={logout}>
        <img className={"w-5 h-auto mr-3"} src={logoutIcon} alt="" />
        <a>Logout</a>
      </Menu.Item>
    </Menu>
  );

  const menu =
    user_status === "PENDING" ? (
      pendingMenu
    ) : (
      <Menu className={"pt-8"} style={{ bottom: "30px" }} onClick={setBorderAvatar}>
        <Menu.Item className={"flex flex-row items-center"} onClick={handleGoToPage("/profile/me")}>
          <img className={"w-5 h-5 mr-3"} src={userMobile} alt="" />
          <a>Profile</a>
        </Menu.Item>
        <Menu.Item
          className={"flex flex-row items-center"}
          onClick={handleGoToPage("/profile/token-history")}
        >
          <img className={"w-5 h-auto mr-3"} src={tokenHistory} alt="" />
          <a>Token History</a>
        </Menu.Item>
        <Menu.Item className={"flex flex-row items-center"} onClick={handleGoToPage("/messages")}>
          <img className={"w-5 h-auto mr-3"} src={mailIcon} alt="" />
          <a>Messages</a>
        </Menu.Item>
        <Menu.Item
          className={"flex flex-row items-center"}
          onClick={handleGoToPage("/profile/settings")}
        >
          <img className={"w-5 h-5 mr-3"} src={setting} alt="" />
          <a>Settings</a>
        </Menu.Item>
        <Menu.Item className={"flex flex-row items-center"} onClick={logout}>
          <img className={"w-5 h-auto mr-3"} src={logoutIcon} alt="" />
          <a>Logout</a>
        </Menu.Item>
      </Menu>
    );

  return (
    <Header
      className={
        "fixed w-full top-0 z-50 bg-white h-13 flex justify-between items-center px-8 py-3 md:hidden"
      }
      id={"image-container"}
    >
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        onClick={setBorderAvatar}
        onVisibleChange={setBorderAvatar}
        overlayStyle={{ zIndex: 10 }}
        getPopupContainer={() => document.getElementById("image-container")}
      >
        {typeof user_avatar !== "function" ? (
          <div
            className={`bg-center bg-no-repeat bg-cover bg-black bg-black rounded-full  h-10 w-10 z-20 ${
              menuClicked ? "avatar-menu" : "avatar-menu-inactive"
            }`}
            style={{ backgroundImage: `url(${transformImageUrl(user_avatar || userMobile)})` }}
          />
        ) : (
          user_avatar(
            `flex items-center justify-center font-grumpy bg-event-going text-white text-xl rounded-full  h-10 w-10 z-20 ${
              menuClicked ? "avatar-menu" : "avatar-menu-inactive-primary"
            }`
          )
        )}
      </Dropdown>
      <img
        onClick={handleGoToPage("/dashboard")}
        alt={""}
        src={logoMobile}
        className={"w-16 h-auto cursor-pointer"}
      />
      <a onClick={handleGoToPage("/help")}>
        <img alt={""} src={helpMobileActive} className={"w-7"} />
      </a>
    </Header>
  );
};

export default withRouter(PrimaryHeader);
