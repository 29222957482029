import { Tooltip, Divider, Modal, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { get, post } from "../../utils/api-handler";
import { PUBLIC_EVENTS_URL } from "../../utils/url-handler";
import tooltipIcon from "../../assets/icons/tooltip-icon.png";
import moment from "moment-timezone";
import PublicHeader from "../../components/header/header-public";
import bigCheckIcon from "../../assets/icons/big-check-icon.png";
import { transformImageUrl } from "../../utils/tools";
const PublicEventsPage = (props) => {
  const { location } = props;

  const [submitting, setSubmitting] = useState(false);
  const [isDisabled, setDisabled] = useState(true);
  const [showDisabled, setShowDisabled] = useState(false);
  const [data, setData] = useState([]);
  const [price, setPrice] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [inputValues, setInputValues] = useState({
    name: "",
    email: "",
  });
  const url = window.location.pathname;

  const extractLastSegment = (url) => {
    const segments = url.split("/");
    return segments[segments.length - 1];
  };

  const id = extractLastSegment(url);

  const handleSelect = (e) => {
    setDisabled(!e.target.checked);
    setShowDisabled(false);
  };

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const EVENT_ID_URL = `${PUBLIC_EVENTS_URL}/${id}`;
        let response = await get(EVENT_ID_URL);
        setData(response.data);
        if (response.data.length > 0) {
          const firstRow = response.data[0];
          const stripeLink = firstRow.stripe_link;

          setPrice(stripeLink);
        }
      } catch (e) {
        console.error("Error fetching data:", e);
      }
    };

    fetchEvent();
  }, []);

  useEffect(() => {
    // Check if the URL contains a success parameter
    const urlParams = new URLSearchParams(window.location.search);
    const statusParam = urlParams.get("status");

    // Open the modal if the status parameter is 'success'
    if (statusParam === "success") {
      setShowSuccessModal(true);
    }
  }, []);

  const closeModal = () => {
    setShowSuccessModal(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    console.log(inputValues);
  };

  const handleSubmit = async () => {
    // Create an object with the data to be sent
    // Define the API endpoint URL
    const response = await post(`/create-product-checkout-session/${price}`, {
      redirect: url,
      redirect_success: `${window.location.origin}/public-events/${id}?status=success`,
      redirect_fail: `${window.location.origin}${url || ""}`,
      email: inputValues.email,
      name: inputValues.name,
      eventId: id,
    });

    // Proceed with checkout
    window.open(response.data.sessionURL, "_self");
  };

  const handleDisabled = async () => {
    setShowDisabled(true);
  };

  const formatTimeWithTimezone = (time_from, time_to) => {
    // Helper function to get the timezone abbreviation based on the offset
    const getTimezoneAbbreviation = (offset) => {
      if (offset === "+10") {
        return "AEST";
      } else if (offset === "+11") {
        return "AEDT";
      } else {
        return " ";
      }
    };
    const timezoneOffset = time_from.slice(-3);

    const timezoneAbbreviation = getTimezoneAbbreviation(timezoneOffset);

    let formattedTimeFrom = moment();
    let formattedTimeTo = moment();
    if (timezoneAbbreviation === "AEDT") {
      formattedTimeFrom = moment(time_from, "HH:mm:ssZ")
        .tz("Asia/Magadan")
        .format("h:mm A");
      formattedTimeTo = moment(time_to, "HH:mm:ssZ")
        .tz("Asia/Magadan")
        .format("h:mm A");
    } else {
      formattedTimeFrom = moment(time_from, "HH:mm:ssZ")
        .tz("Australia/Sydney")
        .format("h:mm A");
      formattedTimeTo = moment(time_to, "HH:mm:ssZ")
        .tz("Australia/Sydney")
        .format("h:mm A");
    }

    return `${formattedTimeFrom} - ${formattedTimeTo} ${timezoneAbbreviation}`;
  };

  return (
    <>
      <PublicHeader />
      <div>
        {data.map((row, index) => (
          <>
            <div key={index}>
              <div className="min-h-screen bg-white pb-5">
                <div className={"w-full"}>
                  <div className={"container mx-auto px-5 md:p-10"}>
                    <div className={"container mx-auto px-4 md:px-16"}>
                      <div className={"flex flex-col md:flex-row md:flex-wrap"}>
                        <Modal
                          visible={showSuccessModal}
                          onCancel={closeModal}
                          okButtonProps={{ disabled: true }}
                          cancelButtonProps={{ disabled: true }}
                          footer={null}
                        >
                          <div className={"flex flex-col"}>
                            <img
                              src={transformImageUrl(bigCheckIcon)}
                              alt={""}
                              className={"self-center w-24 mb-6"}
                            />
                            <p className={"self-center text-center text-3xl text-black"}>
                              Event RSVP
                            </p>
                            <p className={"self-center text-center text-lg mb-5"}>
                              You have successfully booked a seat in this event.
                            </p>
                            <button
                              onClick={closeModal}
                              className={
                                "self-center bg-primary text-white h-10 text-lg px-4 font-light  rounded active:bg-primary focus:outline-none "
                              }
                            >
                              Close
                            </button>
                          </div>
                        </Modal>
                      </div>
                    </div>
                    <div
                      className={
                        "flex md:items-center flex justify-center text-center w-full md:pt-6 md:pb-6"
                      }
                    >
                      <h5 className="font-grumpy tracking-wider text-4xl w-3/4">{row.name}</h5>
                    </div>
                    <div className="md:grid md:grid-cols-10 mb-5 mt-5 md:px-8 w-full space-y-3 md:space-y-0">
                      <img
                        src={transformImageUrl(row.image)}
                        className="rounded-lg w-full col-start-7 md:ml-3 lg:ml-0 lg:col-start-6 md:col-span-5 md:order-2"
                      ></img>

                      <div className="md:col-span-5 md:col-start-1 md:order-1">
                        <p
                          className={
                            "text-black font-normal text-2xl font-grumpy tracking-wide justify-self-center"
                          }
                        >
                          {moment(row.date_from).isSame(row.date_to)
                            ? moment(row.date_to).format("LL")
                            : `${moment(row.date_from).format("LL")} - ${moment(row.date_to).format(
                                "LL"
                              )}`}
                        </p>
                        <p className={"text-black font-normal text-xl font-sans mt-1 md:mt-3"}>
                          {formatTimeWithTimezone(row.time_from, row.time_to)}
                        </p>
                        <p className="lg:w-3/4 text-black font-normal text-xl font-sans mt-1 md:mt-3">
                          {row.online ? "" : row.location}{" "}
                          {!!row.location_notes && (
                            <Tooltip placement="topLeft" title={row.location_notes}>
                              <img
                                src={transformImageUrl(tooltipIcon)}
                                className={"w-4 h-auto inline"}
                                alt={""}
                              />
                            </Tooltip>
                          )}
                        </p>
                        {row.online && (
                          <p
                            className={`lg:w-3/4 text-black font-normal text-xl font-sans mt-1 md:mt-3`}
                          >
                            Microsoft Teams Meeting
                          </p>
                        )}
                        {!row.online && (
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${row.location}`}
                            target="_blank"
                            className={"text-primary text-lg hover:text-primary"}
                          >
                            View on map
                          </a>
                        )}
                        <div className="grid grid-cols-4 col-span-4 gap-2 mt-3">
                          <div className="col-span-4 w-full mt-3 max-w-sm lg:max-w-md p-3 bg-white border border-gray-200 rounded-lg shadow sm:p-4 lg:p-5 dark:bg-gray-800 dark:border-gray-700">
                            <div className="space-y-3 lg:space-y-5">
                              <h5 className="text-base lg:text-lg font-medium text-gray-900 dark:text-white text-center font-grumpy tracking-wider">
                                Want to join?
                              </h5>
                              <div>
                                <label
                                  htmlFor="email"
                                  className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2"
                                >
                                  Email
                                </label>
                                <input
                                  type="email"
                                  name="email"
                                  value={inputValues.email}
                                  onChange={handleChange}
                                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white h-7 text-base"
                                  required
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor="name"
                                  className="block uppercase tracking-wide text-gray-900 text-xs font-bold mb-2"
                                >
                                  Name
                                </label>
                                <input
                                  type="text"
                                  name="name"
                                  value={inputValues.name}
                                  onChange={handleChange}
                                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white h-7 text-base"
                                  required
                                />
                              </div>
                              <div className="flex justify-center">
                                <p className={`text-red-600 ${showDisabled ? "mx-3" : "hidden"}`}>
                                  *
                                </p>
                                <Checkbox checked={!isDisabled} onChange={handleSelect}>
                                  Disclaimer about{" "}
                                  <a
                                    className="text-primary underline hover:text-primary hover:underline"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href="https://app.mumsandco.com.au/terms-and-conditions-pe"
                                  >
                                    Terms and Conditions
                                  </a>
                                </Checkbox>
                              </div>
                              <div className="text-center">
                                <button
                                  type="submit"
                                  onClick={
                                    isDisabled ? () => handleDisabled() : () => handleSubmit()
                                  }
                                  className={`bg-transparent w-1/2 mr-3 text-sm  text-primary font-semibold  py-1 lg:py-3 px-1 border-2  rounded ${
                                    isDisabled
                                      ? "border-disabled"
                                      : "border-primary hover:bg-primary hover:text-white hover:border-transparent"
                                  }`}
                                >
                                  Join as a Guest (${row.cost})
                                </button>
                                <Link to={{ pathname: `/events/${id}` }}>
                                  <button className="bg-transparent w-1/3 text-sm hover:bg-primary text-primary font-semibold hover:text-white py-1 lg:py-3 px-1 border-2 border-primary hover:border-transparent rounded">
                                    Join as a Member
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=""></div>

                    <Divider className="bg-gray-300" />
                    <div className={"text-base html-div lg:px-20 text-black font-normal mt-5"}>
                      <div dangerouslySetInnerHTML={{ __html: row.description || "N/A" }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  );
};

export default withRouter(PublicEventsPage);
