import React, { useContext } from "react";
import urlLink from "../../../assets/icons/website-link-profile.png";
import { PageContext } from "../../../helpers/context";
import { transformImageUrl } from "../../../utils/tools";
const ProfileSettingView = () => {
  const { business, currentBusiness } = useContext(PageContext);

  const urlify = (text) => {
    let urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;

    return text.replace(urlRegex, function(url) {
      let parseUrl;

      if (url.indexOf("https") !== -1 || url.indexOf("http") !== -1) {
        parseUrl = url;
      } else {
        parseUrl = `//${url}`;
      }

      return `<a
        target="_blank" href="${parseUrl}"
        class="inline-block text-primary hover:text-primary focus:text-primary">${url}</a>`;
    });
  };

  return (
    <div className={"break-words"}>
      <div className={"mb-5"}>
        <div className={"profile-business-label"}>Stage</div>
        <div className={"inline-block mr-6"}>{business[currentBusiness].stage || "No info"}</div>
        <div className={"profile-business-label"}>Industry</div>
        <div className={"inline-block"}>{business[currentBusiness].industry || "No info"}</div>
      </div>
      <div className={"mb-5"}>
        <div className={"profile-business-label"}>Name</div>
        <div className={"inline-block mr-6"}>{business[currentBusiness].name || "No info"}</div>
        <div className={"items-center inline-block"}>
          <img src={transformImageUrl(urlLink)} className={"h-auto w-6 inline-block mr-3"} alt="" />
          <div
            className={"inline-block"}
            dangerouslySetInnerHTML={{
              __html: business[currentBusiness].url ? urlify(business[currentBusiness].url) : "N/A",
            }}
          />
        </div>
      </div>
      <div className={"mb-5"}>
        <div className={"profile-business-label"}>Type</div>
        <div className={"inline-block mr-6"}>{business[currentBusiness].type || "No info"}</div>
        <div className={"inline-block"}>
          <div className={"profile-business-label"}>Offering</div>
          <div className={"inline-block"}>{business[currentBusiness].offering || "No info"}</div>
        </div>
      </div>
      {/*<div className={`flex flex-row mb-5 hidden`}>*/}
      {/*  <div className={'profile-business-label'}>Tags</div>*/}
      {/*  {business[currentBusiness].tags.length ? business[currentBusiness].tags.map((value) => {*/}
      {/*    return (*/}
      {/*      <div key={value}*/}
      {/*        className={'py-1 px-3 bg-gray-300 text-black mr-2 rounded text-sx'}>*/}
      {/*        {value}*/}
      {/*      </div>*/}
      {/*    );*/}
      {/*  }) : (*/}
      {/*    <div className={'inline-block'}>No info</div>*/}
      {/*  )}*/}
      {/*</div>*/}
      <p className={"text-sm"}>{business[currentBusiness].about}</p>
    </div>
  );
};

export default ProfileSettingView;
