// https://productionmumsnco.s3-ap-southeast-2.amazonaws.com/Dashboard/DigitalCoWorkingEvent.png
// https://cdn.mumsandco.com.au/
// https://cdn.mumsandco.com.au/Experts/Laura_Elkaslassy_Headshot_2024-07-09.png

const oldPrefixImageAddress = "https://productionmumsnco.s3-ap-southeast-2.amazonaws.com";
const newPrefixImageAddress = "https://cdn.mumsandco.com.au";

export function transformImageUrl(url) {
  return url.replace(oldPrefixImageAddress, newPrefixImageAddress);
}
